export class companymaster
{
    companyid: string= "";
    companyname: string= "";
    address: string= "";
    email: string= "";
    link: string= "";
    no1: string= "";
    no2: string= "";
    CreatedDate: string= "";
}