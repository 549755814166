import { Component, OnInit } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
declare var $: any;
import * as _ from 'underscore'
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchtext: any;
  isLoading = false;
  //productlist : Array<Object> = [];
  public struserid: string = "0";
  public userloggedin: boolean = false;
  productlist: any[];
  existingimages: any[];
  subcategorylist: any[];
  defaultsubcategorylist: any[];
  categoryproducts: any[];
  catesub: any[];
  democategoryproducts: any[];
  defaultcategoryproducts: any[];
  imagebasepath: string = "";
  currentimageurl: string = "";
  isproductLoading = false;
  categoryid: any;
  maincategoryid: any;
  pageSize = 8;
  pagedPosts: any[];
  cartcount = 0;
  catcount = 0;
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };

  minValue: number = 0;
  maxValue: number = 1500;
  options: Options = {
    floor: 0,
    ceil: 1500,
    step: 100,
    noSwitching: true,
    translate: (value: number): string => {
      if (value == 1500) {
        return '$' + value + '+';
      }
      else {
        return '$' + value;
      }
    }
    //showTicks: true
  };
  constructor(protected localStorage: LocalStorage, public api: Api, private _router: Router, private _routeParams: ActivatedRoute,
    private toastr: ToastrService, public globalSettings: Settings) {

    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);

    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    var id = this._routeParams.params.subscribe(params => {
      var id = params["id"];
      this.minValue = 0;
      this.maxValue = 1500;
      this.searchtext = id;
      if (!id)
        return;

        let currentuserid = this.struserid === "" ? "0" : this.struserid;

      let getdetails: { id: string, searchdata: string } = {
        id: currentuserid, searchdata: this.searchtext
      };

      //let seq = this.api.post('productapi/searchItems', getdetails).share();
      let seq = this.api.post('productapi/searchItemsseller', getdetails).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.categoryproducts = res.Data;
          this.defaultcategoryproducts = res.Data;
          this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
        }, err => {
          alert('Error 1');
        });

    });
  }


  onPageChanged(page: any) {
    var startIndex = (page - 1) * this.pageSize;
    this.pagedPosts = _.take(_.rest(this.categoryproducts, startIndex), this.pageSize);
  }

  onChange($event) {
    this.pageSize = Number($event.target.value);
    this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
  }

  pricechanges($event) {
    if (this.maxValue == 1500) {
      this.categoryproducts = this.defaultcategoryproducts.filter(obj => Number(obj.item_price) >= Number(this.minValue));
      //this.categoryproducts = this.categoryproducts.filter(obj =>  Number(obj.item_price) >= 13.94);
      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
    else {
      this.categoryproducts = this.defaultcategoryproducts.filter(obj => Number(obj.item_price) >= Number(this.minValue) && Number(obj.item_price) <= Number(this.maxValue));
      //this.categoryproducts = this.categoryproducts.filter(obj =>  Number(obj.item_price) >= 13.94);
      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
  }

  sortproducts($event) {
    let selectedval = String($event.target.value);
    if (selectedval === 'atoz') {


      this.categoryproducts = this.defaultcategoryproducts.sort(function (a, b) {
        var nameA = a.item_name.toLowerCase(), nameB = b.item_name.toLowerCase()
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });

      this.categoryproducts = this.categoryproducts.sort(function (a, b) {
        var nameA = a.item_name.toLowerCase(), nameB = b.item_name.toLowerCase()
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });


      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
    else if (selectedval === 'ztoa') {
      this.categoryproducts = this.defaultcategoryproducts.sort(function (a, b) {
        var nameA = b.item_name.toLowerCase(), nameB = a.item_name.toLowerCase()
        if (nameA < nameB)
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });


      this.categoryproducts = this.categoryproducts.sort(function (a, b) {
        var nameA = b.item_name.toLowerCase(), nameB = a.item_name.toLowerCase()
        if (nameA < nameB)
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });

      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
    else if (selectedval === 'lowtoheigh') {
      this.categoryproducts = this.categoryproducts.sort((a, b) => a.item_price - b.item_price);
      this.categoryproducts = this.defaultcategoryproducts.sort((a, b) => a.item_price - b.item_price);

      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
    else if (selectedval === 'heightolow') {
      this.categoryproducts = this.categoryproducts.sort((a, b) => b.item_price - a.item_price);
      this.categoryproducts = this.defaultcategoryproducts.sort((a, b) => b.item_price - a.item_price);
      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
  }

  
  likeproduct(product: any) {
    if (!this.userloggedin) {
      this._router.navigate(['/login']);
      return;
    }
    //   $('#login-modal').modal('show');
    var statustosend = '';
    var index = this.categoryproducts.indexOf(product);

    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };

    if (product.IsuserLike === '0') {

      statustosend = '1';
      product.like_count = Number(product.like_count) + 1;
      this.categoryproducts[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.categoryproducts[index].IsuserLike = '0';
          product.like_count = Number(product.like_count) - 1;
          alert('Error');
        });
    } else {
      statustosend = '0';
      product.like_count = Number(product.like_count) - 1;
      this.categoryproducts[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemunlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.categoryproducts[index].IsuserLike = '1';
          product.like_count = Number(product.like_count) + 1;
          alert('Error');
        });

    }
  }


  
  addToCart(product) {
    // let cartdata = $.parseJSON(localStorage.getItem('cart'));
    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': product,
          'qty': 1,
          'amount': parseFloat(product.saleprice)
          ,'adminuser_id': Number(product.adminuser_id)
        });
      } else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          if (product.items_id === cartdata[i].product.items_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + 1;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }

        if (added === 0) {
          cartdata.push({
            'product': product,
            'qty': 1,
            'amount': parseFloat(product.saleprice)
            ,'adminuser_id': Number(product.adminuser_id)
          });
        }

      }

      this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }
    });
  }





}
