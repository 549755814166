import { Component, OnInit, ElementRef,  ViewChild, TemplateRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { clsCategory } from './clscategory';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import * as _ from 'underscore'
import { Options } from 'ng5-slider';


@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  //productlist : Array<Object> = [];
  public struserid: string = "0";
  public userloggedin: boolean = false;
  public loading = false;
  productlist: any[];
  existingimages: any[];
  subcategorylist: any[];
  defaultsubcategorylist: any[];
  categoryproducts: any[];
  catesub: any[];
  democategoryproducts: any[];
  defaultcategoryproducts: any[];
  imagebasepath: string = "";
  category = new clsCategory();
  currentimageurl: string = "";
  isproductLoading = false;
  categoryid: any;
  pageSize = 8;
  pagedPosts: any[];
  cartcount = 0;
  catcount = 0;
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };

  getpro: { id: string, urllink: string, start: number, end: number, } = {
    id: '0',
    urllink: "",
    start: 0,
    end: 12,
  };

  filterpara: { id: string,sub_cat_id: any, urllink: string,pricestart: number,priceend: number,  start: number, end: number, } = {
    id: '0',
    sub_cat_id: [],
    urllink: "",
    pricestart: 0,
    priceend: 100000,
    start: 0,
    end: 12,
  };


  @ViewChild('logincontent') logincontentref: TemplateRef<any>;
  @ViewChild('signupcontent') signupcontentref: TemplateRef<any>;
  @ViewChild('otpverificationcontent') otpverificationcontentref: TemplateRef<any>;
  @ViewChild('newsignupcontent') newsignupcontentref: TemplateRef<any>;


  
  minValue: number;
  maxValue: number;



  // options: Options = {
  //   floor: 0,
  //   ceil: 9000,
  //   step: 100,
  //   noSwitching: true,
  //   translate: (value: number): string => {
  //     if (value == 9000) {
  //       return '₹' + value + '+';
  //     }
  //     else {
  //       return '₹' + value;
  //     }
  //   }
  //   //showTicks: true
  // };

  constructor(private elementRef: ElementRef, public api: Api, public globalSettings: Settings, private _Router: Router,protected localStorage: LocalStorage,
     private modalService: NgbModal,
    private _routeParams: ActivatedRoute) 
    {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
    
  }

  ngOnInit() {
    var id = this._routeParams.params.subscribe(params => {
      var id = params["id"];
      this.categoryid = id;
      // this.minValue = 0;
      // this.maxValue = 0;

      if (!id)
        return;

      this.loading = true;
      let getdetails: { cat_id: string } = {
        cat_id: this.categoryid
      };

      let seq = this.api.post('categoryapi/getCategoryProductsById', getdetails).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.category = res.Data;
          let user3: { cat_id: string } = {
            cat_id: this.category.cat_id
          };

          let seq3 = this.api.post('subcategoryapi/getsubCategoryBycategoryId', user3).share();
          seq3
            .map(res => res.json())
            .subscribe(res => {
              this.subcategorylist = res.Data;
              this.subcategorylist.forEach(item => item.isChecked = false);
              this.defaultsubcategorylist = res.Data;
              this.loading = false;
            }, err => {
              this.loading = false;
              alert('Error');
            });
        }, err => {
          this.loading = false;
          alert('Error 1');
          
        });
      

      var sendval = this.struserid == '' ? "0" : this.struserid;
      this.getpro.start = 0;
      this.getpro.end = 12;
      this.getpro.urllink = this.categoryid;
      this.getpro.id = sendval;

      this.isproductLoading = true;
      let seq2 = this.api.post('productapi/getAllProductsByUserIdCatIDSellerPagewise', this.getpro).share();
      seq2
        .map(res => res.json())
        .subscribe(res => {
          this.categoryproducts = res.Data;
          //this.defaultcategoryproducts = res.Data;
          //this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
          this.isproductLoading = false;
        }, err => {
          alert('Error');
        });

      // this.isproductLoading = true;
      // //let seq2 = this.api.post('productapi/getAllProductsByUserIdCatID', user).share();
      // let seq2 = this.api.post('productapi/getAllProductsByUserIdCatIDseller', user).share();
      // seq2
      //   .map(res => res.json())
      //   .subscribe(res => {
      //     this.categoryproducts = res.Data;
      //     this.defaultcategoryproducts = res.Data;
      //     this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
      //     this.isproductLoading = false;
      //   }, err => {
      //     alert('Error');
      //   });
    });
  }


  loadmorebtn = true;
  loadMoreProducts()
  {
    var sendval = this.struserid == '' ? "0" : this.struserid;
    this.getpro.start = this.getpro.start + this.getpro.end;
    this.getpro.urllink = this.categoryid;
    this.getpro.id = sendval;
    this.isproductLoading = true;
    let seq2 = this.api.post('productapi/getAllProductsByUserIdCatIDSellerPagewise', this.getpro).share();;
    seq2
      .map(res => res.json())
      .subscribe(res => {
        this.categoryproducts = this.categoryproducts.concat(res.Data);
        this.isproductLoading = false;

        if (Object.keys(res.Data).length < 10) {
          //event.target.disabled = true;
          alert("No more products!");
          this.loadmorebtn = false;
        }
        //event.target.complete();
      }, err => {
        this.isproductLoading = false;
        console.error('ERROR', err);
        //alert('Error');
      });
  }

  loadMoreFilterProducts()
  {
    this.objselectedsubcategory = this.subcategorylist.filter(element => element.isChecked === true);
    var commentArray: any[] = [];
    this.objselectedsubcategory.filter(function (object) {
      var comment = object.sub_cat_id;  //var comment = object.name;
      commentArray.push(comment);
    });
    var sendval = this.struserid == '' ? "0" : this.struserid;
    this.filterpara.start = this.filterpara.start + this.filterpara.end; 
    this.filterpara.end = 12;
    this.filterpara.pricestart = this.minValue; 
    this.filterpara.priceend = this.maxValue == 9000 ?  100000 : this.maxValue; 
    this.filterpara.urllink = this.categoryid;
    this.filterpara.id = sendval;
    this.filterpara.sub_cat_id = commentArray;

    this.isproductLoading = true;
    let seq2 = this.api.post('productapi/getSearchCatFilter', this.filterpara).share();;
    seq2
      .map(res => res.json())
      .subscribe(res => {
        this.categoryproducts = this.categoryproducts.concat(res.Data);
        this.isproductLoading = false;
        if (Object.keys(res.Data).length < 10) {
          alert("No more products!");
        }
      }, err => {
        this.isproductLoading = false;
        console.error('ERROR', err);
      });
  }

  onPageChanged(page: any) {
    var startIndex = (page - 1) * this.pageSize;
    this.pagedPosts = _.take(_.rest(this.categoryproducts, startIndex), this.pageSize);
  }

  onChange($event) {
    this.getpro.end = Number($event.target.value);
    this.pageSize = Number($event.target.value);
    this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
  }


  pricechanges($event) {
    // if (this.maxValue == 9000) {
    //   this.categoryproducts = this.defaultcategoryproducts.filter(obj => Number(obj.item_price) >= Number(this.minValue));
    //   //this.categoryproducts = this.categoryproducts.filter(obj =>  Number(obj.item_price) >= 13.94);
    //   this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    // }
    // else {
    //   this.categoryproducts = this.defaultcategoryproducts.filter(obj => Number(obj.item_price) >= Number(this.minValue) && Number(obj.item_price) <= Number(this.maxValue));
    //   //this.categoryproducts = this.categoryproducts.filter(obj =>  Number(obj.item_price) >= 13.94);
    //   this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    // }

    this.loadMoreFilterProducts();
  }

  public isfirst: number = 0;
  subcatdata: any = [];

  pricefilterapplied = false; 
  setprice(min: number,max:number)
  {
    this.pricefilterapplied = true;
    this.minValue = min;
    this.maxValue = max;
    this.subcategoryorpriceselected();
  }

  goapplypricefilter()
  {
    this.pricefilterapplied = true;
    this.subcategoryorpriceselected();
  }


  objselectedsubcategory: any;
  subcategoryorpriceselected() {
    this.objselectedsubcategory = this.subcategorylist.filter(element => element.isChecked === true);
    var commentArray: any[] = [];
    this.objselectedsubcategory.filter(function (object) {
      var comment = object.sub_cat_id;  //var comment = object.name;
      commentArray.push(comment);
    });

    var sendval = this.struserid == '' ? "0" : this.struserid;
    this.filterpara.start = 0; 
    this.filterpara.end = 12;

    this.filterpara.pricestart = this.minValue; 
    this.filterpara.priceend = this.maxValue; 
   // this.filterpara.priceend = this.maxValue == 9000 ?  100000 : this.maxValue; 

    this.filterpara.urllink = this.categoryid;
    this.filterpara.id = sendval;
    this.filterpara.sub_cat_id = commentArray;

    this.isproductLoading = true;
    let seq2 = this.api.post('productapi/getSearchCatFilter', this.filterpara).share();;
    seq2
      .map(res => res.json())
      .subscribe(res => {
       
        this.categoryproducts = res.Data;
        this.isproductLoading = false;
      }, err => {
        this.isproductLoading = false;
        console.error('ERROR', err);
      });
  }

  clearprice()
  {
    this.minValue = undefined;
    this.maxValue = undefined;
    this.pricefilterapplied = false;
  }

  sortproducts($event) {
    let selectedval = String($event.target.value);
    if (selectedval === 'atoz') {
      this.categoryproducts = this.categoryproducts.sort(function (a, b) {
        var nameA = a.item_name.toLowerCase(), nameB = b.item_name.toLowerCase()
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });

      this.categoryproducts = this.categoryproducts.sort(function (a, b) {
        var nameA = a.item_name.toLowerCase(), nameB = b.item_name.toLowerCase()
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });


      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
    else if (selectedval === 'ztoa') {
      this.categoryproducts = this.categoryproducts.sort(function (a, b) {
        var nameA = b.item_name.toLowerCase(), nameB = a.item_name.toLowerCase()
        if (nameA < nameB)
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });


      this.categoryproducts = this.categoryproducts.sort(function (a, b) {
        var nameA = b.item_name.toLowerCase(), nameB = a.item_name.toLowerCase()
        if (nameA < nameB)
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });

      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
    else if (selectedval === 'lowtoheigh') {
      this.categoryproducts = this.categoryproducts.sort((a, b) => a.item_price - b.item_price);
      this.categoryproducts = this.categoryproducts.sort((a, b) => a.item_price - b.item_price);

      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
    else if (selectedval === 'heightolow') {
      this.categoryproducts = this.categoryproducts.sort((a, b) => b.item_price - a.item_price);
      this.categoryproducts = this.categoryproducts.sort((a, b) => b.item_price - a.item_price);
      this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
    }
  }

  likeproduct(product: any) {
    if (!this.userloggedin) {
     // this._Router.navigate(['/login']);
     this.openloginmodal();
      return;
    }
    var statustosend = '';
    var index = this.categoryproducts.indexOf(product);

    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };

    if (product.IsuserLike === '0') {

      statustosend = '1';
      product.like_count = Number(product.like_count) + 1;
      this.categoryproducts[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.categoryproducts[index].IsuserLike = '0';
          product.like_count = Number(product.like_count) - 1;
          alert('Error');
        });
    } else {
      statustosend = '0';
      product.like_count = Number(product.like_count) - 1;
      this.categoryproducts[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemunlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.categoryproducts[index].IsuserLike = '1';
          product.like_count = Number(product.like_count) + 1;
          alert('Error');
        });

    }
  }

  openloginmodal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.logincontentref,{windowClass:"ngcustommodal"});
  }
  
  onsignupclick($event) {
    this.modalService.dismissAll();
    this.modalService.open(this.signupcontentref,{windowClass:"ngcustommodal"});
  }

  strReceivedmobile = "0";
  onotpsentsuccessfully($event) {
    this.strReceivedmobile = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.otpverificationcontentref,{windowClass:"ngcustommodal"});
    modalRef.componentInstance.strmobileno = $event.mobileno;
  }

  onloginsuccess($event) {
    this.modalService.dismissAll();
  }

  strReceivedmobileafterotpverification = "0";
  onotpverificationsuccessfull($event) {
    this.strReceivedmobileafterotpverification = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.newsignupcontentref,{windowClass:"ngcustommodal"});
    modalRef.componentInstance.strsignupmobileno = $event.mobileno;
  }

  onsignupsuccessfull($event)
  {
    this.modalService.dismissAll();
  }


  
  addToCart(product) {
    // let cartdata = $.parseJSON(localStorage.getItem('cart'));
    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': product,
          'qty': 1,
          'amount': parseFloat(product.saleprice)
          ,'adminuser_id': Number(product.adminuser_id)
        });
      } else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          if (product.items_id === cartdata[i].product.items_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + 1;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }

        if (added === 0) {
          cartdata.push({
            'product': product,
            'qty': 1,
            'amount': parseFloat(product.saleprice)
            ,'adminuser_id': Number(product.adminuser_id)
          });
        }

      }

      this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }
    });
  }


}
