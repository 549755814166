import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  public istrue: boolean = false;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //if (localStorage.getItem('sellerclient')) {
      if (sessionStorage.getItem('sellerclient')) {
      // logged in so return true
      this.istrue = true;
      return true;
    }
    this.istrue = false;
    alert('Please Login to proceed');
    this.router.navigate(['login']);
    return false;



  }
}
