export class clsaddress{

    address_id: string= "";
    user_id: string= "";
    order_id: string= "";

    username: string= "";
    address1: string= "";
    address2: string= "";

    state: string= "";
    city: string= "";
    county: string= "";
    post_code: string= "";
    phone: string= "";
    landmark: string= "";

}