import { product } from './../product/clsproduct';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { clsorder } from './clsorder';
import { clsProduct } from '../productdetails/clsProduct';
import { clsorderdetails } from './clsorderdetails';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { of } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  public uniqueseller: any[] = [];
  public sellerwisetotol: any[] = [];
  public struserid: string = "0";
  public userloggedin: boolean = false;
  imagebasepath: string = "";
  cartItems: any[] = [];
  total: number = 0;
  handlingcharges: number = 0;
  adminDiscount: number = 0;
  showEmptyCartMessage = false;
  addresslist: any[];
  adminsettings: any[];
  order = new clsorder();
  objProduct = new clsorderdetails();
  orderproduct: clsorderdetails[] = [];
  product = new clsProduct();
  finalamount: number = 0;
  productid: any;
  selectedAddressid: string = "";
  //public payPalConfig?: PayPalConfig;
  isLoading = false;

  deliveryoption: string = "delivery";
  paymentoption: string = "";
  ispickup: string = "0";
  storeaddress: string = "";
  storecompanyname: string = "";

  initialcashbackamount = 0;
  cashbackamount = 0;
  useCashback = false;
  discountedamount = 0;
  finaltotal = 0;
  appliedcode: string = "";
  appliedcodeDESC: string = "";
  discountcode: string = "";
  dicountpercentage: string = "";
  maxdiscount: string = "";
  discountpanel = false;


  constructor(protected localStorage: LocalStorage, private elementRef: ElementRef,
    public api: Api, private _router: Router, public globalSettings: Settings, private _Router: Router, private toastr: ToastrService,
    private _routeParams: ActivatedRoute) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {

    this.getuseralladdress();
    //  this.getadminsetting();
    //this.initConfig();
    this.getcashbackamountuserwise();

    var id = this._routeParams.params.subscribe(params => {
      var id = params["id"];
      this.productid = '';
      if (id !== undefined) {
        this.productid = id;
      }
      if (!id) {
        this.localStorage.getItem('cart').subscribe((cartdata) => {
          this.cartItems = $.parseJSON(cartdata);
          console.log('this.cartItems');
          console.log(this.cartItems);
          this.isLoading = true;
          let seq = this.api.post('adminsettingapi/getalladminsetting', null).share();
          seq
            .map(res => res.json())
            .subscribe(res => {
              if (res.Data)
                if (typeof (res.Data) !== "undefined" && res.Data !== null && res.Data.length > 0) {
                  this.adminsettings = res.Data[0];
                  this.handlingcharges = Number(res.Data[0].webhandlercharge);
                  this.adminDiscount = Number(res.Data[0].discount);

                  if (this.cartItems.length > 0) {
                    this.cartItems.forEach((item, index) => {
                      console.log(item);
                      //this.total = this.total + (item.product.item_price * item.qty);
                      this.total = this.total + (item.product.saleprice * item.qty);
                    });
                    this.finalamount = this.total;
                    console.log(this.total);
                    this.setfinalamount();
                  }
                  else {
                    this.showEmptyCartMessage = true;
                  }
                }
              this.isLoading = false;
            }, err => {
              alert(err);
            });

          const map2 = new Map();
          for (const item of this.cartItems) {
            if (!map2.has(item.product.sellername)) {
              map2.set(item.product.sellername, true);    // set any value to Map
              this.uniqueseller.push({
                id: item.product.adminuser_id,
                name: item.product.sellername,
                total: 0,
                minbye: 0,
                courierchargesformin: 0,
                finaltotalamount: 0,
                deliverycharges: 0,
                itemsinfo: []
              });
            }
          }

          console.log('this.uniqueseller')
          console.log(this.uniqueseller)
          console.log(this.uniqueseller.length)

          // Group By Seller and sum of amount
          var map = this.cartItems.reduce(function (map, invoice) {
            var name = invoice.adminuser_id == undefined ? '' : invoice.adminuser_id;
            var price = +invoice.amount
            map[name] = (map[name] || 0) + price
            return map
          }, {})

          console.log('map')
          console.log(map)

          // Mapping name to property
          this.sellerwisetotol = Object.keys(map).map(function (name) {
            return {
              sellerid: name,
              totalPriceGross: map[name]
            }
          })
          console.log('sellerwisetotol')
          console.log(this.sellerwisetotol)

          if (this.uniqueseller != null && this.uniqueseller.length > 0) {
            for (let i = 0; i < this.uniqueseller.length; i++) {
              const element = this.uniqueseller[i].id;
              var found = this.sellerwisetotol.filter(obj => obj.sellerid === element);
              this.uniqueseller[i].total = found[0]['totalPriceGross'];


              var sellercart = this.cartItems.filter(obj => obj.adminuser_id === Number(element));
              console.log('sellercart');
              console.log(sellercart);
              this.uniqueseller[i].itemsinfo = sellercart;
              let seller: { adminuser_id: string } = {
                adminuser_id: element
              };

              let seq = this.api.post('Sellersettingapi/getsellersettingdetails', seller).share();
              seq
                .map(res => res.json())
                .subscribe(res => {
                  console.log('res');
                  console.log(res);
                  if (res != null && res.Data != null) {
                    this.uniqueseller[i].courierchargesformin = res.Data.courierchargesformin == "" ? 0 : Number(res.Data.courierchargesformin);
                    this.uniqueseller[i].minbye = res.Data.minbye == "" ? 0 : Number(res.Data.minbye);
                    if (this.uniqueseller[i].total < this.uniqueseller[i].minbye) {
                      this.uniqueseller[i].finaltotalamount = this.uniqueseller[i].total + this.uniqueseller[i].courierchargesformin;
                      this.uniqueseller[i].deliverycharges = this.uniqueseller[i].courierchargesformin;
                    }
                    else {
                      this.uniqueseller[i].finaltotalamount = this.uniqueseller[i].total;
                      this.uniqueseller[i].deliverycharges = 0;
                    }

                    this.finaltotal  = this.uniqueseller.reduce((accumulator, current) => accumulator + current.finaltotalamount, 0);
                    console.log('this.finaltotal');
                    console.log(this.finaltotal);
                  }
                }, err => {
                  console.log("Error Getting seller setting details ");
                  console.log(err);
                });
            }
            console.log('New uniqueseller');
            console.log(this.uniqueseller);
          
          }

         
        });
      }
      else {
        let currentuserid = this.struserid === "" ? "0" : this.struserid;
        let user: { id: string, items_id: string } = {
          id: currentuserid,
          items_id: id
        };

        let seq = this.api.post('productapi/getProductDetailsbyuseridseller', user).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
            console.log('res');
            console.log(res);
            this.product = res.Data[0];
            console.log('this.product');
            console.log(this.product);
            console.log('this.cartItems --- 2 ');
            console.log(this.cartItems);

            //this.cartItems = res.Data;
            if (this.product != null) {
              this.total = Number(this.product.saleprice) * 1;
              this.finalamount = this.total;
            }

          }, err => {
            alert("Error");
          });

      }
    });
  }

  showdiscountcode() {
    this.discountcode = "";
    this.discountpanel = !this.discountpanel;
  }


  ordervalidation(): boolean {
    let result = true;
    try {
      if (this.deliveryoption == "" || this.deliveryoption == undefined) {
        this.toastr.error('Please Select Delivery Option');
        result = false;
      }
      else if (this.deliveryoption == "delivery" && this.selectedAddressid == "") {
        this.toastr.error('Please Select Delivery Address');
        this.ispickup = "0";
        result = false;
      }
      if (this.paymentoption == "" || this.paymentoption == undefined) {
        this.toastr.error('Please Select Payment Option');
        result = false;
      }
      return result;
    } catch (error) {
      return result;
    }
  }

  getcashbackamountuserwise() {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    try {
      let user: { user_id: string } = {
        user_id: this.struserid
      };
      let seq = this.api.post('Cashbacktran/getcashbackamountuserwise', user);
      seq
        .map(res => res.json())
        .subscribe(res => {
          console.log('res');
          console.log(res);
          if (res['Data'] != null) {
            this.cashbackamount = res['Data'].cashbackamt;
            this.initialcashbackamount = res['Data'].cashbackamt;
            console.log('this.cashbackamount');
            console.log(this.cashbackamount);
          }
        }, err => {
          console.log('Error');
          console.log(err);
        });
    } catch (error) {
    }
  }


  myChange($event) {
    console.log($event);
    console.log("this.useCashback", this.useCashback);
    this.setfinalamount();
  }


  checkdiscountcode() {
    try {
      this.dicountpercentage = "";
      this.maxdiscount = "";
      let user: { discountcode: string } = {
        discountcode: this.discountcode
      };
      let seq = this.api.post('discountcodeapi/calculatediscount', user);
      seq
        .map(res => res.json())
        .subscribe(res => {
          console.log('res for checkcode');
          console.log(res);
          if (res['Data'] != null) {
            if (res['Data'] == 'Invalid code') {
              alert('Code is Invalid or Expired');
              this.appliedcode = "";
              this.appliedcodeDESC = "";
              this.discountcode = "";
              this.maxdiscount = "";
              this.dicountpercentage = "";
              this.setfinalamount();
            }
            else {
              this.appliedcode = res['Data'].discountcode;
              this.appliedcodeDESC = res['Data'].dicountpercentage + "% Discount, Maximum ₹ " + res['Data'].maxdiscount + " off";
              //this.discountcode = res['Data'].discountcode;
              this.dicountpercentage = res['Data'].dicountpercentage;
              this.maxdiscount = res['Data'].maxdiscount;
              this.discountpanel = false;
              this.discountcode = "";
              this.setfinalamount();
            }
          }
          else {
            this.discountcode = "";
          }
        }, err => {
          this.discountcode = "";
          this.setfinalamount();
          //alert('Error');
        });
    } catch (error) {
    }
  }

  clearappliedcode() {
    this.appliedcode = "";
    this.appliedcodeDESC = "";
    this.discountcode = "";
    this.maxdiscount = "";
    this.dicountpercentage = "";
    this.discountpanel = true;
    this.setfinalamount();
  }

  setfinalamount() {
    this.discountedamount = 0;
    if (this.useCashback) {
      if (this.cashbackamount > this.total) {
        this.finaltotal = 0;
        this.cashbackamount = this.total;
      }
      else {
        //this.finaltotal = this.total - this.cashbackamount;
        this.finaltotal = this.total + Number(this.handlingcharges) - this.cashbackamount - Number(this.adminDiscount);
      }
    }
    else {
      this.finaltotal = this.total + Number(this.handlingcharges) - Number(this.adminDiscount);
    }
    if (this.appliedcode != '') {
      var amount = this.finaltotal * Number(this.dicountpercentage) / 100;
      console.log('amount');
      console.log(amount);

      console.log('maxdiscount');
      console.log(this.maxdiscount);
      if (amount > Number(this.maxdiscount)) {
        this.discountedamount = Number(this.maxdiscount);
        this.finaltotal = this.finaltotal - Number(this.maxdiscount);
      }
      else {
        this.discountedamount = amount;
        this.finaltotal = this.finaltotal - amount;
      }
    }
  }

  getuseralladdress() {
    let user: { user_id: string } = { user_id: this.struserid };
    let seq = this.api.post('userdata/getuseraddress', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.addresslist = res.Data;
      }, err => {
        alert('Error');
      });
  }

  getadminsetting() {
    this.isLoading = true;
    let seq = this.api.post('adminsettingapi/getalladminsetting', null).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        if (res.Data)
          if (typeof (res.Data) !== "undefined" && res.Data !== null && res.Data.length > 0) {
            this.adminsettings = res.Data[0];
            this.handlingcharges = Number(res.Data[0].webhandlercharge);
            this.adminDiscount = Number(res.Data[0].discount);
          }
        this.isLoading = false;
      }, err => {
        alert('Error');
      });
  }

  placeorderresponse: any;

  presentAlertConfirm() {
    if (confirm('Do you want to place order?')) {
      this.placeorder();
    } else {
      
    }
  }

  placeorder(paymentid: string = '') {
    if (this.ordervalidation() == false)
      return;
    if (this.deliveryoption == "pickup") {
      this.ispickup = "1";
      this.selectedAddressid = "";
    }
    if (this.uniqueseller.length > 0) {

      var orderplacedcount = 0;

      for (let i = 0; i < this.uniqueseller.length; i++) {
        var sellerid = this.uniqueseller[i].id
        var total = this.uniqueseller[i].total;
        var finaltotalamount = this.uniqueseller[i].finaltotalamount;
        var courierchargesformin = this.uniqueseller[i].courierchargesformin;

        const element = this.uniqueseller[i].itemsinfo;
        this.orderproduct = [];
        let data = [];
        element.forEach((item, index) => {
          this.objProduct = new clsorderdetails();
          this.objProduct.cart_id = "";
          this.objProduct.item_name = item.product.item_name;
          this.objProduct.item_qty = item.qty;
          this.objProduct.item_sale_price = item.product.saleprice;
          this.objProduct.item_tax = item.product.item_tax;
          this.objProduct.item_taxamount = "0";
          this.objProduct.item_total_price = item.amount;
          this.objProduct.items_id = item.product.items_id;
          this.objProduct.order_id = "";
          this.objProduct.user_id = this.struserid;
          this.objProduct.adminuser_id = sellerid;
          // this.globalSettings._currentstoreid.subscribe(value => this.storeid = value);
          // //this.objProduct.adminuser_id = this.currentfranchisestore.adminuser_id;
          // this.objProduct.adminuser_id = this.storeid;
          this.orderproduct.push(this.objProduct);
        });

        data.push({
          'products': this.orderproduct,
          'user_id': this.struserid,
          'payment_mode': this.paymentoption,
          'payment_refno': paymentid,
          'note': '',
          'address_id': this.selectedAddressid,
          'sub_total': total,
          'total_price': finaltotalamount,
          'handlingCharge': courierchargesformin,
          'adminDiscount': 0,
          'ispickup': this.ispickup,
          'adminuser_id': sellerid,
          'cashback': 0,     //this.useCashback ? this.cashbackamount : "0",
          'discountcode': "", // this.appliedcode,
          'discountamt': 0 // this.discountedamount
        });


        let seq = this.api.post('Sellapi/additemsTransseller', data);
        seq
          .map(res => res.json())
          .subscribe(res => {
            console.log('res of place orde');
            console.log(res);
            this.placeorderresponse = res;
            if (this.placeorderresponse.Data.toString().includes("Opps Product not available")) {
              this.toastr.info(this.placeorderresponse.Data.toString());
            }
            else if (this.placeorderresponse.Data = "Sucessfully Itemsell added") {
              orderplacedcount = orderplacedcount + 1;
              console.log('orderplacedcount')
              console.log(orderplacedcount)
            }
            
            if((i == this.uniqueseller.length -1))
            {
              if (orderplacedcount == this.uniqueseller.length) {
                this.toastr.success('Your Complete order has been placed');
                let removecartforuser: { user_id: string } = { user_id: this.struserid };
                const seq = this.api.post('cartdataapi/deletecartdata', removecartforuser);
                seq
                  .subscribe(res => {
                    this.cartItems = [];
                    this.localStorage.removeItem('cart').subscribe(() => {});
                    this.total = 0;
                    this.globalSettings.setcount(0);
                    if (this.cartItems.length === 0) {
                      this.showEmptyCartMessage = true;
                    }
                  });
                setTimeout(() => {
                  this._Router.navigate(['/orders']);
                }, 1000);
              }
              else {
                this.toastr.warning('Partial order has been placed. Please check Order list');
              }
            }
          }, err => {
            this.toastr.error(err);
          });
      }
    }

  }

  // placeorder() {
  //   if (confirm('Do you want to place order?')) {
  //     if (this.selectedAddressid != "") {
  //       console.log('Inside Place Order');
  //       console.log(this.productid);
  //       let data = [];

  //       if (this.productid !== '') {
  //         this.objProduct = new clsorderdetails();
  //         this.objProduct.cart_id = "";
  //         this.objProduct.item_name = this.product.item_name;
  //         this.objProduct.item_qty = 1;
  //         this.objProduct.item_sale_price = this.product.saleprice;
  //         this.objProduct.item_tax = "0";
  //         this.objProduct.item_taxamount = "0";
  //         this.objProduct.item_total_price = String(this.total);
  //         this.objProduct.items_id = this.product.items_id;
  //         this.objProduct.order_id = "";
  //         this.objProduct.user_id = this.struserid;
  //         this.objProduct.adminuser_id = this.product.adminuser_id;

  //         this.orderproduct.push(this.objProduct);
  //       }
  //       else {
  //         if (this.cartItems.length > 0) {
  //           this.cartItems.forEach((item, index) => {
  //             this.objProduct = new clsorderdetails();
  //             this.objProduct.cart_id = "";
  //             this.objProduct.item_name = item.product.item_name;
  //             this.objProduct.item_qty = item.qty;
  //             this.objProduct.item_sale_price = item.product.saleprice;
  //             this.objProduct.item_tax = item.product.item_tax;
  //             this.objProduct.item_taxamount = "0";
  //             this.objProduct.item_total_price = item.amount;
  //             this.objProduct.items_id = item.product.items_id;
  //             this.objProduct.order_id = "";
  //             this.objProduct.user_id = this.struserid;
  //             this.objProduct.adminuser_id = item.product.adminuser_id;

  //             this.orderproduct.push(this.objProduct);
  //             // this.total = this.total + (item.product.item_price *item.product.qty);
  //             // this.total = Math.round(this.total);
  //           });

  //         }
  //         else {
  //           alert('No product in cart');
  //         }
  //       }
  //       console.log('Array Built');
  //       console.log(this.orderproduct);

  //       data.push({
  //         'products': this.orderproduct,
  //         'user_id': this.struserid,
  //         'payment_mode': 'PayPal',
  //         'payment_refno': '',
  //         'note': '',
  //         'address_id': this.selectedAddressid,
  //         'sub_total': this.total,
  //         'total_price': this.total,
  //         'handlingCharge': this.handlingcharges,
  //         'adminDiscount': this.adminDiscount

  //       });
  //       //additemsellTran
  //       //let seq = this.api.post('Sellapi/additemsTrans', data).share();
  //       let seq = this.api.post('Sellapi/additemsTransseller', data).share();
  //       seq
  //         .map(res => res.json())
  //         .subscribe(res => {
  //           console.log('inside updateaddress');
  //           this.toastr.info('Your order has been placed');


  //           if (this.struserid !== '0') {
  //             if (this.productid === '') {
  //               let removecartforuser: { user_id: string } = { user_id: this.struserid };
  //               const seq = this.api.post('cartdataapi/deletecartdata', removecartforuser).share();
  //               seq
  //                 .map(res => res.json())
  //                 .subscribe(res => {
  //                   this.cartItems = [];
  //                   this.localStorage.setItem('cart', JSON.stringify(this.cartItems)).subscribe(() => {
  //                     this.total = 0;
  //                     this.globalSettings.setcount(0);
  //                     if (this.cartItems.length === 0) {
  //                       this.showEmptyCartMessage = true;
  //                     }
  //                   });

  //                 }, err => {
  //                   alert('Error');
  //                 });
  //             }
  //             this._router.navigate(['/orders']);
  //           }
  //           //this._router.navigate(['policytype']);
  //         }, err => {
  //           alert("Error");
  //         });
  //     }
  //     else {
  //       this.toastr.warning('Please Select Delivery Address');
  //     }
  //   }
  // }



}




// this.payPalConfig = new PayPalConfig(
//   PayPalIntegrationType.ClientSideREST,
//   PayPalEnvironment.Sandbox,
//   {
//     commit: true,
//     client: {
//       sandbox:
//         'AeQc9J4cOLADT3GlkYEYXYcJ4egdK5Ox57e_yDXyWU9vN7kg2VRUZMB7WPfN6GSpBukScKPPb5MbMz64'
//     },
//     button: {
//       label: 'paypal',
//       layout: 'vertical'
//     },
//     onAuthorize: (data, actions) => {
//       console.log('Authorize');
//       return of(undefined);
//     },
//     onPaymentComplete: (data, actions) => {
//       console.log('OnPaymentComplete');
//       console.log(data);
//     },
//     onCancel: (data, actions) => {
//       console.log('OnCancel');
//     },
//     onError: err => {
//       console.log('OnError');
//     },
//     onClick: () => {
//       console.log('onClick');
//     },
//     validate: (actions) => {
//       console.log(actions);
//     },
//     experience: {
//       noShipping: true,
//       brandName: 'gigStorm'
//     },
//     transactions: [
//       {
//         amount: {
//           total: this.finalamount,
//           currency: 'USD',
//           // details: {
//           //   subtotal: 30.00,
//           //   tax: 0.07,
//           //   shipping: 0.03,
//           //   handling_fee: 1.00,
//           //   shipping_discount: -1.00,
//           //   insurance: 0.01
//           // }
//         },
//         // custom: 'Custom value',
//         // item_list: {
//         //   items: [
//         //     {
//         //       name: 'hat',
//         //       description: 'Brown hat.',
//         //       quantity: 5,
//         //       price: 3,
//         //       tax: 0.01,
//         //       sku: '1',
//         //       currency: 'USD'
//         //     },
//         //     {
//         //       name: 'handbag',
//         //       description: 'Black handbag.',
//         //       quantity: 1,
//         //       price: 15,
//         //       tax: 0.02,
//         //       sku: 'product34',
//         //       currency: 'USD'
//         //     }],
//         //   shipping_address: {
//         //     recipient_name: 'Brian Robinson',
//         //     line1: '4th Floor',
//         //     line2: 'Unit #34',
//         //     city: 'San Jose',
//         //     country_code: 'US',
//         //     postal_code: '95131',
//         //     phone: '011862212345678',
//         //     state: 'CA'
//         //   },
//         // },
//       }
//     ],
//     note_to_payer: 'Contact gigStorm if you have troubles processing payment'
//   });



  // private initConfig(): void {
  //   this.payPalConfig = new PayPalConfig(
  //     PayPalIntegrationType.ClientSideREST,
  //     PayPalEnvironment.Sandbox,
  //     {
  //       commit: true,
  //       client: {
  //         sandbox:
  //           'AeQc9J4cOLADT3GlkYEYXYcJ4egdK5Ox57e_yDXyWU9vN7kg2VRUZMB7WPfN6GSpBukScKPPb5MbMz64'
  //       },
  //       button: {
  //         label: 'paypal',
  //         layout: 'vertical'
  //       },
  //       onAuthorize: (data, actions) => {
  //         console.log('Authorize');
  //         return of(undefined);
  //       },
  //       onPaymentComplete: (data, actions) => {
  //         console.log('OnPaymentComplete');
  //         console.log(data);
  //       },
  //       onCancel: (data, actions) => {
  //         console.log('OnCancel');
  //       },
  //       onError: err => {
  //         console.log('OnError');
  //       },
  //       onClick: () => {
  //         console.log('onClick');
  //       },
  //       validate: (actions) => {
  //         console.log(actions);
  //       },
  //       experience: {
  //         noShipping: true,
  //         brandName: 'gigStorm'
  //       },
  //       transactions: [
  //         {
  //           amount: {
  //             total: this.finalamount,
  //             currency: 'INR',
  //             // details: {
  //             //   subtotal: 30.00,
  //             //   tax: 0.07,
  //             //   shipping: 0.03,
  //             //   handling_fee: 1.00,
  //             //   shipping_discount: -1.00,
  //             //   insurance: 0.01
  //             // }
  //           },
  //           // custom: 'Custom value',
  //           // item_list: {
  //           //   items: [
  //           //     {
  //           //       name: 'hat',
  //           //       description: 'Brown hat.',
  //           //       quantity: 5,
  //           //       price: 3,
  //           //       tax: 0.01,
  //           //       sku: '1',
  //           //       currency: 'USD'
  //           //     },
  //           //     {
  //           //       name: 'handbag',
  //           //       description: 'Black handbag.',
  //           //       quantity: 1,
  //           //       price: 15,
  //           //       tax: 0.02,
  //           //       sku: 'product34',
  //           //       currency: 'USD'
  //           //     }],
  //           //   shipping_address: {
  //           //     recipient_name: 'Brian Robinson',
  //           //     line1: '4th Floor',
  //           //     line2: 'Unit #34',
  //           //     city: 'San Jose',
  //           //     country_code: 'US',
  //           //     postal_code: '95131',
  //           //     phone: '011862212345678',
  //           //     state: 'CA'
  //           //   },
  //           // },
  //         }
  //       ],
  //       note_to_payer: 'Contact gigStorm if you have troubles processing payment'
  //     }
  //   );
  // }