import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatPaginatorModule,
	MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
	MatDialogModule, MatGridListModule, MatIconModule, MatInputModule,
	MatListModule, MatMenuModule, MatProgressBarModule, MatProgressSpinnerModule,
	MatRadioModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSortModule,
	MatSlideToggleModule, MatSnackBarModule, MatTableModule, MatTabsModule, MatToolbarModule,
	MatTooltipModule, MatFormFieldModule, MatExpansionModule, MatStepperModule
} from '@angular/material';

import { NguCarouselModule } from '@ngu/carousel';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader, TranslateStaticLoader } from 'ng2-translate/ng2-translate';
import { SidebarModule } from 'ng-sidebar';
import { Ng5BreadcrumbModule, BreadcrumbService } from 'ng5-breadcrumb';
import 'hammerjs';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import {DpDatePickerModule} from 'ng2-date-picker';

// import { SSRSReportViewerModule } from 'ngx-ssrs-reportviewer';
export function createTranslateLoader(http: Http) {
	return new TranslateStaticLoader(http, 'assets/i18n', '.json');
}

const perfectScrollbarConfig: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};
import { LoginComponent } from './pages/login/login.component';
import { Api } from './service/api/api';
import { Settings } from './service/settings/settings';
import { AuthGuard } from './guard/auth.guard';
import { AppRoutingModule } from './app.routing';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserComponent } from './pages/user/user.component';
import { UseraddComponent } from './pages/user/useradd.component';
import { CompanymasterComponent } from './pages/companymaster/companymaster.component';
import { CompanymasterlistComponent } from './pages/companymasterlist/companymasterlist.component';
import { ProductlistComponent } from './pages/product/productlist.component';
import { ProductComponent } from './pages/product/product.component';
import { CarouselComponent } from './shared/carousel';
import { DirectivesModule } from './core/directive/directives.module';
import { RoughtemplateComponent } from './pages/roughtemplate/roughtemplate.component';
import { AppheaderComponent } from './pages/appheader/appheader.component';
import { AppfooterComponent } from './pages/appfooter/appfooter.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { LocalStorageModule } from '@ngx-pwa/local-storage';
import { CartComponent } from './pages/cart/cart.component';
import { ProductdetailsComponent } from './pages/productdetails/productdetails.component';
import { CategoryComponent } from './pages/category/category.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PaginationComponent } from './shared/pagination.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { Ng5SliderModule } from 'ng5-slider';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { SubcategoryComponent } from './pages/subcategory/subcategory.component';
import { SearchComponent } from './pages/search/search.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { OrderComponent } from './pages/order/order.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { ArchwizardModule } from 'ng2-archwizard';
import { LoadingModule } from 'ngx-loading';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogdetailsComponent } from './pages/blog/blogdetails.component';
import { LocationComponent } from './pages/location/location.component';
import { SellerproductsComponent } from './pages/sellerproducts/sellerproducts.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BookingComponent } from './pages/booking/booking.component';
import { NewloginComponent } from './pages/newlogin/newlogin.component';
import { MobilesignupComponent } from './pages/mobilesignup/mobilesignup.component';
import { OtpverificationComponent } from './pages/otpverification/otpverification.component';
import { SignupComponent } from './pages/signup/signup.component';
import { NewdashboardComponent } from './pages/newdashboard/newdashboard.component';
import { ChooselocationComponent } from './pages/chooselocation/chooselocation.component';
import { SellerfoundComponent } from './pages/sellerfound/sellerfound.component';
import { SellersignupComponent } from './pages/sellersignup/sellersignup.component';
import { CashbackComponent } from './pages/cashback/cashback.component';
import { ForgotpassemailverificationComponent } from './pages/forgotpassemailverification/forgotpassemailverification.component';
import { ForgotpassotpverificationComponent } from './pages/forgotpassotpverification/forgotpassotpverification.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { CartMedicationComponent } from './pages/cart-medication/cart-medication.component';
import { UploadprescriptionComponent } from './pages/uploadprescription/uploadprescription.component';

@NgModule({
	declarations: [AppComponent, LoginComponent, SidebarComponent,
		NavbarComponent, DashboardComponent, UserComponent, UseraddComponent, CompanymasterComponent, CompanymasterlistComponent		
		, ProductlistComponent, ProductComponent,CarouselComponent, RoughtemplateComponent, AppheaderComponent, AppfooterComponent, WishlistComponent, CartComponent,
		ProductdetailsComponent,ContactusComponent,AboutusComponent,PaginationComponent,
		CategoryComponent,
		CheckoutComponent,
		SubcategoryComponent,
		SearchComponent,
		ProfileComponent,
		OrderComponent,
		BlogComponent,
		BlogdetailsComponent,
		LocationComponent,
		SellerproductsComponent,
		BookingComponent,
		NewloginComponent,
		SignupComponent,
		MobilesignupComponent,
		OtpverificationComponent,
		NewdashboardComponent,
		ChooselocationComponent,
		SellerfoundComponent,
		SellersignupComponent,
		CashbackComponent,
		ForgotpassemailverificationComponent,
		ForgotpassotpverificationComponent,
		ForgotpasswordComponent,
		CartMedicationComponent,
		UploadprescriptionComponent,
	],
	imports: [AppRoutingModule, BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, CdkTableModule,
		LocalStorageModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		DpDatePickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatSidenavModule,
		MatSnackBarModule,
		MatStepperModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
		LoadingModule,
		SidebarModule.forRoot(),
		
		FlexLayoutModule,
		Ng5SliderModule,
		NgxPayPalModule,
		ArchwizardModule,
		NguCarouselModule,
		Ng2SearchPipeModule,
		DigitOnlyModule,
		SelectDropDownModule,
		NgbModalModule.forRoot(),
		Ng5BreadcrumbModule.forRoot(),
		//AgmCoreModule.forRoot({ apiKey: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk' }),
		AgmCoreModule.forRoot({
			 apiKey: 'AIzaSyBWQzfg1cM3ZHvr-tPQ8zXlKOvXPSsSl98',
			 libraries: ["places", "geometry"]
			}),
		PerfectScrollbarModule,
		HttpModule, HttpClientModule, DirectivesModule,
		TranslateModule.forRoot({
			provide: TranslateLoader,
			useFactory: (createTranslateLoader),
			deps: [Http]
		}),
		ToastrModule.forRoot(
			{
				timeOut: 4000,
				positionClass: 'toast-top-right',
				preventDuplicates: true,
			}
		)
	],
	providers: [BreadcrumbService, Api, AuthGuard, Settings],
	bootstrap: [AppComponent],
	entryComponents:[ChooselocationComponent]		
})
export class AppModule { }
