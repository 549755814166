import { CustomValidator2 } from './../../shared/customvalidator';
import { Component, OnInit, ElementRef, EventEmitter, Output,Input} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Api } from '../../service/api/api';
import { Settings } from '../../service/providers';
import { Router, ActivatedRoute } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @Input() strsignupmobileno = '';
  @Output() signupsuccessfull : EventEmitter<{mobileno: string}> = new EventEmitter<{mobileno: string}>() ;

  imagebasepath: string = '';
  isLoading = false;
  result: any;
  isLoggedIn: boolean;
  public userloggedin: boolean = false;
  frmsignup: FormGroup;
  moibleno: any;
  
  constructor(fb: FormBuilder, public api: Api, public settings: Settings, private _routeParams: ActivatedRoute,
    private _Router: Router, private toastr: ToastrService) {
      this.frmsignup = fb.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        contactno1: ['', Validators.required],
        email: ['', Validators.compose([
          Validators.required,
          CustomValidator2.cannotContainspace,
          CustomValidator2.email
        ])],
        user_pass: ['', [
          Validators.required,
          Validators.minLength(3),
          CustomValidator2.cannotContainspace
        ]],
        reuser_pass: ['', Validators.required]
      }, { validator: CustomValidator2.passwordsShouldMatch });
  }

  ngOnInit() {

    console.log('this.strsignupmobileno');
    console.log(this.strsignupmobileno);
    this.moibleno = this.strsignupmobileno;
    this.frmsignup.controls['contactno1'].setValue(this.moibleno);
    
    // this._routeParams.paramMap.subscribe(paramMap => {
    //   this.moibleno = paramMap.get('mobileno');
    //   console.log('In Signup Mobile');
    //   console.log(this.moibleno);
    //   this.frmsignup.controls['contactno1'].setValue(this.moibleno);
    // });
  }

  
  signupuser() {
    let user: { firstname: string, lastname: string, email: string, contactno1: string, user_pass: string, IsAdmin: string } = {
      firstname: this.frmsignup.controls.firstname.value,
      lastname: this.frmsignup.controls.lastname.value,
      email: this.frmsignup.controls.email.value,
      contactno1: this.frmsignup.controls.contactno1.value,
      user_pass: this.frmsignup.controls.user_pass.value,
      IsAdmin: "0",
    };

    this.isLoading = true;
    let seq = this.api.post_httpclient('userdata/adminregister', user);
    seq
      .subscribe(res => {
        if (res['status'] == "success" && res['Data'] == "User allready register") {
          //alert('User allready register');
          this.toastr.warning('User allready register');
          this.frmsignup.controls.email.setErrors({
            alreadyregistered: true
          });
        }
        else if (res['status'] == "success" && res['Data'] == "Sucessfully Register") {
          //alert('User Registered Successfully');
          this.toastr.success('User Registered Successfully!!');
          //this._Router.navigate(['/login']);
          this.signupsuccessfull.emit({ mobileno: this.frmsignup.controls.contactno1.value});
        }
        else {
          // alert('Invalid Signup Case');
          this.toastr.warning('Invalid Signup Case');
          this.frmsignup.reset({
            firstname: '', lastname: '', email: '', user_pass: '', reuser_pass: ''
          });
        }
      }, err => {
        //alert("Error");
        this.toastr.error('Error in Signup');
        this.frmsignup.reset({
          firstname: '', lastname: '', email: '', user_pass: '', reuser_pass: ''
        });
      });
  }


}
