import { Component, OnInit, ElementRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import 'rxjs/add/operator/delay';
declare var $: any;
import * as _ from 'underscore'
// import { HttpClient } from "@angular/common/http";
// import { Observable } from "rxjs/Observable";
// import { switchMap, map } from 'rxjs/operators';
// import { interval } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public selectedDate: any;
  public struserid = '0';
  public userloggedin = false;
  public loading = false;
  public showdashview = false;
  productlist: any[];
  sellerproductlist: any[];
  dashviewlist: any[];
  trending: any[];
  dashstandalonggroup: any[];
  bestseller: any[];
  newarrivals: any[];
  bannerlist: any[];
  imagebasepath = '';
  cartcount = 0;
  catcount = 0;
  categorylist: any[];
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };
  pageSize = 16;
  pagedPosts: any[];
  public cityarea_id = '';
  from: '';
  constructor(protected localStorage: LocalStorage, public api: Api, private _router: Router, private elementRef: ElementRef,
    private toastr: ToastrService, public globalSettings: Settings, private _routeParams: ActivatedRoute,
    //,private httpClient: HttpClient
  ) {
  }

  ngOnInit() {


    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._cityarea_id.subscribe(value => this.cityarea_id = value);
    this.imagebasepath = this.api._imagebasepath;
    //  $('#dashboardviews').hide();
    this.getbannerlist();
    this.getdahsviewlist();
    this.getproductlist();
    //this.getsellerproductlist();
    //this.OB_getproductlist();

    var name = this._routeParams.queryParams.subscribe(query => {
      this.from = query["locationupdated"];
      if (this.from != "" && this.from != undefined) {
        this.getbannerlist();
        this.getdahsviewlist();
        this.getproductlist();
      }
    });
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  getcategorylist() {
    let user: { email: string } = { email: 'test2@test.com' };
    let seq = this.api.post('categoryapi/getAllCategories', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.categorylist = res.Data;
        if (this.categorylist.length > 0) {
          this.catcount = this.categorylist.length;
        }
      }, err => {
        alert('Error');
      });
  }


  getbannerlist() {
    this.loading = true;
    let seq = this.api.post('bannerapi/getallbanner', '').share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.bannerlist = res.Data;
        this.loading = false;
      }, err => {
        alert('Error');
        this.loading = false;
      });
  }


  getproductlist() {
    // this.loading = true;
    //cityarea_id

    //this.cityarea_id = this.cityarea_id == '' ? "-1" : this.cityarea_id;
    let user: { id: string, cityarea_id: string } = {
      id: this.struserid, cityarea_id: this.cityarea_id
    };
    if (user.id === '') {
      user.id = '0';
    }
    //let seq = this.api.post('productapi/getAllProducts', user).share();
    let seq = this.api.post('productapi/getAllProductsseller', user).share();
    seq
      .map(res => res.json())
      .delay(1500)
      .subscribe(res => {
        this.productlist = res.Data;
        this.pagedPosts = _.take(this.productlist, this.pageSize);
        //  this.loading = false;
      }, err => {
        //  this.loading = false;
        alert('Error');
      });
  }

  getsellerproductlist() {
    // this.loading = true;
    let user: { id: string } = {
      id: this.struserid
    };
    if (user.id === '') {
      user.id = '0';
    }

    let seq = this.api.post('productapi/getallproductBySeller', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.sellerproductlist = res.Data;
        //  this.pagedPosts = _.take(this.productlist, this.pageSize);
        //  this.loading = false;
      }, err => {
        //  this.loading = false;
        alert('Error');
      });
  }


  // ogcustomersObservable: Observable<any[]>;
  // customersObservable: Observable<any[]>;

  // OB_getproductlist() {
  //   let user: { id: string } = {
  //     id: this.struserid
  //   };
  //   if (user.id === '') {
  //     user.id = '0';
  //   }
  //   this.customersObservable = this.api
  //     .post('productapi/getAllProducts', user)
  //     .map(response => {
  //       return response.json().Data;
  //     });

  //   //// Every 5 second  it will call 
  //   // this.customersObservable = interval(5000).pipe(
  //   //   switchMap(() => this.api.post('categoryapi/getAllCategories', user)),
  //   //   map(response => {
  //   //     return response.json().Data;
  //   //   })
  //   // );

  //   // interval(10)
  //   //   .subscribe(() => {
  //   //     console.log('Hi ')
  //   //   });

  //   console.log('After');
  //   console.log(this.customersObservable);
  // }

  getdahsviewlist() {
    this.loading = true;

    let user: { id: string, cityarea_id: string } = {
      id: this.struserid, cityarea_id: this.cityarea_id
    };
    if (user.id === '') {
      user.id = '0';
    }


    this.showdashview = false;
    let seq = this.api.post('Dashviewapi/getdashviewforclientseller', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.dashviewlist = res.Data;
        this.bestseller = this.dashviewlist.filter(obj => obj.grouptype.toLowerCase() === 'best seller');
        this.dashstandalonggroup = this.dashviewlist.filter(obj => obj.grouptype.toLowerCase() === 'stand alone');
        this.newarrivals = this.dashviewlist.filter(obj => obj.grouptype.toLowerCase() === 'new arrivals');
        this.trending = this.dashviewlist.filter(obj => obj.grouptype.toLowerCase() === 'trending');


        setTimeout(() => {
          this.showdashview = true;
          this.loading = false;
          $('.best-seller-pro-active').owlCarousel({
            loop: false,
            nav: true,
            dots: false,
            smartSpeed: 1500,
            navText: ["<i class='lnr lnr-arrow-left'></i>", "<i class='lnr lnr-arrow-right'></i>"],
            margin: 10,
            responsive: {
              0: {
                items: 1,
                autoplay: true,
                smartSpeed: 500
              },
              450: {
                items: 2
              },
              768: {
                items: 3
              },
              992: {
                items: 4
              },
              1200: {
                items: 5
              }
            }
          })


          $('.like-pro-active').owlCarousel({
            loop: false,
            nav: false,
            dots: true,
            smartSpeed: 1500,
            navText: ["<i class='lnr lnr-arrow-left'></i>", "<i class='lnr lnr-arrow-right'></i>"],
            margin: 10,
            responsive: {
              0: {
                items: 1,
                autoplay: true,
                smartSpeed: 500
              },
              450: {
                items: 2
              },
              768: {
                items: 3
              },
              992: {
                items: 4
              },
              1200: {
                items: 5
              }
            }
          })



          $('.best-seller-pro-active').owlCarousel({
            loop: false,
            nav: true,
            dots: false,
            smartSpeed: 1500,
            navText: ["<i class='lnr lnr-arrow-left'></i>", "<i class='lnr lnr-arrow-right'></i>"],
            margin: 10,
            responsive: {
              0: {
                items: 1,
                autoplay: true,
                smartSpeed: 500
              },
              450: {
                items: 2
              },
              768: {
                items: 3
              },
              992: {
                items: 4
              },
              1200: {
                items: 5
              }
            }
          })


          $('.electronics-pro-active')
            .on('changed.owl.carousel initialized.owl.carousel', function (event) {
              $(event.target)
                .find('.owl-item').removeClass('last')
                .eq(event.item.index + event.page.size - 1).addClass('last');
            }).owlCarousel({
              loop: false,
              nav: true,
              dots: false,
              smartSpeed: 1000,
              navText: ["<i class='lnr lnr-arrow-left'></i>", "<i class='lnr lnr-arrow-right'></i>"],
              margin: 10,
              responsive: {
                0: {
                  items: 1,
                  autoplay: true,
                  smartSpeed: 500
                },
                768: {
                  items: 2
                },
                992: {
                  items: 3
                },
                1200: {
                  items: 4
                }
              }
            })

          //  $('#dashboardviews').show();
        }, 1500);




      }, err => {
        this.loading = false;
        this.showdashview = true;
        // $('#dashboardviews').show();
        alert('Error');
      });
  }


  bestsellerlikeproduct(product: any, no: number) {
    if (!this.userloggedin) {
      this._router.navigate(['/login']);
      return;
    }
    var statustosend = '';
    var index = this.bestseller[no].itemlist.indexOf(product);
    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };

    if (product.IsuserLike === '0') {
      statustosend = '1';
      product.like_count = Number(product.like_count) + 1;
      this.bestseller[no].itemlist[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.bestseller[no].itemlist[index].IsuserLike = '0';
          product.like_count = Number(product.like_count) - 1;
          alert('Error');
        });
    } else {
      statustosend = '0';
      product.like_count = Number(product.like_count) - 1;
      this.bestseller[no].itemlist[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemunlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.bestseller[no].itemlist[index].IsuserLike = '1';
          product.like_count = Number(product.like_count) + 1;
          alert('Error');
        });
    }
  }

  dashstandalonggrouplike(product: any, no: number) {
    if (!this.userloggedin) {
      this._router.navigate(['/login']);
      return;
    }
    var statustosend = '';
    var index = this.dashstandalonggroup[no].itemlist.indexOf(product);
    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };

    if (product.IsuserLike === '0') {
      statustosend = '1';
      product.like_count = Number(product.like_count) + 1;
      this.dashstandalonggroup[no].itemlist[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.dashstandalonggroup[no].itemlist[index].IsuserLike = '0';
          product.like_count = Number(product.like_count) - 1;
          alert('Error');
        });
    } else {
      statustosend = '0';
      product.like_count = Number(product.like_count) - 1;
      this.dashstandalonggroup[no].itemlist[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemunlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.dashstandalonggroup[no].itemlist[index].IsuserLike = '1';
          product.like_count = Number(product.like_count) + 1;
          alert('Error');
        });
    }
  }

  // newarrivalslike(product: any, no: number,no2: number) {
  //   if (!this.userloggedin) {
  
  //     return;
  //   }
  //   var statustosend = '';

  //   console.log(no);
  //   console.log(no2);
  //   console.log(this.newarrivals);
  //   console.log(this.newarrivals[no]);
  //   console.log(this.newarrivals[no].itemlist[no2]);

  //   var index = this.newarrivals[no].itemlist.indexOf(product);
  //   console.log(index);
  //   var index2 = this.newarrivals[no].itemlist[no2].indexOf(product);
  //   let user: { user_id_fk: string, items_id_fk: string } = {
  //     user_id_fk: this.struserid,
  //     items_id_fk: product.items_id
  //   };

  //   if (product.IsuserLike === '0') {
  //     statustosend = '1';
  //     product.like_count = Number(product.like_count) + 1;
  //     this.newarrivals[no].itemlist[no2][index].IsuserLike = statustosend;

  //     let seq = this.api.post('userdata/itemlike', user).share();
  //     seq
  //       .map(res => res.json())
  //       .subscribe(res => {
  //       }, err => {
  //         this.newarrivals[no].itemlist[no2][index].IsuserLike = '0';
  //         product.like_count = Number(product.like_count) - 1;
  //         alert('Error');
  //       });
  //   } else {
  //     statustosend = '0';
  //     product.like_count = Number(product.like_count) - 1;
  //     this.newarrivals[no].itemlist[no2][index].IsuserLike = statustosend;

  //     let seq = this.api.post('userdata/itemunlike', user).share();
  //     seq
  //       .map(res => res.json())
  //       .subscribe(res => {
  //       }, err => {
  //         this.newarrivals[no].itemlist[no2][index].IsuserLike = '1';
  //         product.like_count = Number(product.like_count) + 1;
  //         alert('Error');
  //       });
  //   }
  // }


  likeproduct(product: any) {
    if (!this.userloggedin) {
      this._router.navigate(['/login']);
      return;
    }
    var statustosend = '';
    var index = this.productlist.indexOf(product);

    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };

    if (product.IsuserLike === '0') {

      statustosend = '1';
      product.like_count = Number(product.like_count) + 1;
      this.productlist[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.productlist[index].IsuserLike = '0';
          product.like_count = Number(product.like_count) - 1;
          alert('Error');
        });
    } else {
      statustosend = '0';
      product.like_count = Number(product.like_count) - 1;
      this.productlist[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemunlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.productlist[index].IsuserLike = '1';
          product.like_count = Number(product.like_count) + 1;
          alert('Error');
        });

    }
  }

  addToCart(product) {
    // let cartdata = $.parseJSON(localStorage.getItem('cart'));
    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': product,
          'qty': 1,
          'amount': parseFloat(product.saleprice)
          , 'adminuser_id': Number(product.adminuser_id)
        });
      } else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          if (product.items_id === cartdata[i].product.items_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + 1;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }

        if (added === 0) {
          cartdata.push({
            'product': product,
            'qty': 1,
            'amount': parseFloat(product.saleprice)
            , 'adminuser_id': Number(product.adminuser_id)
          });
        }

      }

      this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }
    });
  }

  onPageChanged(page: any) {
    var startIndex = (page - 1) * this.pageSize;
    this.pagedPosts = _.take(_.rest(this.productlist, startIndex), this.pageSize);
  }


  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.text = `
		 $(document).ready( function() {
       
		});
		 `;
    this.elementRef.nativeElement.appendChild(s);
  }
}


  // addToCart(product) {

  //   // this.storage.get('cart').then((data) => {
  //  this.globalSettings.getValue('cart').then((data) => {
  //      if (data == null || data.length === 0) {
  //        data = [];
  //        data.push({
  //          'product': product,
  //          'qty': 1,
  //          'amount': parseFloat(product.item_price)
  //        });
  //      } else {

  //        let added = 0;

  //        for (let i = 0; i < data.length; i++) {
  //          if (product.items_id === data[i].product.items_id) {
  //            const qty = data[i].qty;
  //            data[i].qty = qty + 1;
  //            data[i].amount = parseFloat(data[i].amount) + parseFloat(data[i].product.item_price);
  //            added = 1;
  //          }

  //        }

  //        if (added === 0) {
  //          data.push({
  //            'product': product,
  //            'qty': 1,
  //            'amount': parseFloat(product.item_price)
  //          });
  //        }

  //      if (data == null || data.length === 0) {
  //         this.cartcount = 0;
  //      }    else
  //      {
  //         this.cartcount = data.length;
  //      }

  //      }

  //     this.globalSettings.setValue('cart', data).then( ()=> {
  //      // this.storage.set('cart', data).then(() => {
  //      });

  //    });

  //  }


// }
