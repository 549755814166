import { Component, OnInit, ElementRef } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { product } from './clsproduct';
import { Router, ActivatedRoute } from "@angular/router";
import { CustomValidators } from 'ng2-validation';
import { Api } from '../../service/providers';
import { Settings } from '../../service/providers';

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.css'],
	providers: [Api]
})
export class ProductComponent implements OnInit {

	frmsignup: FormGroup;
	formtitle: string = "Add Company";
	buttonCaption: string = "Add Company";
	isaddmode: boolean = true;
	isLoading = false;
	category = new product();
	customerlist: any[];
	categorylist: any[];
	public loggedinuserid: string = "";

	constructor(
		fb: FormBuilder, public api: Api, private _router: Router, private _routeParams: ActivatedRoute,public globalSettings: Settings
	) {
		this.frmsignup = fb.group({
			companyid: ['', Validators.compose([Validators.required])],
			categoryid: ['', Validators.compose([Validators.required])],
			name: ['', Validators.compose([Validators.required])],
			description: [''],
			warranty: [''],
			serialno: [''],
			modelno: [''],
			purchasedate: ['', Validators.compose([Validators.required])],
			expirydate: ['', Validators.compose([Validators.required])],
			reminderdatetime: ['']
		});
	}

	ngOnInit() {
		this.getCategorylist();
		this.getcompanylist();
		this.globalSettings._loggedinuserid.subscribe(value => this.loggedinuserid = value);
		var id = this._routeParams.params.subscribe(params => {
			var id = String(params["id"]);
			if (id == "new") {
				this.formtitle = "Add Product";
				this.buttonCaption = "Add Product"
				this.isaddmode = true;
			}
			else {
				this.formtitle = "Edit Product";
				this.buttonCaption = "Update Product"
				this.isaddmode = false;
			}

			if (id == "new") {
				return;
			}
			else {
				this.isLoading = true;
				let Company: { productid: string } = {
					productid: id
				};
				let seq = this.api.post('Productapi/getproductdetails', Company).share();
				seq
					.map(res => res.json())
					.subscribe(res => {
						this.category = res.Data;
					}, err => {
						alert("Error");
					});
				this.isLoading = false;
			}
		});
	}

	getCategorylist() {
		let seq = this.api.post('Categoryapi/getallcategory', '').share();
		seq
		  .map(res => res.json())
		  .subscribe(res => {
			this.categorylist = res.Data;
		  }, err => {
			alert("Error");
		  });
	  }

	addupdatecustomer() {
		if (this.category.productid) {
			//update family
			let seq = this.api.post('Productapi/updateproduct', this.category).share();
			seq
				.map(res => res.json())
				.subscribe(res => {
					this._router.navigate(['product']);
				}, err => {
					alert("Error");
				});
		}
		else {
			//add family
			this.category.user_id = this.loggedinuserid
			let seq = this.api.post('Productapi/addproduct', this.category).share();
			seq
				.map(res => res.json())
				.subscribe(res => {
					this._router.navigate(['product']);
				}, err => {
					alert("Error");
				});
		}
	}


	getcompanylist() {
		let seq = this.api.post('Companyapi/getallcompany', '').share();
		seq
			.map(res => res.json())
			.subscribe(res => {
				this.customerlist = res.Data;
			}, err => {
				alert("Error");
			});
	}

}
