import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { Settings } from '../service/providers';
import { Api } from '../service/providers';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public userloggedin: boolean = false;
  public username: string = "";
  public emailid: string = "";
  public IsSuperUser: boolean = false;
  public loggedinuserid: string = "";
  
  public catcount: number = 0;
  isLoading = false;
  categorylist: any[];
  constructor(public globalSettings: Settings, public _Router: Router,public api: Api ) { }
  
  ngOnInit() {
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._loggedinuseremail.subscribe(value => this.emailid = value);
    this.globalSettings._loggedinusername.subscribe(value => this.username = value);
    this.globalSettings._loggedinuserid.subscribe(value => this.loggedinuserid = value);
    this.getcategorylist();
  }

  
//   getcategorylist() {

//     this.isLoading = true;
//     this.authenticationService.getcategorylist().subscribe(data => {
//         this.categorylist = data.json().Data;
        
//         if (this.categorylist.length > 0) {
//             this.catcount = this.categorylist.length;
//         }
//         this.isLoading = false;
//     }
//         , error => {
//             alert("Error");
//         }
//     );
// }


getcategorylist() {
  let seq = this.api.post('categoryapi/getAllCategories', '').share();
  seq
    .map(res => res.json())
    .subscribe(res => {
      this.categorylist = res.Data;
       
      if (this.categorylist.length > 0) {
        this.catcount = this.categorylist.length;
    }
    this.isLoading = false;
      
    }, err => {
      alert("Error");
    });
}

  logout() {

    this.globalSettings.setLoginStatus(false);
    this.globalSettings.setusername("");
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    //localStorage.clear();
    sessionStorage.clear();
    this._Router.navigate(['login']);
  }

}


