import { CustomValidator2 } from './../../shared/customvalidator';
import { Component, OnInit, ElementRef,EventEmitter,Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Api } from '../../service/api/api';
import { Settings } from '../../service/providers';
import { Router, ActivatedRoute } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
declare var $: any;

@Component({
  selector: 'app-forgotpassemailverification',
  templateUrl: './forgotpassemailverification.component.html',
  styleUrls: ['./forgotpassemailverification.component.css']
})
export class ForgotpassemailverificationComponent implements OnInit {
  @Output() otpsentsuccessfully: EventEmitter<{mobileno: string}> = new EventEmitter<{mobileno: string}>() ;
  isLoading = false;
  result: any;
  isLoggedIn: boolean;
  public userloggedin: boolean = false;
  frmsignup: FormGroup;
  currentfilter: any;
  dataReturned: any;
  public modalstock: any;
  disableButton = false;

constructor(protected localStorage: LocalStorage, fb: FormBuilder, public api: Api, public settings: Settings, private _routeParams: ActivatedRoute,
    private _Router: Router, private toastr: ToastrService) {
      this.frmsignup = fb.group({
        mobileno: ['', Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          CustomValidator2.cannotContainspace
        ])],
      });
  }
  verifymobileno() {
    this.disableButton = true;
    try {
      let user: { email: string } = {
        email: this.frmsignup.controls.mobileno.value
      };
      this.isLoading = true;
      let seq = this.api.post_httpclient('userdata/forgotuserpwd', user);
      seq
        .subscribe(res => {
          console.log('res');
          console.log(res);
          if (res != undefined) {
            if (res['Data'] == "Vaild email id") {
              this.disableButton = false;
              this.toastr.success("OTP send on : +91 " + this.frmsignup.controls.mobileno.value);
             // this.otpsentsuccessfully.emit();
              this.otpsentsuccessfully.emit({ mobileno: this.frmsignup.controls.mobileno.value });
            }
            else if (res['Data'] == "Invalid email id") {
              this.toastr.warning("No User found");
            }
          }
        }, err => {
          this.toastr.error("Error in Sending SMS");
          this.disableButton = false;
          this.frmsignup.reset({
            mobileno: ''
          });
        });
    } catch (error) {
      this.disableButton = false;
    }
  }
  ngOnInit() {
  }

}
