import { Component, Output, OnInit, ElementRef, EventEmitter } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { clsUser } from './clsuser';
import { Router, ActivatedRoute } from "@angular/router";
import { Api } from '../../service/providers';
import * as CryptoJS from 'crypto-js';

import { ToastrService } from 'ngx-toastr';
import { Settings } from '../../service/providers';
import { CustomValidator2 } from '../../shared/customvalidator';
declare var $: any;

@Component({
  selector: 'signup',
  templateUrl: './useradd.component.html',
  styleUrls: ['./useradd.component.css'],
  providers: [Api]
})
export class UseraddComponent implements OnInit {

  isLoading = false;
  productlist: any;
  isLoggedIn: boolean;
  returnUrl: string;
  frmsignup: FormGroup;
  result: any;

  public userloggedin: boolean = false;
  @Output() change = new EventEmitter();

  constructor(fb: FormBuilder, public api: Api, public settings: Settings,
    private _Router: Router, private toastr: ToastrService) {

    this.frmsignup = fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        CustomValidator2.cannotContainspace,
        CustomValidator2.email
      ])],
      user_pass: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        CustomValidator2.cannotContainspace
      ])],
      reuser_pass: ['', Validators.required]
    }, { validator: CustomValidator2.passwordsShouldMatch });
  }

  ngOnInit() {
  }

  signupuser() {
    let user: { firstname: string, lastname: string, email: string, user_pass: string, IsAdmin: string } = {
      firstname: this.frmsignup.controls.firstname.value,
      lastname: this.frmsignup.controls.lastname.value,
      email: this.frmsignup.controls.email.value,
      user_pass: this.frmsignup.controls.user_pass.value,
      IsAdmin: "0",
    };

    this.isLoading = true;
    let seq = this.api.post('userdata/adminregister', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        if (res.status == "success" && res.Data == "User allready register") {
          this.toastr.warning('User allready register');
          this.frmsignup.controls.email.setErrors({
            alreadyregistered: true
          });
        }
        else if (res.status == "success" && res.Data == "Sucessfully Register") {
          alert('User Registered Successfully');
          $('#signup-modal').modal('hide');
          this._Router.navigate(['/']);
        }
        else {
          alert('Invalid Signup Case');
          this.frmsignup.reset({
            firstname: '',lastname: '', email: '', user_pass: '', reuser_pass: ''
          });
        }
      }, err => {
        alert("Error");
        this.frmsignup.reset({
          firstname: '',lastname: '', email: '', user_pass: '', reuser_pass: ''
        });
      });
  }
}
