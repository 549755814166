import { Component, OnInit } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-blogdetails',
  templateUrl: './blogdetails.component.html',
  styleUrls: ['./blogdetails.component.css']
})
export class BlogdetailsComponent implements OnInit {
  public struserid: string;
  public userloggedin: boolean = false;
  isLoading = false;
  imagebasepath: string = "";
  bloginfo: any;
  additionalinfo: string = "";
  bloglistinfo: string = "";
  bloglist: any[];
  blogid: any;
  public repoUrl = '';

  constructor(public api: Api, private _router: Router, public globalSettings: Settings, private _routeParams: ActivatedRoute) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    var id = this._routeParams.params.subscribe(params => {
      var id = params["id"];
      this.blogid = id;
      if (!id)
        return;
        
    let blog: { id: string } = { id: this.blogid };
    let seq = this.api.post('blogapi/getblogdetails', blog).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.bloginfo = res.Data;
          if (String(this.bloginfo.bloglong) != "") {
            this.additionalinfo = this.bloginfo.bloglong.replace(/<img/g, "<img class='img-responsive resizeblogimage'");
          }
        
      }, err => {
        alert("Error");
      });
    });

    let user: { id: string } = { id: this.struserid };
    let seq = this.api.post('blogapi/getallblog', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.bloglist = res.Data;

        if (typeof (this.bloglist) !== "undefined" && this.bloglist !== null) {
          for (let blg of this.bloglist) {
            this.bloglistinfo = "";
            blg["imagepath"] = "";
            if (String(blg.bloglong) != "") {
              this.bloglistinfo = blg.bloglong.replace(/<img/g, "<img class='img-responsive resizeblogimage' ");
              // var regEx = /< *img[^>]*src *= *["\']?([^"\']*)/i;
              var regEx = /<img [^>]*src="[^"]*"[^>]*>/gm;
              var valid = regEx.exec(this.bloglistinfo);
              if (valid != null) {
                var tmp = document.createElement('div');
                tmp.innerHTML = String(valid);
                var src = tmp.querySelector('img').getAttribute('src');
                blg["imagepath"] = src;
              }
            }
          }
        }
      }, err => {
        alert("Error");
      });
  }
  
}





