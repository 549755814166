import { Component, OnInit, ElementRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  public struserid: string = "0";
  public userloggedin: boolean = false;
  public loading = false;
  imagebasepath: string = "";
  cartItems: any[] = [];
  total = 0;
  userorderslist: any[];
  showEmptyCartMessage = false;

  constructor(protected localStorage: LocalStorage, private elementRef: ElementRef,
    public api: Api, private _router: Router, public globalSettings: Settings, private _Router: Router, private toastr: ToastrService,
    private _routeParams: ActivatedRoute) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;

    this.getallorderbyuser();

    this.localStorage.getItem('cart').subscribe((cartdata) => {
      this.cartItems = $.parseJSON(cartdata);
      if (this.cartItems.length > 0) {
        this.cartItems.forEach((item, index) => {
          this.total = this.total + (item.product.item_price * item.product.qty);
          this.total = Math.round(this.total);
        });
      } else {
        this.showEmptyCartMessage = true;
      }
    });
  }


  showhidebox(i:any)
  {
    //alert(i);
    $('#'+ i).toggle(300);
  }
  
  getallorderbyuser()
  {
    this.loading = true;
    let user: { user_id: string } = { user_id: this.struserid };
    let seq = this.api.post('Sellapi/getallorderbyuser', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.loading = false;
        this.userorderslist = res.Data;
      }, err => {
        this.loading = false;
        alert('Error');
      });
  }



}
