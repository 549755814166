import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Api } from '../service/providers';
declare var $: any;

@Component({
    selector: "carousel",
    template: `

<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner" role="listbox">
    
        <div *ngFor="let url of urls" class="carousel-item" [ngClass]="{active: isActive(url)}">
            <a href="{{url.bannerlink}}" target="_blank">
            <img src="{{imagebasepath}}{{url.bannerimage}}" alt="{{url.bannerimage}}" class="img-responsive">
            </a>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
    </a>
    </div>
</div>
    
<!-- Controls
<a class="left carousel-control" href="#carousel-generic" role="button" data-slide="prev">
    <span class="fa fa-chevron-left icon-prev" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
</a>
<a class="right carousel-control" href="#carousel-generic" role="button" data-slide="next">
    <span class="fa fa-chevron-right icon-next" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
</a> -->

<!--
div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner" role="listbox">
       <div class="carousel-item active">
          <img class="img-fluid w-100" src="https://loremflickr.com/g/1920/469/painting" alt="First slide" width="1920" height="833">
          <div class="carousel-caption d-none d-md-block">
             <h4>Tips</h4>
             <h5>Explore the best angular 4 theme with awesome sidebar </h5>
          </div>
          <div class="caption-overlay"></div>
       </div>
       <div class="carousel-item">
          <img class="img-fluid w-100" src="https://loremflickr.com/g/1920/469/red" alt="Second slide" width="1920" height="833">
          <div class="carousel-caption d-none d-md-block">
             <h4>Tips</h4>
             <h5>Check out our diffent layput style </h5>
          </div>
          <div class="caption-overlay"></div>
       </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
    </a>
 </div>-->
    `,
    styles: [`

    
.img-responsive {
    display: block;
    height:300px;
    max-width: 100%;
    width: 100%;
    border-radius:15px;
  }

  
.carousel-control.left
            {
                position: absolute;
                left: -60px;
            }

            .carousel-control.right
            {
                position: absolute;
                right: -58px;
            }
        @media only screen and (max-width: 768px)
            {
                .carousel-control.left
                {
                    position: absolute;
                    left: -15px;
                }

                .carousel-control.right
                {
                    position: absolute;
                    right: -15px;
                }


            }

             div.carousel
            {
                width: 100%; /* for example */
                margin: auto;
            }

            .carousel-inner > .item > img,
            .carousel-inner > .item > a > img
            {
                /*width: 100%;*/ /* use this, or not */
                margin: auto;
            }

    `]
})

export class CarouselComponent {
    private start = true;
    imagebasepath: string = "";
    // urls = [];
    @Input() urls = [];
    constructor(private globalSettings: Api) {
        this.imagebasepath = this.globalSettings._imagebasepath;
        this.startCarousel;
    }

    startCarousel(urls: string[]) {
        this.urls = urls;
        $('#carouselExampleControls').carousel({
            interval: 1000 //changes the speed
        })
    }

    isActive(url: string) {
        return url === this.urls[0];
    }
}