import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrls: ['./newlogin.component.css']
})
export class NewloginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
