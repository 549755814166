import { Component, OnInit } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterOutlet, RouterLink } from '@angular/router';

@Component({
  selector: 'app-cashback',
  templateUrl: './cashback.component.html',
  styleUrls: ['./cashback.component.css']
})
export class CashbackComponent implements OnInit {

  usercashback: any;
  imagebasepath: string = '';
  public loading = false;
  public struserid: string = "0";
  public userloggedin = false;

  constructor( public api: Api, private _router: Router,public globalSettings: Settings) { 
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    this.getusercashback();
  }

  
  getusercashback() {
    this.loading = true;
    let user: { user_id: string } = { user_id: this.struserid };
    let seq = this.api.post('cashbacktran/getcashbacktransuserwise', user);
    seq
      .map(res => res.json())
      .subscribe(res => {
        console.log('res getcashbacktransuserwise');
        console.log(res);
        this.loading = false;
        this.usercashback = res.Data;
        console.log(this.usercashback );
      }, err => {
        this.loading = false;
        alert(err);
      });
  }

}
