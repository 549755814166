export class clsProduct {
    items_id: string = "";
    item_name: string = "";
    item_price: string = "";
    item_desc: string = "";
    // item_status: string = "";
    cat_id: string = "";
    thumbnail: string = "";
    big_image: string = "";
    medium_image: string = "";
    // item_stock: string = "";
    itemlink: string = "";
   productimg: File = null;
   ImageURL: string = "";
   Item_Schedule:Date = new Date();
   item_dtl_desc: string = "";
   IsuserLike:string = "";
   advimage:string="";
   advlink:string="";
   like_count:string="";
   sellername:string="";
    cat_name: string = "";
    caturllink: string = "";
    sub_name: string = "";
    suburllink: string = "";
    adminuser_id: string = "";
    saleprice: string = "";
    discountpercentage: string = "";
    item_tax: string = "";
    stock : string = "";
    mrp : string = "";
    //Sample Data
    //  "cat_id": "55",
    // "item_id": "2",
    // "item_name": "Tissot",
    // "item_price": "45000",
    // "item_desc": "t",
    // "item_status": "0",
    // "thumbnail": "images/uploads/Tissot_2_small..jpg",
    // "big_image": "images/uploads/Tissot_2..jpg",
    // "medium_image": "images/uploads/Tissot_2_med..jpg",
    // "item_stock": "3"
}