import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
declare var $: any;
import { Api } from '../../service/providers';
import { Settings } from '../../service/providers';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  total = 0;
  cartItems: any[] = [];
  imagebasepath = '';
  public userloggedin: boolean = false;
  showEmptyCartMessage = false;
  public struserid = '0';
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };

  cartItemsserver: any;
  isadd: Boolean = false;

  @ViewChild('logincontent') logincontentref: TemplateRef<any>;
  @ViewChild('signupcontent') signupcontentref: TemplateRef<any>;
  @ViewChild('otpverificationcontent') otpverificationcontentref: TemplateRef<any>;
  @ViewChild('newsignupcontent') newsignupcontentref: TemplateRef<any>;

  constructor(protected localStorage: LocalStorage, public api: Api, public globalSettings: Settings,
    private _Router: Router, private modalService: NgbModal
  ) {
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    if (this.userloggedin) {
      let cartuserdata: { user_id: string } = { user_id: this.struserid };
      const seq1 = this.api.post_httpclient('cartdataapi/getcartdatadetails', cartuserdata);
      seq1
        .subscribe(res1 => {
          if (res1['Data'] != null) {
            this.cartItemsserver = $.parseJSON(res1['Data'].cart);
            console.log('this.cartItemsserver');
            console.log(this.cartItemsserver);
            this.cartItems = $.parseJSON(res1['Data'].cart);

            this.localStorage.setItem('cart', JSON.stringify(this.cartItemsserver)).subscribe(() => {
            });

            if (this.cartItemsserver != undefined && this.cartItemsserver.length > 0) {
              this.globalSettings.setcount(this.cartItemsserver.length);

              if (this.cartItemsserver.length > 0) {
                this.cartItemsserver.forEach((item, index) => {
                  this.total = this.total + (item.product.saleprice * item.qty);
                });
              }
              else {
                this.showEmptyCartMessage = true;
              }

            }
            else {
              this.globalSettings.setcount(0);
            }
          }
          else {
            this.globalSettings.setcount(0);
          }
        }, err => {
          alert(err);
        });
    }
  }

  proceedtocheckout() {
    if (!this.userloggedin) {
      //this._Router.navigate(['/login']);
      this.openloginmodal();
      return;
    }
    else {
      if (this.cartItems.length > 0) {
        this._Router.navigate(['/checkout']);
      }
      else {
        alert('No Products in shopping Cart');
      }
    }
  }


  openloginmodal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.logincontentref, { windowClass: "ngcustommodal" });

  }

  onsignupclick($event) {
    this.modalService.dismissAll();
    this.modalService.open(this.signupcontentref, { windowClass: "ngcustommodal" });
  }

  strReceivedmobile = "0";
  onotpsentsuccessfully($event) {
    this.strReceivedmobile = $event.mobileno;
    this.modalService.dismissAll();
    console.log($event.mobileno);
    const modalRef = this.modalService.open(this.otpverificationcontentref, { windowClass: "ngcustommodal" });
    modalRef.componentInstance.strmobileno = $event.mobileno;
  }

  onloginsuccess($event) {
    this.modalService.dismissAll();
  }

  strReceivedmobileafterotpverification = "0";
  onotpverificationsuccessfull($event) {
    this.strReceivedmobileafterotpverification = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.newsignupcontentref, { windowClass: "ngcustommodal" });
    modalRef.componentInstance.strsignupmobileno = $event.mobileno;
  }

  onsignupsuccessfull($event) {
    console.log($event.mobileno);
    this.modalService.dismissAll();
  }

  removeFromCart(item, i) {

    const price = item.product.saleprice;
    const qty = item.qty;

    this.cartItems.splice(i, 1);
    this.localStorage.setItem('cart', JSON.stringify(this.cartItems)).subscribe(() => {
      this.total = this.total - (price * qty);
      //this.total = Math.round(this.total);

      this.globalSettings.setcount(this.cartItems.length);

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(this.cartItems);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }
    });
    if (this.cartItems.length === 0) {
      this.showEmptyCartMessage = true;
    }
  }

  onChange(item) {
  }

  changeQty(item, i, change) {

    let price = 0;
    let qty = 0;

    price = parseFloat(item.product.saleprice);
    qty = item.qty;

    if (change < 0 && item.qty === 1) {
      return;
    }

    qty = qty + change;
    item.qty = qty;
    item.amount = qty * price;

    this.total = 0;

    this.cartItems[i] = item;
    this.cartItems.forEach((item, index) => {
      this.total = this.total + (item.product.saleprice * item.qty);
      //this.total = Math.round(this.total);
    });
    this.localStorage.setItem('cart', JSON.stringify(this.cartItems)).subscribe(() => {
      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(this.cartItems);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }
    });
  }

}
















// ngOnInit() {
//   if (this.userloggedin) {
//     let cartuserdata: { user_id: string } = { user_id: this.struserid };
//     const seq1 = this.api.post_httpclient('cartdataapi/getcartdatadetails', cartuserdata);
//     seq1
//       .subscribe(res1 => {
//         if (res1['Data'] != null) {
//           this.cartItemsserver = $.parseJSON(res1['Data'].cart);
//           this.localStorage.getItem('cart').subscribe((cartdata) => {
//             cartdata = $.parseJSON(cartdata);

//             if (cartdata === null || cartdata.length === 0) {
//               if (this.cartItemsserver.length > 0) {
//                 this.localStorage.setItem('cart', JSON.stringify(this.cartItemsserver)).subscribe(() => {
//                 });
//                 this.localStorage.getItem('cart').subscribe((cartdata) => {
//                   this.cartItems = $.parseJSON(cartdata);
//                   if (typeof (this.cartItems) !== "undefined" && this.cartItems !== null) {
//                     if (this.cartItems.length > 0) {
//                       this.cartItems.forEach((item, index) => {
//                         this.total = this.total + (item.product.saleprice * item.qty);
//                       });
//                     } else {
//                       this.showEmptyCartMessage = true;
//                     }
//                   }
//                 });
//               }
//               this.globalSettings.setcount(this.cartItemsserver.length);
//             }
//             else {
//               if (this.cartItemsserver.length > 0) {
//                 for (let i = 0; i < cartdata.length; i++) {
//                   this.isadd = false;
//                   for (let z = 0; z < this.cartItemsserver.length; z++) {
//                     if (this.cartItemsserver[z].product.items_id === cartdata[i].product.items_id) {
//                       this.isadd = true;
//                     }
//                   }
//                   if (this.isadd === false) {
//                     this.cartItemsserver.push({
//                       'product': cartdata[i].product,
//                       'qty': cartdata[i].qty,
//                       'amount': parseFloat(cartdata[i].product.item_price)
//                     });
//                   }
//                 }
//                 this.globalSettings.setcount(this.cartItemsserver.length);
//                 this.localStorage.setItem('cart', JSON.stringify(this.cartItemsserver)).subscribe(() => {
//                 });


//                 this.localStorage.getItem('cart').subscribe((cartdata) => {
//                   this.cartItems = $.parseJSON(cartdata);
//                   if (typeof (this.cartItems) !== "undefined" && this.cartItems !== null) {
//                     if (this.cartItems.length > 0) {
//                       this.cartItems.forEach((item, index) => {
//                         this.total = this.total + (item.product.saleprice * item.qty);
//                       });
//                     } else {
//                       this.showEmptyCartMessage = true;
//                     }
//                   }
//                 });
//               }
//               if (this.cartItemsserver != undefined && this.cartItemsserver.length > 0) {
//                 this.globalSettings.setcount(this.cartItemsserver.length);
//               }
//               else {
//                 this.globalSettings.setcount(0);
//               }
//             }
//           });
//         }
//         else
//         {
//           this.globalSettings.setcount(0);
//         }
//       }, err => {
//         alert(err);
//       });
//   }
//   else {
//     this.localStorage.getItem('cart').subscribe((cartdata) => {
//       this.cartItems = $.parseJSON(cartdata);
//       if (typeof (this.cartItems) !== "undefined" && this.cartItems !== null) {
//         if (this.cartItems.length > 0) {
//           this.cartItems.forEach((item, index) => {
//             this.total = this.total + (item.product.saleprice * item.qty);
//           });
//         } else {
//           this.showEmptyCartMessage = true;
//         }
//       }

//       if (this.cartItemsserver != undefined && this.cartItemsserver.length > 0) {
//         this.globalSettings.setcount(this.cartItemsserver.length);
//       }
//       else {
//         this.globalSettings.setcount(0);
//       }

//     });
//   }
// }