import {
	Component,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { OnChanges } from '@angular/core';
declare var $: any;

@Component({
	selector: 'pagination',
	template: `
	<nav *ngIf="items?.length > pageSize" class="pro-pagination">
        <ul class="blog-pagination">
            <li [class.disabled]="currentPage == 1"  style="cursor:pointer;">
                <a (click)="previous()" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li [class.active]="currentPage == page" *ngFor="let page of getPages()" (click)="changePage(page)" style="cursor:pointer;">
                <a>{{ page }}</a>
            </li>
            <li [class.disabled]="currentPage == pages?.length"  style="cursor:pointer;">
                <a (click)="next()" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
			
		
        </ul>
		 <ul class="pagination">
		  <li class="disabled" *ngIf="8 <  totalPages()  " >               
                <span aria-hidden="true">Page {{currentPage}} of {{ totalPages() }} </span>
             
            </li>
		 </ul>
    </nav> 
	
`
})
export class PaginationComponent implements OnChanges {
	@Input() items = [];
	@Input('page-size') pageSize = 10;
    @Input() pagesToShow: 4;
	@Output('page-changed') pageChanged = new EventEmitter();
	pages: any[];
	currentPage;

	ngOnChanges() {
		this.currentPage = 1;
		if (this.items != null) {
			var pagesCount = this.items.length / this.pageSize;

			// alert(Math.ceil(Number(pagesCount)));
			// if (Number.isInteger(pagesCount) === false) {
			// 	pagesCount = pagesCount + 1;
			// }
			pagesCount = Math.ceil(Number(pagesCount));
			this.pages = [];
			for (var i = 1; i <= pagesCount; i++)
				this.pages.push(i);
		}
	}

	changePage(page) {
		this.currentPage = page;
		this.pageChanged.emit(page);
		window.scrollTo(0, 0);
		//      var scrollToTop = window.setInterval(function () {
        //     var pos = window.pageYOffset;
        //     if (pos > 0) {
        //         window.scrollTo(0, pos - 20); // how far to scroll on each step
        //     } else {
        //         window.clearInterval(scrollToTop);
        //     }
        // }, 0);
	}

	previous() {
		if (this.currentPage == 1)
			return;

		this.currentPage--;
		this.pageChanged.emit(this.currentPage);
			window.scrollTo(0, 0);
	}

	next() {
		if (this.currentPage == this.pages.length)
			return;

		this.currentPage++;
		this.pageChanged.emit(this.currentPage);
			window.scrollTo(0, 0);
	}

	getPages(): number[] {
    const c = Math.ceil(this.items.length / this.pageSize);
    const p = this.currentPage || 1;
    const pagesToShow = this.pagesToShow || 8;
    const pages: number[] = [];
    pages.push(p);
    const times = pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < pagesToShow) {
        if (Math.max.apply(null, pages) < c) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }


  totalPages(): number {
    return Math.ceil(this.items.length /  this.pageSize) || 0;
  }
}