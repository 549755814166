import { Component, OnInit } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  public struserid: string;
  public userloggedin: boolean = false;
  isLoading = false;
  bloglist: any[];
  existingimages: any[];
  categorylist: any[];
  categoryproducts: any[];
  imagebasepath: string = "";
  additionalinfo: string = "";
  additionalinfo2: string = "";


  constructor(public api: Api, private _router: Router, public globalSettings: Settings) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    this.getbloglist();
  }


  getbloglist() {
    let user: { id: string } = { id: this.struserid };
    let seq = this.api.post('blogapi/getallblog', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.bloglist = res.Data;

        if (typeof (this.bloglist) !== "undefined" && this.bloglist !== null) {
          for (let blg of this.bloglist) {
            this.additionalinfo = "";
            blg["imagepath"] = "";
            if (String(blg.bloglong) != "") {
              this.additionalinfo = blg.bloglong.replace(/<img/g, "<img style='width:100%;height:533px;' class='img-responsive' ");
              // var regEx = /< *img[^>]*src *= *["\']?([^"\']*)/i;
              var regEx = /<img [^>]*src="[^"]*"[^>]*>/gm;
              var valid = regEx.exec(this.additionalinfo);
              if (valid != null) {
                var tmp = document.createElement('div');
                tmp.innerHTML = String(valid);
                var src = tmp.querySelector('img').getAttribute('src');
                blg["imagepath"] = src;
              }
            }
          }
        }
      }, err => {
        alert("Error");
      });
  }


}
