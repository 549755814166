import { Component, OnInit, ElementRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
declare var $: any;
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sellerfound',
  templateUrl: './sellerfound.component.html',
  styleUrls: ['./sellerfound.component.css']
})
export class SellerfoundComponent implements OnInit {
  public struserid: string = "0";
  public userloggedin: boolean = false;
  imagebasepath: string = "";
  areastoreinfo: any;
  loading = false;

  constructor(private elementRef: ElementRef, public api: Api, private _router: Router, public globalSettings: Settings, private _Router: Router,
    protected localStorage: LocalStorage,
    private _routeParams: ActivatedRoute
    , private toastr: ToastrService
  ) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    var id = this._routeParams.params.subscribe(params => {
      var pincode = params["pincode"];
      var areadid = params["areaid"];

      if (!pincode)
        return;

      this.loading = true;
      let getdata: { pincode: string, cityarea_id: string } = {
        pincode: pincode,
        cityarea_id: areadid == '' ? '0' : areadid
      };

      let seq = this.api.post('Dashviewapi/sellerlistareawise', getdata).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.areastoreinfo = res.Data;
          this.loading = false;
          if (this.areastoreinfo != undefined) {
            if (this.areastoreinfo.length > 0) {
              this.toastr.success(this.areastoreinfo.length + " Store found Near your area!!");
            }
            else {
              this.toastr.warning("Sorry, No Store available Near your area");
            }
          }
          else {
            this.toastr.error("No Store Info Available");
          }
        }, err => {
          this.loading = false;
          this.toastr.error(err);
        });
    });
  }

}
