import { Component, OnInit } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
declare var $: any;


@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  public struserid: string;
  public userloggedin: boolean = false;
  imagebasepath: string = "";
  categoryproducts: any[];
  cartcount = 0;
  catcount = 0;
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };

  constructor(public api: Api, private _router: Router, public globalSettings: Settings,protected localStorage: LocalStorage ) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {
    this.getAllProductWishlist();
  }


  getAllProductWishlist() {
    let user: { id: string } = { id: this.struserid };
    //let seq = this.api.post('productapi/getAllProductWishlist', user).share();
    let seq = this.api.post('productapi/getAllProductWishlistseller', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.categoryproducts = res.Data;
      }, err => {
        alert("Error");
      });
  }


  likeproduct(product: any) {
    var statustosend = "";
    var index = this.categoryproducts.indexOf(product);
    this.categoryproducts.splice(index, 1);
    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };
    statustosend = "0";
    
    let seq = this.api.post('userdata/itemunlike', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
      }, err => {
        this.categoryproducts.splice(index, 0, product);
        alert("Error");
      });
  }


  
  addToCart(product) {
    // let cartdata = $.parseJSON(localStorage.getItem('cart'));
    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': product,
          'qty': 1,
          'amount': parseFloat(product.saleprice)
          ,'adminuser_id': Number(product.adminuser_id)
        });
      } else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          if (product.items_id === cartdata[i].product.items_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + 1;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }

        if (added === 0) {
          cartdata.push({
            'product': product,
            'qty': 1,
            'amount': parseFloat(product.saleprice)
            ,'adminuser_id': Number(product.adminuser_id)
          });
        }

      }

      this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }
    });
  }


}




