import { Component, OnInit, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Api } from '../../service/api/api';
import { Settings } from '../../service/providers';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-forgotpassotpverification',
  templateUrl: './forgotpassotpverification.component.html',
  styleUrls: ['./forgotpassotpverification.component.css']
})
export class ForgotpassotpverificationComponent implements OnInit {


  @Input() strmobileno = '';
  @Output() otpverificationsuccessfull: EventEmitter<{ mobileno: string, user_id: string, email: string }> = new EventEmitter<{ mobileno: string, user_id: string, email: string }>();

  objstock: any;
  frmsignup: FormGroup;
  mobileno = "0";

  constructor(fb: FormBuilder, public api: Api, public settings: Settings, private _routeParams: ActivatedRoute,
    private _Router: Router, private toastr: ToastrService) {
    this.frmsignup = fb.group({
      otp: ['', Validators.compose([
        Validators.required,
        Validators.minLength(4)
      ])],
    });
  }

  ngOnInit() {
    console.log('this.strmobileno');
    console.log(this.strmobileno);
    this.mobileno = this.strmobileno;
    this.doTimer();

  }

  verifyotp() {
    let user: { mobile: string, otp: string } = {
      mobile: this.mobileno, otp: this.frmsignup.controls.otp.value
    };
    let seq = this.api.post_httpclient('userdata/verificationotpforreset', user);
    seq
      .subscribe(res => {
        console.log('res');
        console.log(res);
        console.log(res['Data']);
        if (res != undefined) {
          if (res['Data'] == "This OTP is invalid Or expired!!!") {
            this.toastr.warning("OTP is invalid Or expired!!!")
          }
          else {
            this.toastr.success("OTP verification Successfull")
            this.otpverificationsuccessfull.emit({ mobileno: this.mobileno, user_id: res['Data'].user_id, email: res['Data'].email });
          }
        }
      }, err => {
        alert(err);
      });
  }

  resendotp() {
    if (this.mobileno == "0") {
      this.toastr.warning('Mobile number not received');
    }
    else {
      try {
        let user: { mobile: string } = {
          mobile: this.mobileno
        };
        let seq = this.api.post_httpclient('userdata/sendotpsms', user);
        seq
          .subscribe(res => {
            console.log('res');
            console.log(res);
            if (res != undefined) {
              if (res['Data'] == "Sucessfully OTP send") {
                this.toastr.success("OTP re-send Sucessfully ");

                this.counter = 30;
                this.doTimer();
              }
            }
          }, err => {
            alert("Error in Sending SMS, check console!!");
            console.log(err);
          });
      } catch (error) {
      }
    }

  }

  counter = 30;
  delay(delay: number) {
    return new Promise(r => {
      setTimeout(r, delay);
    })
  }

  async doTimer() {
    for (let i = 0; i < 30; i++) {
      await this.delay(1000);
      this.counter = this.counter - 1;
      //console.log(this.counter + " " + i);
    }
  }
}
