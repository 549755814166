import { Component, OnInit, ElementRef,ViewChild , EventEmitter,Output} from '@angular/core';
// import { AppGlobals } from '../../AppGlobals'
import { Api } from '../../service/providers';
import { NgModule } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CustomValidator2 } from '../../shared/customvalidator';
import { clsseller } from './clsseller';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sellersignup',
  templateUrl: './sellersignup.component.html',
  styleUrls: ['./sellersignup.component.css']
})
export class SellersignupComponent implements OnInit {
  
  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  @ViewChild("fileDropRef2") fileDropEl2: ElementRef;

  @Output() sellersignupsucess = new EventEmitter<void>();
 
  files: any[] = [];
  offerfiles: any[] = [];

  isaddmode: boolean = true;
  from: '';
  isLoading = false;
  productlist: any;
  isLoggedIn: boolean;
  returnUrl: string;
  frmsignup: FormGroup;
  result: any;
  formtitle: string = "Add New Seller";
  buttonCaption: string = "Add New Seller";
  category = new clsseller();
  imagebasepath: string = "";
  shop: any;
    shopimgfiles: any[] = [];

    identity: any;
    identityimgfiles: any[] = [];

  public userloggedin: boolean = false;

  constructor(public service: Api,private elementRef: ElementRef,
    fb: FormBuilder,
    private _Router: Router,
    private _routeParams: ActivatedRoute, private toastr: ToastrService) {
    this.frmsignup = fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      contactno1: ['', Validators.required],

      // contactno1: ['', Validators.compose([
      //   Validators.required, CustomValidator2.isNumberCheck,])],

      // IsAdmin: ['', Validators.required],
      gender: ['', Validators.required],
      address: [''],
      company_name: [''],
      website: [''],
      contactno2: [''],
      email: ['', Validators.compose([
        Validators.required,
        CustomValidator2.cannotContainspace,
        CustomValidator2.email
      ])],
      user_pass: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        CustomValidator2.cannotContainspace
      ])],
      reuser_pass: ['', Validators.required]
    }, { validator: CustomValidator2.passwordsShouldMatch });
  }

  ngOnInit() {
    this.imagebasepath = this.service._imagebasepath;
    var id = this._routeParams.params.subscribe(params => {
      var id = "new";
        this.formtitle = "Add New Seller";
        this.buttonCaption = "Add New Seller";
        this.isaddmode = true;
        console.log('this.category');
        console.log(this.category);
        return;
      
    });
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    // if (this.files[index].progress < 100) {
    //   console.log("Upload in progress.");
    //   return;
    // }
    this.files.splice(index, 1);
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    console.log(files);
    if (this.files != undefined && this.files.length > 0) {
      this.files = [];
    }

    for (const item of files) {
      console.log(item);
      if (item.type.toLowerCase().includes('pdf') || item.type.toLowerCase().includes('jpg') || item.type.toLowerCase().includes('jpeg') || item.type.toLowerCase().includes('png') || item.type.toLowerCase().includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        console.log('item.size');
        console.log(item.size);
        var fileinMB = (item.size / (1024*1024)).toFixed(2);
        if(Number(fileinMB) > 2)
        {
          alert("File Size Should be less than 2MB");
        }
        else
        {
          this.files.push(item);
        }
      } else {
        alert("Allowed extension: pdf, jpeg, png, docx")
      }
      //item.progress = 0;      
    }
    this.fileDropEl.nativeElement.value = "";
    
  }

  


  onFileDropped2($event) {
    this.prepareFilesList2($event);
  }

  prepareFilesList2(files: Array<any>) {
    if (this.offerfiles != undefined && this.offerfiles.length > 0) {
      this.offerfiles = [];
    }
    for (const item of files) {
      console.log(item);
      if (item.type.toLowerCase().includes('pdf') || item.type.toLowerCase().includes('jpg') || item.type.toLowerCase().includes('jpeg') || item.type.toLowerCase().includes('png') || item.type.toLowerCase().includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        var fileinMB = (item.size / (1024*1024)).toFixed(2);
        if(Number(fileinMB) > 2)
        {
          alert("File Size Should be less than 2MB");
        }
        else
        {
          this.offerfiles.push(item);
        }
      } else {
        alert("Allowed extension: pdf, jpeg, png, docx")
      }
    }
    this.fileDropEl2.nativeElement.value = "";
  }
  
  fileBrowseHandler2(files) {
    this.prepareFilesList2(files);
  }

  deleteFile2(index: number) {
    this.offerfiles.splice(index, 1);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  addupdate() {
      //let files: FileList = (<HTMLInputElement>document.getElementById('shop')).files;
      for (let i = 0; i < this.files.length; i++) {
          this.shop = this.files[i];
          this.shopimgfiles.push(this.shop);
      }
  
      //let files2: FileList = (<HTMLInputElement>document.getElementById('identity')).files;
      for (let i = 0; i < this.offerfiles.length; i++) {
          this.identity = this.offerfiles[i];
          this.identityimgfiles.push(this.identity);
      }
    
      console.log('this.category') ;
      console.log(this.category);
      let fd = new FormData();
      //fd.append('adminuser_id', this.category.adminuser_id);
      fd.append('email', this.category.email);
      fd.append('firstname', this.category.firstname);
      fd.append('lastname', this.category.lastname);
      fd.append('user_pass', this.category.user_pass);
      fd.append('address', this.category.address);
      fd.append('gender', this.category.gender);
      fd.append('company_name', this.category.company_name);
      fd.append('contactno1', this.category.contactno1);
      fd.append('contactno2', this.category.contactno2);
      fd.append('website', this.category.website);
      fd.append('account_type', this.category.account_type);
      fd.append('usertype', this.category.usertype);
      fd.append('IsActivate', String(this.category.IsActivate));
      fd.append('Issuspended', String(this.category.Issuspended));
      // fd.append('main_image', this.category.main_image);
      // fd.append('thumbnail', this.category.thumbnail);
  
      for (let i = 0; i < this.shopimgfiles.length; i++) {
        console.log('shopeact file');
        console.log(this.shopimgfiles[i]);
        let strfiletype = "shopeact." + this.shopimgfiles[i].name.slice((Math.max(0, this.shopimgfiles[i].name.lastIndexOf(".")) || Infinity) + 1);
        fd.append("shopeact", this.shopimgfiles[i],strfiletype);
      }

      for (let i = 0; i < this.identityimgfiles.length; i++) {
        let strfiletype = "adressproof." + this.identityimgfiles[i].name.slice((Math.max(0, this.identityimgfiles[i].name.lastIndexOf(".")) || Infinity) + 1);
        fd.append("adressproof", this.identityimgfiles[i], strfiletype);
      }
      
      let seq = this.service.post_httpfileupload('userdata/adminsellerregister', fd).share();
      //let seq = this.service.post('userdata/adminsellerregister', this.category).share();
      seq
        .subscribe(res => {
         console.log(res);
         if (res != undefined) {
          if (res['Data'] == "Sucessfully Register") {
            this.toastr.success('Seller Registration Successfull');
            this.sellersignupsucess.emit();
          }
        }
        }, err => {
          alert(err);
        });
    }

  close()
  {
    // if (this.from == '' || this.from == undefined) {
    //   this._Router.navigate(['sellerlist']);
    // }
    // else {
    //   this._Router.navigate(['dashboard']);
    // }

  }

 

}
