import { Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { CustomValidator2 } from '../../shared/customvalidator';
declare var $: any;

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  frmsignup: FormGroup;
  result: any;

  constructor(fb: FormBuilder, public api: Api, public settings: Settings,
    private _Router: Router, ) {

    this.frmsignup = fb.group({
      subject: ['', Validators.required],
      full_name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        CustomValidator2.cannotContainspace,
        CustomValidator2.email
      ])],
      message: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  sendmessage() {
    let info: { subject: string, fulcontactname: string, emailid: string, msg: string } = {
      subject: this.frmsignup.controls.subject.value,
      fulcontactname: this.frmsignup.controls.full_name.value,
      emailid: this.frmsignup.controls.email.value,
      msg: this.frmsignup.controls.message.value
    };
    let seq = this.api.post('userdata/SendEnquiry', info).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        alert('Enquiry Submitted successfully.');
      }, err => {
        alert("Error");
      });
  }


}
