export class clsorderdetails{
    cart_id= "";
	// cart_session= "";
	// cart_status= "";
	item_name= "";
	item_qty= 0;
	item_sale_price= "";
	item_tax= "";
	item_taxamount =   "";
	item_total_price= "";
	items_id= "";
	order_id= "";
	user_id = "";
	adminuser_id = "";

}

// export class clsorderdetails{
//     cart_id= "";
// 	// cart_session= "";
// 	// cart_status= "";
// 	item_name= "";
// 	item_qty: string= "0";
// 	item_sale_price= "";
// 	item_tax: string=  "";
// 	item_taxamount: string  =   "";
// 	item_total_price: string=  "";
// 	items_id= "";
// 	order_id= "";
// 	user_id = "";
// }