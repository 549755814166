import { Component, OnInit, ElementRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { Settings } from '../../service/providers';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Api } from '../../service/providers';
//import { } from 'googlemaps';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';

// interface marker {
// 	lat: number;
// 	lng: number;
// 	label?: string;
//   draggable: boolean;
//   content?:string;
//   isShown:boolean;
//   icon:string;
// }

interface marker {
  lat: number;
  lng: number;
}


declare var $: any;

@Component({
  selector: 'app-chooselocation',
  templateUrl: './chooselocation.component.html',
  styleUrls: ['./chooselocation.component.css']
})
export class ChooselocationComponent implements OnInit {

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  radius = 500;
  radiusLat = 0;
  radiusLong = 0;
 
  markers: marker[] = []


  @Output() loctioninfomation: EventEmitter<{pincode: string, areaid: string}> = new EventEmitter<{pincode: string , areaid: string}>();
  @ViewChild('map') mapElement: any;
  //map: google.maps.Map;

  isLoading = false;
  public userloggedin = false;
  public strusername = '';
  public struserid = '';
  public struseremail = '';
  cityarealist: any[];
  frmpincode: FormGroup;
  enteredpincode = "";
  enteredareaid = "";
  geolocationselected = false;
  enterlocationmanuly = true;

  constructor(private elementRef: ElementRef, public api: Api,
    public globalSettings: Settings, public _Router: Router, fb: FormBuilder, private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader
    ) {
    this.frmpincode = fb.group({
      ctlpincode: [''],
      ctlareaid: ['']
    })
  }

  ngOnInit() {

    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    // });


    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._loggedinusername.subscribe(value => this.strusername = value);
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._loggedinuseremail.subscribe(value => this.struseremail = value);
    this.getcityarealist();
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.radiusLat = this.latitude;
        this.radiusLong = this.longitude;
        this.zoom = 15;
        // for(let i=1;i<50;i++){
        //   this.markers.push(
        //     {
        //       lat: this.latitude+Math.random(),
        //       lng: this.longitude+Math.random(),
        //       label: `${i}`,
        //       draggable: false,
        //       content: `Content no ${i}`,
        //       isShown:false,
        //       icon:'./assets/marker-red.png'
        //     }
        //   );
        // }

        this.markers.push(
          {
            lat: this.latitude,
            lng: this.longitude,
          })

      });
    }
  }

  
  arealist: any;
  checkpincode() {
    if (this.enterlocationmanuly) {
      var found = this.cityarealist.filter(obj => obj.pincode === this.enteredpincode);
      //alert(found.length);
      let getdata: { pincode: string } = {
        pincode: this.enteredpincode
      };
      let seq = this.api.post('Cityareaapi/getcityareapincode', getdata).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.arealist = res.Data;
          if(this.arealist != undefined && this.arealist.length == 0)
          {
            console.log("No area found for pincode: " + this.enteredpincode);
          }
        }, err => {
          alert(err);
        });
    }
  }

  onChangeSub(deviceValue) {
    console.log('area selected');
    console.log(deviceValue);
    console.log(this.frmpincode.controls.ctlpincode.value);
    console.log(this.frmpincode.controls.ctlareaid.value);
  }

  getstoreinfo() {
    if (this.enterlocationmanuly) {
      if(this.enteredpincode == "")
      {
          alert('Enter Pincode');
      }
      else
      {
        this.loctioninfomation.emit({pincode: this.enteredpincode, areaid: this.enteredareaid});
      }
     // this.getareastoreinfo(this.enteredpincode, this.enteredareaid)
   
    }
    else {
      if (this.geolocationselected) {
        if (this.postal_code != undefined && this.postal_code != '') {
          //this.getareastoreinfo(this.postal_code);
          this.loctioninfomation.emit({pincode: this.postal_code, areaid: '0'});
        }
        else {
          this.loctioninfomation.emit({pincode: '0', areaid: '0'});
        }
      }
    }
  }

  getLocation() {
    this.api.getPosition().then(
      pos => {
        console.log(`Positon: ${pos.lng} ${pos.lat}`);
        //this.showPosition(pos.lat, pos.lng);
        this.showcurrentPosition(pos.lat, pos.lng);
        this.getgeoaddress(pos.lat, pos.lng);
      },
      err => {
        this.geolocationselected = false;
        console.log("Task Errored!");
        console.log(err);
        //alert(err.message);
        this.toastr.warning(err.message)
      });
  }

  geoinfo: any;
  city: any;
  state: any;
  country: any;
  postal_code: any;
  //address: any;

  getgeoaddress(paralat, paralng) {
    this.api.getAddress(paralat, paralng).then(
      pos => {
        console.log('Address resolve');
        console.log(pos);
        this.geoinfo = pos;
        this.address = this.geoinfo.formatted_address;

        for (var i = 0; i < this.geoinfo.address_components.length; i++) {
          for (var b = 0; b < this.geoinfo.address_components[i].types.length; b++) {
            switch (this.geoinfo.address_components[i].types[b]) {
              case 'locality':
                this.city = this.geoinfo.address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                this.state = this.geoinfo.address_components[i].long_name;
                break;
              case 'country':
                this.country = this.geoinfo.address_components[i].long_name;
                break;
              case 'postal_code':
                this.postal_code = this.geoinfo.address_components[i].long_name;
                break;
            }
          }
        }
        console.log('City = ' + this.city + ', ' + 'State = ' + this.state + ', ' + 'Country = ' + this.country + ', postal_code = ' + this.postal_code);
        console.log("Address: " + this.address);
      },
      err => {
        console.log("address Errored!");
        console.log(err);
        alert(err.message);
      });
  }
  
  private showcurrentPosition(paralat, paralng) {
    try {
        this.latitude = paralat;
        this.longitude = paralng;
        this.radiusLat = this.latitude;
        this.radiusLong = this.longitude;
        this.zoom = 15;
        this.markers.push(
          {
            lat: this.latitude,
            lng: this.longitude,
          })
          this.geolocationselected = true;
        } catch (error) {
          this.geolocationselected = false;
          console.log('Error in showlocation');
          console.log(error);
        }
  }


  // showPosition(paralat, paralng) {
  //   try {
  //     var lat = paralat;
  //     var lon = paralng;
  //     var latlon = new google.maps.LatLng(lat, lon)
  //     var mapholder = document.getElementById('mapholder')
  //     mapholder.style.height = '250px';
  //     mapholder.style.width = '100%';
  //     var myOptions = {
  //       center: latlon, zoom: 14,
  //       mapTypeId: google.maps.MapTypeId.ROADMAP,
  //       mapTypeControl: false,
  //       //navigationControlOptions: { style: google.maps.NavigationControlStyle.SMALL }
  //     };
  //     this.map = new google.maps.Map(document.getElementById("mapholder"), myOptions);
  //     var marker = new google.maps.Marker({ position: latlon, map: this.map, title: "You are here!" });
  //     var circle = new google.maps.Circle({
  //       map: this.map,
  //       radius: 500,    // miles in metres
  //       fillColor: '#BFE0A1',
  //       strokeColor: '#BFE0A5'
  //     });
  //     circle.bindTo('center', marker, 'position');
  //     this.geolocationselected = true;
  //   } catch (error) {
  //     this.geolocationselected = false;
  //     console.log('Error in showlocation');
  //     console.log(error);
  //   }
  // }


  ResetComponents() {
    try {      
      this.arealist = [];
      this.enteredpincode = "";
      this.enteredareaid = "";
      this.geolocationselected = false;
      this.enterlocationmanuly = true;
      this.frmpincode.reset({
        ctlpincode: '', ctlareaid: ''
      });
      this.geoinfo = '';
      this.city = '';
      this.state = '';
      this.country = '';
      this.postal_code = '';
      this.address = '';
      this.frmpincode.reset();

    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
  }

  getcityarealist() {
    this.isLoading = true;
    let seq = this.api.post('cityareaapi/getallcityarea', null).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.cityarealist = res.Data;
        this.isLoading = false;
      }, err => {
        alert('Error');
      });
  }


  // areastoreinfo: any;
  // getareastoreinfo(pincode: any, areaid: string = '0') {
  //   let getdata: { pincode: string, cityarea_id: string } = {
  //     pincode: pincode,
  //     cityarea_id: areaid
  //   };
  //   let seq = this.api.post('Dashviewapi/sellerlistareawise', getdata).share();
  //   seq
  //     .map(res => res.json())
  //     .subscribe(res => {
  //       this.areastoreinfo = res.Data;
  //       console.log(this.areastoreinfo);
  //       if(this.areastoreinfo != undefined)
  //       {
  //         if(this.areastoreinfo.length > 0)
  //         {
  //           this.toastr.success(this.areastoreinfo.length + " Store found Near your area!!");
  //         }
  //         else
  //         {
  //           this.toastr.warning("Sorry, No Store available Near your area");
  //         }
  //       }
  //       else{
  //       this.toastr.error("No Store Info Available");
  //       }
  //     }, err => {
  //       alert("Error");
  //     });
  // }



  // getcityinfo() {

  //   var latlng;
  //   latlng = new google.maps.LatLng(40.730885, -73.997383); // New York, US
  //   //latlng = new google.maps.LatLng(37.990849233935194, 23.738339349999933); // Athens, GR
  //   //latlng = new google.maps.LatLng(48.8567, 2.3508); // Paris, FR
  //   //latlng = new google.maps.LatLng(47.98247572667902, -102.49018710000001); // New Town, US
  //   //latlng = new google.maps.LatLng(35.44448406385493, 50.99001635390618); // Parand, Tehran, IR
  //   //latlng = new google.maps.LatLng(34.66431108560504, 50.89113940078118); // Saveh, Markazi, IR

  //   new google.maps.Geocoder().geocode({ 'latLng': latlng }, function (results, status) {
  //     if (status == google.maps.GeocoderStatus.OK) {
  //       if (results[1]) {
  //         var country = null, countryCode = null, city = null, cityAlt = null;
  //         var c, lc, component;
  //         for (var r = 0, rl = results.length; r < rl; r += 1) {
  //           var result = results[r];

  //           if (!city && result.types[0] === 'locality') {
  //             for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
  //               component = result.address_components[c];

  //               if (component.types[0] === 'locality') {
  //                 city = component.long_name;
  //                 break;
  //               }
  //             }
  //           }
  //           else if (!city && !cityAlt && result.types[0] === 'administrative_area_level_1') {
  //             for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
  //               component = result.address_components[c];

  //               if (component.types[0] === 'administrative_area_level_1') {
  //                 cityAlt = component.long_name;
  //                 break;
  //               }
  //             }
  //           } else if (!country && result.types[0] === 'country') {
  //             country = result.address_components[0].long_name;
  //             countryCode = result.address_components[0].short_name;
  //           }

  //           if (city && country) {
  //             break;
  //           }
  //         }

  //         console.log("City: " + city + ", City2: " + cityAlt + ", Country: " + country + ", Country Code: " + countryCode);
  //       }
  //     }
  //   });
  // }

  //  showError(error) {
  //   switch (error.code) {
  //     case error.PERMISSION_DENIED:
  //       x.innerHTML = "User denied the request for Geolocation."
  //       break;
  //     case error.POSITION_UNAVAILABLE:
  //       x.innerHTML = "Location information is unavailable."
  //       break;
  //     case error.TIMEOUT:
  //       x.innerHTML = "The request to get user location timed out."
  //       break;
  //     case error.UNKNOWN_ERROR:
  //       x.innerHTML = "An unknown error occurred."
  //       break;
  //   }
  // }


}
