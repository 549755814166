import { Component, OnInit, ElementRef,  ViewChild, TemplateRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/operator/delay';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import * as _ from 'underscore'
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-sellerproducts',
  templateUrl: './sellerproducts.component.html',
  styleUrls: ['./sellerproducts.component.css']
})
export class SellerproductsComponent implements OnInit {
  sellerid: any;
  sellername: any;
  public struserid: string = "0";
  public userloggedin: boolean = false;
  public loading = false;
  pagedPosts: any[];
  categoryproducts: any[];
  defaultcategoryproducts: any[];
  imagebasepath: string = "";
  pageSize = 8;
  cartcount = 0;
  catcount = 0;
  sellerinfo: any;
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };

  @ViewChild('logincontent') logincontentref: TemplateRef<any>;
  @ViewChild('signupcontent') signupcontentref: TemplateRef<any>;
  @ViewChild('otpverificationcontent') otpverificationcontentref: TemplateRef<any>;
  @ViewChild('newsignupcontent') newsignupcontentref: TemplateRef<any>;

  constructor(private elementRef: ElementRef, public api: Api, public globalSettings: Settings, private _Router: Router, protected localStorage: LocalStorage,
    private modalService: NgbModal,
    private _routeParams: ActivatedRoute , private toastr: ToastrService) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  ngOnInit() {

    var name = this._routeParams.queryParams.subscribe(query => {
      this.sellername = query["sellername"];
    });

    var id = this._routeParams.params.subscribe(params => {
      var id = params["id"];
      this.sellerid = id;
      if (!id)
      return;

      let currentuserid = this.struserid === "" ? "0" : this.struserid;

      let users: { adminuser_id: string, id: string } = {
        adminuser_id: this.sellerid,
        id: currentuserid
      };

      this.loading = true;
     let seq = this.api.post('productapi/getallproductBySeller', users).share();
      //let seq = this.api.post('Itembuyapi/getallitembuybyseller', users).share();
      seq
        .map(res => res.json())
        //.delay(2000)
        .subscribe(res => {
          
          this.categoryproducts = res.Data;
          this.defaultcategoryproducts = res.Data;
          this.sellerinfo = res.seller;
          
          this.pagedPosts = _.take(this.categoryproducts, this.pageSize);
          this.loading = false;
        }, err => {
          alert("Error");
        });

    });
  }

  
  likeproduct(product: any) {
    if (!this.userloggedin) {
      //this._Router.navigate(['/login']);
      this.openloginmodal();
      return;
    }
    //   $('#login-modal').modal('show');
    var statustosend = '';
    var index = this.categoryproducts.indexOf(product);

    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };

    if (product.IsuserLike === '0') {

      statustosend = '1';
      product.like_count = Number(product.like_count) + 1;
      this.categoryproducts[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.categoryproducts[index].IsuserLike = '0';
          product.like_count = Number(product.like_count) - 1;
          alert('Error');
        });
    } else {
      statustosend = '0';
      product.like_count = Number(product.like_count) - 1;
      this.categoryproducts[index].IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemunlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          this.categoryproducts[index].IsuserLike = '1';
          product.like_count = Number(product.like_count) + 1;
          alert('Error');
        });

    }
  }

  openloginmodal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.logincontentref,{windowClass:"ngcustommodal"});
  }
  
  onsignupclick($event) {
    this.modalService.dismissAll();
    this.modalService.open(this.signupcontentref,{windowClass:"ngcustommodal"});
  }

  strReceivedmobile = "0";
  onotpsentsuccessfully($event) {
    this.strReceivedmobile = $event.mobileno;
    this.modalService.dismissAll();
    console.log($event.mobileno);
    const modalRef = this.modalService.open(this.otpverificationcontentref,{windowClass:"ngcustommodal"});
    modalRef.componentInstance.strmobileno = $event.mobileno;
  }

  onloginsuccess($event) {
    this.modalService.dismissAll();
  }

  strReceivedmobileafterotpverification = "0";
  onotpverificationsuccessfull($event) {
    this.strReceivedmobileafterotpverification = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.newsignupcontentref,{windowClass:"ngcustommodal"});
    modalRef.componentInstance.strsignupmobileno = $event.mobileno;
  }

  onsignupsuccessfull($event)
  {
    console.log($event.mobileno);
    this.modalService.dismissAll();
  }


 
  // addToCart(product) {
  //   console.log(product);
  //   // let cartdata = $.parseJSON(localStorage.getItem('cart'));
  //   this.localStorage.getItem('cart').subscribe((cartdata) => {
  //     cartdata = $.parseJSON(cartdata);
  //     if (cartdata === null || cartdata.length === 0) {
  //       cartdata = [];
  //       cartdata.push({
  //         'product': product,
  //         'qty': 1,
  //         'amount': parseFloat(product.item_price)
  //         ,'adminuser_id': Number(0)
  //       });
  //     } else {
  //       let added = 0;
  //       for (let i = 0; i < cartdata.length; i++) {
  //         if (product.items_id === cartdata[i].product.items_id) {
  //           let qty = cartdata[i].qty;
  //           cartdata[i].qty = qty + 1;
  //           cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.item_price);
  //           added = 1;
  //         }
  //       }

  //       if (added === 0) {
  //         cartdata.push({
  //           'product': product,
  //           'qty': 1,
  //           'amount': parseFloat(product.item_price)
  //         });
  //       }

  //     }

  //     this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
  //       if (cartdata === null) {
  //         this.cartcount = 0;
  //       } else {
  //         this.cartcount = cartdata.length;
  //       }
  //       this.globalSettings.setcount(this.cartcount);
  //     });

  //     if (this.struserid !== '0') {
  //       this.cartdata1.user_id = this.struserid;
  //       this.cartdata1.cart = JSON.stringify(cartdata);
  //       const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
  //       seq
  //         .map(res => res.json())
  //         .subscribe(res => {
  //         }, err => {
  //           alert('Error');
  //         });
  //     }
  //   });
  // }


  
  addToCart(product) {
    // let cartdata = $.parseJSON(localStorage.getItem('cart'));
    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': product,
          'qty': 1,
          'amount': parseFloat(product.saleprice)
          ,'adminuser_id': Number(product.adminuser_id)
        });
      } else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          if (product.items_id === cartdata[i].product.items_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + 1;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }

        if (added === 0) {
          cartdata.push({
            'product': product,
            'qty': 1,
            'amount': parseFloat(product.saleprice)
            ,'adminuser_id': Number(product.adminuser_id)
          });
        }

      }

      this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }

      this.toastr.success('Cart Updated !!');
    });


  }

}
