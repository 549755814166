import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { Api } from '../../service/providers';
import { clsUser } from './clsuser';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { COMMON_PIPES } from '@angular/common/src/pipes';
declare var $: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [Api]
})
export class UserComponent implements OnInit {

  customerlist: any[];

  constructor(public api: Api, private _router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.getpolicycompanylist();
  }

  getpolicycompanylist() {
    let seq = this.api.post('userdata/getCustomerList', '').share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.customerlist = res.Data;
        $('.table').footable();
      }, err => {
        alert("Error");
      });
  }

  activateorblockuser(user: any) {
    var Msg = "";
    var statustosend = "";
    if (user.IsActivate === "0") {
      Msg = "Do you want to Activate " + user.full_name;
      statustosend = "1";
    }
    else {
      Msg = "Do you want to Suspend " + user.full_name;
      statustosend = "0";
    }
    

    if (confirm(Msg)) {
      var index = this.customerlist.indexOf(user);
      // this.customerlist[index].IsActivate = statustosend;    
      // console.log(this.customerlist);
      let usersuspend: { user_id: string,IsActivate:string } = {
        user_id: user.user_id,IsActivate:statustosend
      };

      let seq = this.api.post('userdata/updateuser', usersuspend).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.getpolicycompanylist();
        }, err => {
          alert("Error");
        });


      // this.service.updateuserstatus(user.user_id, statustosend).subscribe(data => {
      // }
      //   , error => {
      //     alert("Error- " + error);
      //   }
      // );
    }
  }


}
