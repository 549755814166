import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
// import { PageTitleService } from '../../core/page-title/page-title.service';
// import {fadeInAnimation} from "../../core/route-animation/route.animation";
import { Api } from '../../service/providers';
import { companymaster } from './clscompanymaster';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-companymasterlist',
  templateUrl: './companymasterlist.component.html',
  styleUrls: ['./companymasterlist.component.css'],
  providers: [Api]
})
export class CompanymasterlistComponent implements OnInit {

  customerlist: any[];
  
  constructor(public api: Api, private _router: Router,private toastr: ToastrService) { }
 
  ngOnInit() {
    this.getpolicycompanylist();
  }


  getpolicycompanylist() {
    let seq = this.api.post('Companyapi/getallcompany', '').share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.customerlist = res.Data;
        $('.table').footable();
      }, err => {
        alert("Error");
      });
  }


  deletecompany(product: any) {
    if (confirm("Are you sure you want to delete company -  " + product.companyname + "?")) {
      var index = this.customerlist.indexOf(product);

      let Company: { companyid: string } = {
        companyid: product.companyid
      };

      let seq = this.api.post('Companyapi/deletecompany', Company).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          if (res.Data == "Deleted Sucessfully") {
            this.customerlist.splice(index, 1);
          }
          else if (res.Data == "Company already in use") {
            //alert("Family already in used by customer");
            this.toastr.warning('Company already in use');
          }
          //Family use in customer master
        }, err => {
          //this.customerlist.splice(index, 0, product);
          this.toastr.error('Error');
        });
    }
  }
}
