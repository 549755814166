import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
declare var $: any;

@Component({
  selector: 'app-roughtemplate',
  templateUrl: './roughtemplate.component.html',
  styleUrls: ['./roughtemplate.component.css']
})
export class RoughtemplateComponent implements OnInit {

  public catcount: number = 0;
  isLoading = false;
  categorylist: any[];
  constructor(private elementRef: ElementRef, public api: Api) { }

  ngOnInit() {
    //  this.getcategorylist();
  }


  getcategorylist() {
    let seq = this.api.post('/categoryapi/getAllCategories', '').share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.categorylist = res.Data;
        console.log(this.categorylist);
        if (this.categorylist.length > 0) {
          this.catcount = this.categorylist.length;
        }
        this.isLoading = false;

      }, err => {
        alert("Error");
      });
  }


  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    // s.src = "http://somedomain.com/somescript";
    s.text = `
		 $(document).ready( function() {
		//alert('hi');
			$('#slider').nivoSlider({
        effect: 'random',
        animSpeed: 300,
        pauseTime: 5000,
        directionNav: true,
        manualAdvance: true,
        controlNavThumbs: false,
        pauseOnHover: true,
        controlNav: false,
        prevText: "<i class='lnr lnr-arrow-left'></i>",
        nextText: "<i class='lnr lnr-arrow-right'></i>"
    });

    $('.categorie-title,.mobile-categorei-menu').on('click', function () {
      $('.vertical-menu-list,.mobile-categorei-menu-list').slideToggle();
  });

  jQuery('.mobile-menu nav').meanmenu({
    meanScreenWidth: "991",
})

$('#cate-toggle li.has-sub>a,#cate-mobile-toggle li.has-sub>a,#shop-cate-toggle li.has-sub>a').on('click', function () {
  $(this).removeAttr('href');
  var element = $(this).parent('li');
  if (element.hasClass('open')) {
      element.removeClass('open');
      element.find('li').removeClass('open');
      element.find('ul').slideUp();
  } else {
      element.addClass('open');
      element.children('ul').slideDown();
      element.siblings('li').children('ul').slideUp();
      element.siblings('li').removeClass('open');
      element.siblings('li').find('li').removeClass('open');
      element.siblings('li').find('ul').slideUp();
  }
});
$('#cate-toggle>ul>li.has-sub>a').append('<span class="holder"></span>');
		});
	
		 `;
    this.elementRef.nativeElement.appendChild(s);

  }


}
