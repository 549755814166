import { Component } from '@angular/core';
import { Router, RouterOutlet, RouterLink, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MHDS: Client';

  // constructor( public _Router: Router) {
  //   this._Router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       //this.currenturl = this._Router.url
  //       window.scrollTo(0, 0);
  //       console.log('in scroll')
  //     }
  //   });
  // }

  onActivate(event) {
    // window.scroll(0,0);
    document.body.scrollTop = 0;
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)

  }
}

// this.router.events.subscribe((path) => {
//   if (path.url != this.url) {
//     window.scrollTo(0, 0);
//   }
// });
