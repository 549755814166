import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
// import { PageTitleService } from '../../core/page-title/page-title.service';
// import {fadeInAnimation} from "../../core/route-animation/route.animation";
import { Api } from '../../service/providers';
import { product } from './clsproduct';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css'],
  providers: [Api]
})
export class ProductlistComponent implements OnInit {


  customerlist: any[];

  constructor(public api: Api, private _router: Router,private toastr: ToastrService) { }
 
  ngOnInit() {
    this.getproductlist();
  }


  getproductlist() {
    let seq = this.api.post('Productapi/getallproduct', '').share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.customerlist = res.Data;
        $('.table').footable();
      }, err => {
        alert("Error");
      });
  }


  deleteproduct(product: any) {
    if (confirm("Are you sure you want to delete product -  " + product.name + "?")) {
      var index = this.customerlist.indexOf(product);

      let selectedProduct: { productid: string } = {
        productid: product.productid
      };

      let seq = this.api.post('Productapi/deleteproduct', selectedProduct).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          if (res.Data == "Deleted Sucessfully") {
            this.customerlist.splice(index, 1);
          }
          else if (res.Data == "Product already in use") {
            //alert("Family already in used by customer");
            this.toastr.warning('Product already in use');
          }
          //Family use in customer master
        }, err => {
          //this.customerlist.splice(index, 0, product);
          this.toastr.error('Error');
        });
    }
  }
}
