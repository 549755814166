import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { Settings } from '../../service/providers';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Api } from '../../service/providers';
declare var $: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  @Output() valueChange = new EventEmitter();
  public currentselectedlocation = ""
  isLoading = false;
  public userloggedin = false;
  public strusername = '';
  public struserid = '';
  public struseremail = '';
  cityarealist: any[];
  frmcourse: FormGroup;

  constructor(private elementRef: ElementRef, public api: Api,
    public globalSettings: Settings, public _Router: Router, fb: FormBuilder) {
      this.frmcourse = fb.group({
        city_id: [''],
      })


     }

  ngOnInit() {
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._loggedinusername.subscribe(value => this.strusername = value);
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._loggedinuseremail.subscribe(value => this.struseremail = value);
    this.getcityarealist();
  }

  locationchangeValue(location) { 
    // You can give any function name
    //this.currentselectedlocation = location.cityarea_id;
    this.valueChange.emit(location);
}

  getcityarealist() {
    this.isLoading = true;
    let seq = this.api.post('cityareaapi/getallcityarea', null).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.cityarealist = res.Data;
        $('.table').footable();
        this.isLoading = false;
      }, err => {
        alert('Error');
      });
  }

  
  selectoption(event){
    // console.log("selected event : ", event);   
  }

  
  removeoption(event){
    // console.log("removed event : ",event);    
  }
}
