import { OtpverificationComponent } from './../otpverification/otpverification.component';
import { LoginComponent } from './../login/login.component';
import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { Router, RouterOutlet, RouterLink, NavigationEnd } from '@angular/router';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
declare var $: any;
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable, of, Subject } from "rxjs";
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ChooselocationComponent } from './../chooselocation/chooselocation.component';

@Component({
  selector: 'appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.css']
})
export class AppheaderComponent implements OnInit {

  @ViewChild("chooselocationcmp")
  private chooselocationcmp: ChooselocationComponent;

  @ViewChild("loginmodal")
  private loginmodal: LoginComponent;

  @ViewChild('signupcontent') signupcontentref: TemplateRef<any>;
  @ViewChild('otpverificationcontent') otpverificationcontentref: TemplateRef<any>;
  @ViewChild('newsignupcontent') newsignupcontentref: TemplateRef<any>;

  public searchMenu = false;
  public userloggedin = false;
  public strusername = '';
  public struserid = '';
  public struseremail = '';
  // public struserkey:  = '';
  cartcount = '0';
  cartcount_prescription = '0';
  public catcount = 0;
  isLoading = false;
  categorylist: any[];
  OG_searchDataNopara: any;
  searchDataNopara: any;
  filterName: string = "";
  public chatvalueset: any;
  public pincode = '';
  public cityarea_id = '';
  Notificationlist: any[];
  public notificationcount: number = 0;
  imagebasepath: string = "";
  public currenturl: string = "";

  constructor(protected localStorage: LocalStorage, private elementRef: ElementRef, public api: Api,
    public globalSettings: Settings, public _Router: Router,
    public config: NgbModalConfig, private modalService: NgbModal
    , private toastr: ToastrService
  ) {
    this._Router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currenturl = this._Router.url
      }
    });

    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    //this.searchwithoutpara();
    this.imagebasepath = this.api._imagebasepath;
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._loggedinusername.subscribe(value => this.strusername = value);
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._loggedinuseremail.subscribe(value => this.struseremail = value);
    // this.globalSettings._loggedinuserauthkey.subscribe(value => this.struserkey = value);
    this.globalSettings._Cartcount.subscribe(value => this.cartcount = value);
    this.globalSettings._Cartcount_prescription.subscribe(value => this.cartcount_prescription = value);
    this.globalSettings._pincode.subscribe(value => this.pincode = value);
    this.globalSettings._cityarea_id.subscribe(value => this.cityarea_id = value);
    this.getcategorylist();
    this.getnotification();
    this.fastsearch();

    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        this.globalSettings.setcount('0');
      } else {
        this.globalSettings.setcount(cartdata.length);
      }
    });

    
    this.localStorage.getItem('cart_prescription').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        this.globalSettings.setcount_prescription('0');
      } else {
        this.globalSettings.setcount_prescription(cartdata.length);
      }
    });


    $(document).ready(function () {
      $('#prev').on('click', function () {
        $('#menu ul').animate({
          scrollLeft: '-=100'
        }, 300, 'swing');
      });

      $('#next').on('click', function () {
        $('#prev').show();
        $('#menu ul').animate({
          scrollLeft: '+=100'
        }, 300, 'swing');
      });
    });


  }

  open(content) {
    this.modalService.open(content,{windowClass:"ngcustommodal"});
  }

  openloginmodal(logincontent) {
    this.modalService.open(logincontent,{windowClass:"ngcustommodal"});
  }

  onsignupclick($event) {
    this.modalService.dismissAll();
    this.modalService.open(this.signupcontentref,{windowClass:"ngcustommodal"});
  }

  strReceivedmobile = "0";
  onotpsentsuccessfully($event) {
    this.strReceivedmobile = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.otpverificationcontentref,{windowClass:"ngcustommodal"});
    modalRef.componentInstance.strmobileno = $event.mobileno;
  }

  onloginsuccess($event) {
    this.modalService.dismissAll();
  }

  strReceivedmobileafterotpverification = "0";

  onotpverificationsuccessfull($event) {
    this.strReceivedmobileafterotpverification = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.newsignupcontentref,{windowClass:"ngcustommodal"});
    modalRef.componentInstance.strsignupmobileno = $event.mobileno;
  }

  onsignupsuccessfull($event)
  {
    this.modalService.dismissAll();
  }

  receivedlocationinfo($event) {
    $('#chooselocationmodal').modal('hide');
    //this.getareastoreinfo($event.pincode, $event.areaid);
    this.chooselocationcmp.ResetComponents();
    var strpincode = $event.pincode;
    if ($event.pincode == null || $event.pincode == undefined) {
      strpincode = "0";
    }
    var strareaid = $event.areaid;
    if ($event.areaid == null || $event.areaid == undefined) {
      strareaid = "0";
    }
    this.pincode = strpincode;
    this.modalService.dismissAll();
    this._Router.navigate(['/sellerfound', strpincode, strareaid]);


  }
  areastoreinfo: any;

  getareastoreinfo(pincode: any, areaid: string = '0') {
    let getdata: { pincode: string, cityarea_id: string } = {
      pincode: pincode,
      cityarea_id: areaid
    };
    let seq = this.api.post('Dashviewapi/sellerlistareawise', getdata).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.areastoreinfo = res.Data;
        if (this.areastoreinfo != undefined) {
          if (this.areastoreinfo.length > 0) {
            this.toastr.success(this.areastoreinfo.length + " Store found Near your area!!");
          }
          else {
            this.toastr.warning("Sorry, No Store available Near your area");
          }
        }
        else {
          this.toastr.error("No Store Info Available");
        }
      }, err => {
        alert("Error");
      });
  }


  // getcategorylist() {
  //   let seq = this.api.post('/categoryapi/getAllCategories', '').share();
  //   seq
  //     .map(res => res.json())
  //     .subscribe(res => {
  //       this.categorylist = res.Data;
  //       if (this.categorylist.length > 0) {
  //         this.catcount = this.categorylist.length;
  //       }
  //       this.isLoading = false;

  //     }, err => {
  //       alert("Error");
  //     });
  // }

  onlocationvaluechanged($event) {
    $('#location-modal').modal('hide');
    localStorage.setItem('userlocation', JSON.stringify($event));
    this.globalSettings.setLocationStatus(true);
    this._Router.navigate(['dashboard'], { queryParams: { "locationupdated": this.pincode } });
    this.globalSettings._pincode.subscribe(value => this.pincode = value);
  }


  searchwithoutpara() {
    let seq = this.api.post('Dashboardapi/searchdata', null).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.OG_searchDataNopara = res.Data;
        this.searchDataNopara = res.Data;
      }, err => {
        alert("Error");
      });
  }

  // filteritem(event: any) {
  //   console.log('Hellow' );
  //   console.log(this.filterName);
  //   if (this.OG_searchDataNopara != undefined && this.OG_searchDataNopara != null) {
  //     if (this.OG_searchDataNopara.length > 0) {
  //       this.searchDataNopara = this.OG_searchDataNopara.filter(
  //         obj =>
  //           obj.name.toLowerCase().indexOf(this.filterName.toLowerCase()) !== -1 ||
  //           obj.Type.indexOf(this.filterName) !== -1
  //       )
  //     }
  //   }
  // }
  results: any;
  searchingstatus = false;


  filteritem(val) {
    if (this.filterName.length >= 1) {
      //this.searchingstatus = true;
      this.searchTerm$.next(this.filterName);
      this.searchingstatus = true;
    }
    else if (this.filterName.length == 0) {
      //this.searchingstatus = false;
      this.results = [];
    }
    else {
      //this.searchingstatus = false;
    }
  }



  getcategorylist() {
    let user: { email: string } = { email: "test2@test.com" };
    let seq = this.api.post('categoryapi/getAllCategories', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.categorylist = res.Data;
        if (this.categorylist.length > 0) {
          this.catcount = this.categorylist.length;
        }
        this.isLoading = false;

      }, err => {
        alert("Error");
      });
  }

  searchBox() {
    this.searchMenu = !this.searchMenu;
  }

  searchTerm$ = new Subject<string>();
  fastsearch() {
//    this.searchingstatus = true;
    this.api.search(this.searchTerm$)
      .subscribe(res => {
        this.searchingstatus = false;
        this.results = res['Data'];
      },
        (err) => {
          this.searchingstatus = false;
          ('Error in Seach Call');
          console.log(err);
        }
      );
  }

  selectitem(item: any) {
    if (item != undefined) {
      this.filterName = "";
      if (item.Type.toLowerCase() == "product") {
        this._Router.navigate(['/', item.urllink]);
      }
      else if (item.Type.toLowerCase() == "category") {
        this._Router.navigate(['/category', item.urllink]);
      } else if (item.Type.toLowerCase() == "seller") {
        this._Router.navigate(['/seller', item.urllink]);
      }
    }
  }


  getnotification() {
    this.chatvalueset = setInterval(() => {
      this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
      if (this.userloggedin) {
        this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
        let selller: { user_id: string } = {
          user_id: this.struserid
        }
        let seq = this.api.post('Notificationapi/getnotificationforuser', selller).share();
        seq
          .map(res => res.json())
          .subscribe(res => {

            this.Notificationlist = res.Data;
            this.notificationcount = this.Notificationlist.length;
          }, err => {
            console.log('Error while getting notification.');
            console.log(err);
          });
      }
    }, 180000);
  }


  updatenotificationandredirect(notificationId: any, order_id: any) {
    let selller: { usernotification_id: string, isdeliver: string } = {
      usernotification_id: notificationId, isdeliver: "1"
    }
    let seq = this.api.post('Notificationapi/updatenotificationforuser', selller).share();

    seq
      .map(res => res.json())
      .subscribe(res => {
        this._Router.navigate(['orders']);
        //[routerLink]="['/orderupdate', category.order_id]"
      }, err => {
        alert('Error');
      });

  }



  hidemenu() {
    $('.vertical-menu-list,.mobile-categorei-menu-list').slideToggle();
  }

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    // s.src = "http://somedomain.com/somescript";
    s.text = `
		 $(document).ready( function() {
		//alert('hi');
			$('#slider').nivoSlider({
        effect: 'random',
        animSpeed: 300,
        pauseTime: 5000,
        directionNav: true,
        manualAdvance: true,
        controlNavThumbs: false,
        pauseOnHover: true,
        controlNav: false,
        prevText: "<i class='lnr lnr-arrow-left'></i>",
        nextText: "<i class='lnr lnr-arrow-right'></i>"
    });

    $('#cate-toggle2').on('click', function () {
      alert('hi2');
    });
    
      $('.categorie-title,.mobile-categorei-menu').on('click', function () {
        $('.vertical-menu-list,.mobile-categorei-menu-list').slideToggle();
      });

       jQuery('.mobile-menu nav').meanmenu({
         meanScreenWidth: "991",
       })

      $('#cate-toggle li.has-sub>a,#cate-mobile-toggle li.has-sub>a,#shop-cate-toggle li.has-sub>a').on('click', function () {
        $(this).removeAttr('href');
        var element = $(this).parent('li');
        if (element.hasClass('open')) {
            element.removeClass('open');
            element.find('li').removeClass('open');
            element.find('ul').slideUp();
        } else {
            element.addClass('open');
            element.children('ul').slideDown();
            element.siblings('li').children('ul').slideUp();
            element.siblings('li').removeClass('open');
            element.siblings('li').find('li').removeClass('open');
            element.siblings('li').find('ul').slideUp();
        }
      });
      $('#cate-toggle>ul>li.has-sub>a').append('<span class="holder"></span>');

    
		});
		 `;
    this.elementRef.nativeElement.appendChild(s);

  }

  chooselocation() {
    $('#location-modal').modal('show');
  }

  chooselocation2() {
    this.chooselocationcmp.ResetComponents();
    $('#chooselocationmodal').modal('show');
  }

  opensignupmodal() {
    $('#login-modal').modal('hide');
    $('#signup-modal').modal('show');
  }


  login() {
    this._Router.navigate(['/login'],
      { queryParams: { backurl: this.currenturl } });
  }


  logout() {
    this.globalSettings.setLoginStatus(false);
    this.globalSettings.clearuserparameters();
    this.globalSettings.clearlocationparameters();
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings.setcount(0);
    this.globalSettings.setcount_prescription(0);
    localStorage.clear();
    this._Router.navigate(['/']);
  }


  searchproducts() {
    var strsearch = "";
    strsearch = $('#txtsearch').val();
    $('#txtsearch').val('');
    if (strsearch !== "") {
      this._Router.navigate(['/search', strsearch]);
    }
    else {
      alert("Nothing Entered to Search");
    }
  }

  ngOnDestroy() {
    if (this.chatvalueset) {
      clearInterval(this.chatvalueset);
    }
  }
}
