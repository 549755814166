import { Component, OnInit } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'appfooter',
  templateUrl: './appfooter.component.html',
  styleUrls: ['./appfooter.component.css']
})
export class AppfooterComponent implements OnInit {

  public struserid:string = "0";
  public userloggedin: boolean = false;
  footerlist: any[];
  imagebasepath: string = "";


  constructor(public api: Api, private _router: Router,public globalSettings: Settings,public config: NgbModalConfig, private modalService: NgbModal, private toastr: ToastrService) { 
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;

    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.getallfooterlink();
  }

  getallfooterlink() {
    let seq = this.api.post('Footerlinkapi/getfooterclientdata', '').share();
    seq
      .map(data => data.json().Data)
      .subscribe(data => {
        this.footerlist = data;
      }, err => {
        alert("Error");
      });
  }

  
  openloginmodal(signupsellercontent) {
    this.modalService.open(signupsellercontent,{windowClass:"hugeModal"});
  }

  onsellersignupsucess($event)
  {
    this.modalService.dismissAll();
    document.body.scrollTop = 0;
  }

}
