import { CustomValidator2 } from './../../shared/customvalidator';
import { Component, OnInit, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Api } from '../../service/api/api';
import { Settings } from '../../service/providers';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  
  @Input() mobileno = '';
  @Input() user_id = '';
  @Input() email = '';
  @Output() passwordchanged = new EventEmitter<void>();


  frmsignup: FormGroup;
  _mobileno = "0";
  _user_id = "0";
  _email = "0";

  constructor(fb: FormBuilder, public api: Api, public settings: Settings, private _routeParams: ActivatedRoute,
    private _Router: Router, private toastr: ToastrService) { 
      this.frmsignup = fb.group({
        user_pass: ['', [
          Validators.required,
          Validators.minLength(3),
          CustomValidator2.cannotContainspace
        ]],
        reuser_pass: ['', Validators.required]
      }, { validator: CustomValidator2.passwordsShouldMatch });
  }

  ngOnInit() {
    console.log('this.mobileno');
    console.log(this.mobileno);
    this._mobileno = this.mobileno;
    this._user_id = this.user_id;
    this._email = this.email;
  }

  
  changepassword() {
    let user: { user_id: string, user_pass: string} = {
      user_id: this._user_id,
      user_pass: this.frmsignup.controls.user_pass.value,
    };

    let seq = this.api.post_httpclient('userdata/changepwdforgot', user);
    seq
      .subscribe(res => {
        if (res['status'] == "Sucess" && res['Data'] == "Sucessfully Password updated") {
          this.toastr.success('Paasowrd Changed');
          this.passwordchanged.emit();
        }
        else
        {
          
        }
      }, err => {
        alert(err);
      });
  }

}
