export class clsseller{
    adminuser_id: string= ""; 
    email: string= "";
    firstname: string= "";
    lastname: string= "";
    user_pass: string= "";
    address: string= ""; 
    gender: string= ""; 
    company_name: string= "";
    contactno1: string= "";
    contactno2: string= "";
    website: string= "";
    account_type: string= ""; 
    usertype: string= "seller";
    IsActivate: number=0;
    Issuspended: number=0;
    main_image: string= "";
    thumbnail: string= "";
    shopeact: string= "";
    adressproof: string= "";
    }