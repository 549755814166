import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule,AbstractControl } from "@angular/forms";

export class CustomValidator2 {

    static cannotContainspace(ctrl: FormControl) {
        if (String(ctrl.value).indexOf(' ') >= 0) {
            // console.log(String(ctrl.value).indexOf(' '));
            return { cannotContainspace: true };
        }
        else {
            return null;
        }
    }

    static email(control: FormControl) {
        var regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var valid = regEx.test(control.value);
        return valid ? null : { email: true };
    }

    static numberonly(control: FormControl) {

        var regEx = /^[0-9]*$/;
        var valid = regEx.test(control.value);
        return valid ? null : { numberonly: true };
    }

    static urlonly(control: FormControl) {
        var regEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        var valid = regEx.test(control.value);
        return valid ? null : { urlonly: true };
    }

    static passwordsShouldMatch(group: FormGroup) {
        var newPassword = group.controls.user_pass.value;
        var confirmPassword = group.controls.reuser_pass.value;
        // If either of these fields is empty, the validation 
        // will be bypassed. We expect the required validator to be 
        // applied first. 
        if (newPassword == '' || confirmPassword == '')
            return null;

        if (newPassword != confirmPassword)
            return { passwordsShouldMatch: true };

        return null;
    }

    static isNumberCheck(control: FormGroup)  {
    var regEx = /^[0-9]+(\.[0-9]{1,2})?$/;
        var valid = regEx.test(control.value);
        return valid ? null : { numberonly: true };
    }


    // Asnyc Validator : check promise in details
    // static shouldbeunique(ctrl: FormControl)
    // {
    //     return new Promise((resolve, reject) => {
    //         setTimeout(function() {
    //             if(ctrl.value == "chintan")
    //             {
    //                 resolve({shouldbeunique: true});
    //             }
    //             else
    //             {
    //                 resolve(null);
    //             }
    //         }, 5000);
    //     });
    // }
}