import { Component, OnInit, ElementRef, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Api } from '../../service/api/api';
import { Settings } from '../../service/providers';
import { Router, ActivatedRoute } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  @Output() signupclick = new EventEmitter<void>();
  @Output() loginsuccess = new EventEmitter<void>();
  @Output() forgotpassclick = new EventEmitter<void>();

  @ViewChild('forgotpassverification') forgotpassverificationref: TemplateRef<any>;
  @ViewChild('forgotpassotpverification') forgotpassotpverificationref: TemplateRef<any>;
  @ViewChild('forgotpasssetnewpass') forgotpasssetnewpassref: TemplateRef<any>;

  isLoading = false;
  productlist: any;
  isLoggedIn: boolean;
  returnUrl: string;
  frmsignup: FormGroup;
  result: any;
  cartItemsserver: any;
  cartItems_Pres_server: any;
  public userloggedin = false;
  isadd: Boolean = false;
  cartuserdata: { user_id: string } = { user_id: '0' };
  returnurl: string = "";
  client_id: any;

  constructor(protected localStorage: LocalStorage, fb: FormBuilder, public api: Api, public settings: Settings,
    public config: NgbModalConfig, private modalService: NgbModal,
    private _routeParams: ActivatedRoute,
    private _Router: Router, private toastr: ToastrService) {
    this.frmsignup = fb.group({
      email: ['', Validators.required],
      user_pass: ['', Validators.required]
    });
  }

  ngOnInit() {

    var url = this._routeParams.queryParams.subscribe(query => {
      this.returnurl = query["backurl"];
      console.log('this.returnurl : ', this.returnurl);
    });

    this.settings._isuserloggedin.subscribe(value => this.userloggedin = value);
    if (this.userloggedin) {
      this._Router.navigate(['/home']);
    } else {
      this.frmsignup.reset({
        email: '', user_pass: ''
      });
    }
  }

  backurl() {
    if (this.returnurl == '' || this.returnurl == undefined || this.returnurl == null) {
      this._Router.navigate(['/dashboard']);
    }
    else {
      this._Router.navigate([this.returnurl]);
    }
  }

  doLogin() {
    const datak = CryptoJS.enc.Hex.parse(this.bin2hex('1xB8oBQgXGPhcKoD0QkP1Uj4CRZ7Sy1c'));
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    const encUser = CryptoJS.AES.encrypt(this.frmsignup.controls.user_pass.value, datak, {
      iv: iv
    }).ciphertext;
    const data = iv.concat(encUser).toString(CryptoJS.enc.Base64);
    const account: { email: string, user_pass: string } = {
      email: this.frmsignup.controls.email.value,
      user_pass: data
    };
    console.log(data);
    let seq = this.api.post_httpclient('userdata/ClientLogin', account);
    seq
      .subscribe(res => {
        if (res['status'] === 'Sucess' && res['Data'] === 'Valid User') {
          localStorage.setItem('sellerclient', JSON.stringify(res['UserData']));
          this.settings.setLoginStatus(true);
          let user_id = '';
          this.settings._loggedinuserid.subscribe(value => user_id = value);
          this.settings._loggedinuserid.subscribe(value => this.client_id = value);
          this.getSetUserCartData();
          this.getuser_Presciption_Cartdata();
          this.loginsuccess.emit();
        } else {
          this.toastr.warning('Invalid Login');
        }
      }, err => {
        this.toastr.error(err);
      });
  }

  getSetUserCartData() {
    this.cartuserdata.user_id = this.client_id;
    const seq1 = this.api.post_httpclient('cartdataapi/getcartdatadetails', this.cartuserdata);
    seq1
      .subscribe(res1 => {
        if (res1['Data'] != null) {
          this.cartItemsserver = $.parseJSON(res1['Data'].cart);
          this.localStorage.setItem('cart', JSON.stringify(this.cartItemsserver)).subscribe(() => {
          });
          if (this.cartItemsserver != undefined && this.cartItemsserver != null && this.cartItemsserver.length > 0) {
            this.settings.setcount(this.cartItemsserver.length);
          }
          else {
            this.settings.setcount(0);
          }
        }
        else {
          this.settings.setcount(0);
        }
      }, err => {
        alert(err);
      });
  }

  getuser_Presciption_Cartdata() {
    let user: { user_id: string } = { user_id: this.client_id }
    const seq = this.api.post('precartdataapi/getprecartdatadetails', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        if (res != null && res['Data'] != null) {
          this.cartItems_Pres_server = $.parseJSON(res['Data'].cart);
          this.localStorage.setItem('cart_prescription', JSON.stringify(this.cartItems_Pres_server)).subscribe(() => {
          });
          if (this.cartItems_Pres_server.length != undefined && this.cartItems_Pres_server.length != null && this.cartItems_Pres_server.length > 0) {
            this.settings.setcount_prescription(this.cartItems_Pres_server.length);
          }
          else {
            this.settings.setcount_prescription(0);
          }
        }
        else {
          this.settings.setcount_prescription(0);
        }
      }, err => {
        alert(err);
      });
  }

  signupnewuser() {
    this.signupclick.emit();
  }

  forgotpass() {
    this.modalService.dismissAll();
    this.modalService.open(this.forgotpassverificationref, { windowClass: "ngcustommodal" });
    //this.forgotpassclick.emit();
  }

  strReceivedmobile = "0";
  onotpsentsuccessfully($event) {

    console.log('in onotpsentsuccessfull');
    console.log($event);
    this.strReceivedmobile = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.forgotpassotpverificationref, { windowClass: "ngcustommodal" });
    //modalRef.componentInstance.strmobileno = $event.mobileno;
  }

  strmobileno = "0";
  struser_id = "0";
  stremail = "0";

  otpverificationsuccessfull($event) {
    console.log($event);
    this.strmobileno = $event.mobileno;
    this.struser_id = $event.user_id;
    this.stremail = $event.email;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.forgotpasssetnewpassref, { windowClass: "ngcustommodal" });
    // modalRef.componentInstance.mobileno = $event.mobileno;
    // modalRef.componentInstance.user_id = $event.user_id;
    // modalRef.componentInstance.email = $event.email;
  }

  passwordchanged($event) {
    console.log($event);
    this.modalService.dismissAll();
  }

  bin2hex(bin) {
    var i = 0, l = bin.length, chr, hex = ''
    for (i; i < l; ++i) {
      chr = bin.charCodeAt(i).toString(16)
      hex += chr.length < 2 ? '0' + chr : chr
    }
    return hex
  }

}
