import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
declare var $: any;
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable()

export class Settings {

  public _isuserloggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _loggedinusername: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _loggedinuseremail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _loggedinuserid: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  public _isSuperUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _Cartcount: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  public _Cartcount_prescription: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  //public _imagebasepath = 'http://myhomedeliveryservices.com/rest_sellerecomm/';
  public _imagebasepath = 'https://rest.mhds.co.in/';

  public _pincode: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _cityarea_id: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // public _webapiurl: string = "http://dev42:81/ecomm/index.php/";

  constructor(protected localStorage: LocalStorage) {
    this.getloginstatus();
  }

  getloginstatus(): boolean {
    // var objtemp = $.parseJSON(localStorage.getItem('sellerclient'));
    var objtemp = $.parseJSON(localStorage.getItem('sellerclient'));
    if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
      this._isuserloggedin.next(true);
      //this._loggedinusername.next(objtemp.firstname + ' ' + objtemp.lastname);
      this._loggedinusername.next(objtemp.firstname);
      this._loggedinuserid.next(objtemp.user_id);
      this._loggedinuseremail.next(objtemp.email);
      return true;
    } else {
      this._isuserloggedin.next(false);
      this._loggedinusername.next('');
      return false;
    }
  }


  setLoginStatus(isLoggedIn: boolean) {
    this.setuserparameter(isLoggedIn);
  }

  setcount(cartcount) {
    this._Cartcount.next(cartcount);
  }

  setcount_prescription(cartcount) {
    this._Cartcount_prescription.next(cartcount);
  }

  setusername(usernm: string) {
    this._loggedinusername.next(usernm);
  }

  setuserparameter(set: boolean) {
    if (set) {
      // var objtemp = $.parseJSON(localStorage.getItem('sellerclient'));
      var objtemp = $.parseJSON(localStorage.getItem('sellerclient'));
      if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
        this._isuserloggedin.next(true);
        // this._loggedinusername.next(objtemp.full_name);
        //this._loggedinusername.next(objtemp.firstname + ' ' + objtemp.lastname);
        this._loggedinusername.next(objtemp.firstname);
        this._loggedinuserid.next(objtemp.user_id);
        this._loggedinuseremail.next(objtemp.email);
      }
    } else {
      this._isuserloggedin.next(false);
      this._loggedinusername.next('');
      this._loggedinuserid.next('');
      this._loggedinuseremail.next('');
    }
  }

  clearuserparameters() {
    this._isuserloggedin.next(false);
    this._loggedinusername.next('');
    this._loggedinuserid.next('');
    this._loggedinuseremail.next('');
  }


  setLocationStatus(set: boolean) {
    this.setlocationparameter(set);
  }

  setlocationparameter(set: boolean) {
    if (set) {
      var objtemp = $.parseJSON(localStorage.getItem('userlocation'));
      console.log(objtemp);
      if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
        this._pincode.next(objtemp.pincode);
        console.log('objtemp.pincode');
        console.log(objtemp.pincode);

        this._cityarea_id.next(objtemp.cityarea_id);
      }
    } else {
      this._pincode.next('');
      this._cityarea_id.next('');
    }
  }

  clearlocationparameters() {
    this._pincode.next('');
    this._cityarea_id.next('');
  }
}







// import { Injectable } from '@angular/core';
// //import { Storage } from '@ionic/storage';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// /**
//  * A simple settings/config class for storing key/value pairs with persistence.
//  */
// @Injectable()
// export class Settings {
//   private SETTINGS_KEY: string = '_settings';

//   settings: any;
// public imagebasepath: string = '';
//   _defaults: any;
//   _readyPromise: Promise<any>;

//   public _isuserloggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
//   public _loggedinusername: BehaviorSubject<string> = new BehaviorSubject<string>('');
//   public _loggedinuserid: BehaviorSubject<string> = new BehaviorSubject<string>("0");
//   public _loggedinuseremail: BehaviorSubject<string> = new BehaviorSubject<string>('');
//   public _loggedinuserauthkey: BehaviorSubject<string> = new BehaviorSubject<string>('');

//   constructor(public storage: Storage, defaults: any) {
//       this._defaults = defaults;
//   }

//   load() {
//     return this.storage.get(this.SETTINGS_KEY).then((value) => {
//       if (value) {
//         this.settings = value;
//         return this._mergeDefaults(this._defaults);
//       } else {
//         return this.setAll(this._defaults).then((val) => {
//           this.settings = val;
//         })
//       }
//     });
//   }

//   _mergeDefaults(defaults: any) {
//     for (let k in defaults) {
//       if (!(k in this.settings)) {
//         this.settings[k] = defaults[k];
//       }
//     }
//     return this.setAll(this.settings);
//   }

//   merge(settings: any) {
//     for (let k in settings) {
//       this.settings[k] = settings[k];
//     }
//     return this.save();
//   }

//   setValue(key: string, value: any) {
//     this.settings[key] = value;    
//     return this.storage.set(this.SETTINGS_KEY, this.settings);
//   }

//   setAll(value: any) {
//     return this.storage.set(this.SETTINGS_KEY, value);
//   }

//   getValue(key: string) {
//     return this.storage.get(this.SETTINGS_KEY)
//       .then(settings => {
//         return settings[key];
//       });
//   }

//   save() {
//     return this.setAll(this.settings);
//   }

//   get allSettings() {    
//     return this.settings;
//   }

//   updateuserdat(settings: any)
//   {    
//    this._isuserloggedin.next(settings._isuserloggedin);
//    this._loggedinusername.next(settings._loggedinusername);
//    this._loggedinuserid.next(settings._loggedinuserid);
//    this._loggedinuseremail.next(settings._loggedinuseremail);
//    this._loggedinuserauthkey.next(settings._loggedinuserauthkey);

//   }
// }
