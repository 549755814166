import { Component, OnInit, ElementRef, ViewChild, TemplateRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
declare var $: any;
import { Api } from '../../service/providers';
import { Settings } from '../../service/providers';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-cart-medication',
  templateUrl: './cart-medication.component.html',
  styleUrls: ['./cart-medication.component.css'],
  
})
export class CartMedicationComponent implements OnInit,OnChanges {

  @ViewChild('prescription') prescriptionref: TemplateRef<any>;
  total = 0;
  cartItems: any[] = [];
  imagebasepath = '';
  public userloggedin: boolean = false;
  showEmptyCartMessage = false;
  public struserid = '0';
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };
  cartItemsserver: any;
  isadd: Boolean = false;



  @Input() presciption_Required: { count: number };

  
  constructor(protected localStorage: LocalStorage, public api: Api, public globalSettings: Settings,
    private _Router: Router, private modalService: NgbModal, private toastr: ToastrService
  ) {
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.imagebasepath = this.api._imagebasepath;
  }

  pendingCount = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.presciption_Required) {
      this.pendingCount = this.calculatePresciptionRequiredCount();
    }
  }

  calculatePresciptionRequiredCount() {
     let _return = 0;
    if(this.cartItems != undefined && this.cartItems.length > 0)  {
     let cnt = this.cartItems.filter(item => item.product.approval_status == 'Prescription Required');
     if(cnt !=undefined && cnt.length > 0) {
      _return = cnt.length;
     }
    }
    return _return;
  }
  
  ngOnInit() {

    if (this.userloggedin) {
     this.getUserPresciption_CartData();
    }
  }

  getUserPresciption_CartData() {
    let cartuserdata: { user_id: string } = { user_id: this.struserid };
    const seq1 = this.api.post_httpclient('precartdataapi/getprecartdatadetails', cartuserdata);
    seq1
      .subscribe(res1 => {
        if (res1['Data'] != null) {
          this.cartItemsserver = $.parseJSON(res1['Data'].cart);
          console.log('this.cartItemsserver');
          console.log(this.cartItemsserver);
          this.cartItems = $.parseJSON(res1['Data'].cart);

          if (this.cartItemsserver != undefined && this.cartItemsserver.length > 0) {
            this.globalSettings.setcount_prescription(this.cartItemsserver.length);

            if (this.cartItemsserver.length > 0) {
              this.cartItemsserver.forEach((item, index) => {
                this.total = this.total + (item.product.saleprice * item.qty);
              });
            }
            else {
              this.showEmptyCartMessage = true;
            }

          }
          else {
            this.globalSettings.setcount_prescription(0);
          }
          this.localStorage.setItem('cart_prescription', JSON.stringify(this.cartItemsserver)).subscribe(() => {
          });
        }
        else {
          this.globalSettings.setcount_prescription(0);
        }

      }, err => {
        alert(err);
      });
  }

  removeFromCart(item, i) {
    const price = item.product.saleprice;
    const qty = item.qty;
    this.cartItems.splice(i, 1);
    this.localStorage.setItem('cart_prescription', JSON.stringify(this.cartItems)).subscribe(() => {
      this.total = this.total - (price * qty);
      this.globalSettings.setcount_prescription(this.cartItems.length);
      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(this.cartItems);
        const seq = this.api.post('precartdataapi/addprecartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert(err);
          });
      }
    });
    if (this.cartItems.length === 0) {
      this.showEmptyCartMessage = true;
    }
  }

  changeQty(item, i, change) {
    let price = 0;
    let qty = 0;
    price = parseFloat(item.product.saleprice);
    qty = item.qty;
    if (change < 0 && item.qty === 1) {
      return;
    }
    qty = qty + change;
    item.qty = qty;
    item.amount = qty * price;
    this.total = 0;
    this.cartItems[i] = item;
    this.cartItems.forEach((item, index) => {
      this.total = this.total + (item.product.saleprice * item.qty);
      //this.total = Math.round(this.total);
    });
    this.localStorage.setItem('cart_prescription', JSON.stringify(this.cartItems)).subscribe(() => {
      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(this.cartItems);
        const seq = this.api.post('precartdataapi/addprecartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert(err);
          });
      }
    });
  }

  arrayItemtoSend: any;

  getCartItemtoSendForPrescription() {
    this.localStorage.getItem('cart_prescription').subscribe((cartdata) => {
      this.arrayItemtoSend = $.parseJSON(cartdata);
      console.log('this.arrayItemtoSend');
      console.log(this.arrayItemtoSend);
    });
  }

  openpresciption($event) {
    this.localStorage.getItem('cart_prescription').subscribe((cartdata) => {
      this.arrayItemtoSend = $.parseJSON(cartdata);
      if (this.arrayItemtoSend != undefined && this.arrayItemtoSend.length > 0) {
        this.arrayItemtoSend = this.arrayItemtoSend.filter(item => item.product.approval_status == 'Prescription Required');
        console.log('Inside arry');
        console.log(this.arrayItemtoSend);
        this.modalService.dismissAll();
        this.modalService.open(this.prescriptionref, { windowClass: "ngcustommodal" });
      }
      else
      {
        this.toastr.warning('No Item for ReSending Prescription');
      }
    });
  }

  OnprescriptionSent($event) {
    this.modalService.dismissAll();
    this.toastr.success('Sent for Prescription Approval');
    this.getUserPresciption_CartData();
  }


}
