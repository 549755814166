export class clsorder {
    order_id: string = "";
    user_id: string = "";

    sub_total: string = "";
    tax: string = "";
    total_price: string = "";

    shipment_date: string = "";
    order_status: string = "";

   payment_mode: string = "";
    payment_refno: string = "";
    note: string = "";
}