import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { clsProduct } from './clsProduct';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
declare var $: any;


@Component({
  selector: 'productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit {
  isLoading = false;
  public struserid: string = "0";
  public userloggedin: boolean = false;
  imagebasepath: string = "";
  productlist: any[];
  productcategoryid: any;
  product = new clsProduct();
  productdata: any;
  existingimages: any[];
  categorylist: any[];
  additionalinfo: string = "";
  sellername: string = "";
  cat_name: string = "";
  caturllink: string = "";
  sub_name: string = "";
  suburllink: string = "";
  currentimageurl: string = "";
  categoryproducts: any[];
  othersellerlist: any[];
  isproductLoading = false;
  productid: any;
  public repoUrl = '';
  public sitemainurl = '';
  itemcount = 1;

  cartcount = 0;
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };
  cartdata1_prescription: { user_id: string, cart: string } = { user_id: '0', cart: null };

  @ViewChild('logincontent') logincontentref: TemplateRef<any>;
  @ViewChild('signupcontent') signupcontentref: TemplateRef<any>;
  @ViewChild('otpverificationcontent') otpverificationcontentref: TemplateRef<any>;
  @ViewChild('newsignupcontent') newsignupcontentref: TemplateRef<any>;

  constructor(protected localStorage: LocalStorage, private elementRef: ElementRef, private toastr: ToastrService,
    public api: Api, private _router: Router, public globalSettings: Settings, private _Router: Router, private modalService: NgbModal,
    private _routeParams: ActivatedRoute) {
  }

  ngOnInit() {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
    var id = this._routeParams.params.subscribe(params => {
      var id = params["id"];
      this.productid = id;

      if (!id)
        return;

      this.isLoading = true;
      this.getProductDetails(id);
      //     .then(result => {
      //         setTimeout(function () {
      //             this.productDetailGallery(15000);
      //         }, 1);
      //     }
      //     )
      //     .catch(error => console.log(error));
      // this.isLoading = false;

    });
    this.productid;


  }
  getProductDetails(id: any) {
    return new Promise((resolve, reject) => {

      let currentuserid = this.struserid === "" ? "0" : this.struserid;


      let user: { id: string, items_id: string } = {
        id: currentuserid,
        items_id: id
      };

      //let seq = this.api.post('productapi/getProductDetailsbyuserid', user).share();
      let seq = this.api.post('productapi/getProductDetailsbyuseridseller ', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.product = res.Data[0];
          this.othersellerlist = res.Seller;
          this.productcategoryid = this.product.cat_id;
          this.additionalinfo = '';
          this.sellername = '';

          if (typeof (this.product.sellername) !== "undefined" && this.product.sellername !== null &&
            String(this.product.sellername) != "") {
            this.sellername = '@' + this.product.sellername;
          }
          if (typeof (this.product.cat_name) !== "undefined" && this.product.cat_name !== null && String(this.product.cat_name) != "") {
            this.cat_name = this.product.cat_name;
          }
          if (typeof (this.product.caturllink) !== "undefined" && this.product.caturllink !== null && String(this.product.caturllink) != "") {
            this.caturllink = this.product.caturllink;
          }
          if (typeof (this.product.sub_name) !== "undefined" && this.product.sub_name !== null && String(this.product.sub_name) != "") {
            this.sub_name = this.product.sub_name;
          }
          if (typeof (this.product.suburllink) !== "undefined" && this.product.suburllink !== null && String(this.product.suburllink) != "") {
            this.suburllink = this.product.suburllink;
          }

          if (String(this.product.item_dtl_desc) != "") {
            this.additionalinfo = this.product.item_dtl_desc.replace(/<img/g, "<img class='img-responsive' ");
            // this.additionalinfo = this.product.item_dtl_desc;
            // this.additionalinfo = this.additionalinfo.replace(/<img/g, "<img class='img-responsive' ")
          }
          this.existingimages = res.Images;
          if (this.existingimages.length > 0) {
            this.currentimageurl = this.existingimages[0]["mainimage"];
          }

          // this.isproductLoading = true;
          // let users: { id: string, items_id: string, cat_id: string } = {
          //   id: currentuserid,
          //   items_id: this.product.items_id,
          //   cat_id: this.product.cat_id
          // };

          // let seq = this.api.post('productapi/getAllProductsByUserIdCatIDItemId', users).share();
          // seq
          //   .map(res => res.json())
          //   .subscribe(res => {
          //     this.categoryproducts = res.Data;
          //     this.isproductLoading = false;

          //   }, err => {
          //     alert("Error");
          //   });

        }, err => {
          alert("Error");
        });



    });
  }

  openloginmodal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.logincontentref, { windowClass: "ngcustommodal" });
  }

  onsignupclick($event) {
    this.modalService.dismissAll();
    this.modalService.open(this.signupcontentref, { windowClass: "ngcustommodal" });
  }

  strReceivedmobile = "0";
  onotpsentsuccessfully($event) {
    this.strReceivedmobile = $event.mobileno;
    this.modalService.dismissAll();
    console.log($event.mobileno);
    const modalRef = this.modalService.open(this.otpverificationcontentref, { windowClass: "ngcustommodal" });
    modalRef.componentInstance.strmobileno = $event.mobileno;
  }

  onloginsuccess($event) {
    this.modalService.dismissAll();
  }

  strReceivedmobileafterotpverification = "0";
  onotpverificationsuccessfull($event) {
    this.strReceivedmobileafterotpverification = $event.mobileno;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.newsignupcontentref, { windowClass: "ngcustommodal" });
    modalRef.componentInstance.strsignupmobileno = $event.mobileno;
  }

  onsignupsuccessfull($event) {
    console.log($event.mobileno);
    this.modalService.dismissAll();
  }

  booking(product) {
    if (!this.userloggedin) {
      //this._Router.navigate(['/login']);
      this.openloginmodal();
      return;
    }
    this._Router.navigate(['booking', this.productid]);
  }


  addCart(product,sellerid = '', strsaleprice = '' ) {

    if (!this.userloggedin) {
      this.openloginmodal();
      return;
    }

    console.log('Calling CArt');

    if (product.isprescription == '0') {
      this.addToCart(product);
    }
    else {
      this.addToCart_Prescription(product);
    }

  }


  addToCartseller(product, sellerid, strsaleprice) {
    this.productdata = { ...product };
    this.productdata.saleprice = strsaleprice;
    this.productdata.adminuser_id = sellerid;
    this.productdata.images = this.existingimages;
    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': this.productdata,
          'qty': this.itemcount,
          'amount': parseFloat(strsaleprice)
          , 'adminuser_id': Number(sellerid)
        });
      } else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          if (product.items_id === cartdata[i].product.items_id && sellerid === cartdata[i].product.adminuser_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + this.itemcount;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }
        if (added === 0) {
          cartdata.push({
            'product': this.productdata,
            'qty': this.itemcount,
            'amount': parseFloat(strsaleprice)
            , 'adminuser_id': Number(sellerid)
          });
        }
      }

      this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert(err);
          });
      }
      this.toastr.success('Cart Updated !!');
    });
  }


  addToCart(product) {
    this.productdata = product;
    this.productdata.images = this.existingimages;
    // let cartdata = $.parseJSON(localStorage.getItem('cart'));
    this.localStorage.getItem('cart').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);
      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': this.productdata,
          'qty': this.itemcount,
          'amount': parseFloat(product.saleprice)
          , 'adminuser_id': Number(product.adminuser_id)
        });
      } else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          //if (product.items_id === cartdata[i].product.items_id   ) {
          if (product.items_id === cartdata[i].product.items_id && product.adminuser_id === cartdata[i].product.adminuser_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + this.itemcount;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }

        if (added === 0) {
          cartdata.push({
            'product': this.productdata,
            'qty': this.itemcount,
            'amount': parseFloat(product.saleprice)
            , 'adminuser_id': Number(product.adminuser_id)
          });
        }

      }

      this.localStorage.setItem('cart', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('cartdataapi/addcartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }

      this.toastr.success('Cart Updated !!');
    });
  }



  addToCart_Prescription(product) {
    product.approval_status = 'Prescription Required';
    //this.result.userData.visitorip = '';
    console.log('product');
    console.log(product);
    this.productdata = product;
    this.productdata.images = this.existingimages;
    
    // let cartdata = $.parseJSON(localStorage.getItem('cart'));
    this.localStorage.getItem('cart_prescription').subscribe((cartdata) => {
      cartdata = $.parseJSON(cartdata);

      if (cartdata === null || cartdata.length === 0) {
        cartdata = [];
        cartdata.push({
          'product': this.productdata,
          'qty': this.itemcount,
          'amount': parseFloat(product.saleprice)
          , 'adminuser_id': Number(product.adminuser_id)
        });
      }
      
       else {
        let added = 0;
        for (let i = 0; i < cartdata.length; i++) {
          //if (product.items_id === cartdata[i].product.items_id   ) {
          if (product.items_id === cartdata[i].product.items_id && product.adminuser_id === cartdata[i].product.adminuser_id) {
            let qty = cartdata[i].qty;
            cartdata[i].qty = qty + this.itemcount;
            cartdata[i].amount = parseFloat(cartdata[i].amount) + parseFloat(cartdata[i].product.saleprice);
            added = 1;
          }
        }

        if (added === 0) {
          cartdata.push({
            'product': this.productdata,
            'qty': this.itemcount,
            'amount': parseFloat(product.saleprice)
            , 'adminuser_id': Number(product.adminuser_id)
          });
        }
      }

      this.localStorage.setItem('cart_prescription', JSON.stringify(cartdata)).subscribe(() => {
        if (cartdata === null) {
          this.cartcount = 0;
        } else {
          this.cartcount = cartdata.length;
        }
        this.globalSettings.setcount_prescription(this.cartcount);
      });

      if (this.struserid !== '0') {
        this.cartdata1.user_id = this.struserid;
        this.cartdata1.cart = JSON.stringify(cartdata);
        const seq = this.api.post('precartdataapi/addprecartdata', this.cartdata1).share();
        seq
          .map(res => res.json())
          .subscribe(res => {
          }, err => {
            alert('Error');
          });
      }

      this.toastr.success('Prescription Cart Updated !!');
    });
  }


  likeproduct(product: any) {
    if (!this.userloggedin) {
      //this._Router.navigate(['/login']);
      this.openloginmodal();
      return;
    }
    //   $('#login-modal').modal('show');
    var statustosend = '';

    let user: { user_id_fk: string, items_id_fk: string } = {
      user_id_fk: this.struserid,
      items_id_fk: product.items_id
    };

    if (product.IsuserLike === "0") {

      statustosend = "1";
      product.like_count = Number(product.like_count) + 1;
      this.product.IsuserLike = statustosend;

      let seq = this.api.post('userdata/itemlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          product.like_count = Number(product.like_count) - 1;
        });
    }
    else {
      statustosend = "0";
      product.like_count = Number(product.like_count) - 1;
      this.product.IsuserLike = statustosend;
      let seq = this.api.post('userdata/itemunlike', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
        }, err => {
          product.like_count = Number(product.like_count) + 1;
        });
    }
  }


  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    // s.src = "http://somedomain.com/somescript";
    s.text = `
		 $(document).ready( function() {
 
      $('.thumb-menu').owlCarousel({
        loop: false,
        navText: ["<i class='lnr lnr-arrow-left'></i>", "<i class='lnr lnr-arrow-right'></i>"],
        margin: 15,
        smartSpeed: 1000,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 3,
                autoplay: false,
                smartSpeed: 500
            },
            768: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    })
    $('.thumb-menu a').on('click', function () {
        $('.thumb-menu a').removeClass('active');
    })
    });
		 `;
    this.elementRef.nativeElement.appendChild(s);

  }
}
