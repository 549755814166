export class product
{
    productid: string= "";
    categoryid: string= "";
    companyid: string= "";
    name: string= "";
    description: string= "";
    warranty: string= "";
    serialno: string= "";
    modelno: string= "";
    purchasedate: string= "";
    expirydate: string= "";
    reminderdatetime: string= "";
    user_id: string= "";
    Isactive: string= "";
    CreatedDate: string= "";
}