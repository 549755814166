import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Api, Settings } from '../../service/providers';
import { LocalStorage } from '@ngx-pwa/local-storage';
declare var $: any;

export interface itemwisedata {
  item_name: string;
  items_id: string;
  item_qty: string;
  adminuser_id: string;
  user_id: string;
  status: string;
}

@Component({
  selector: 'app-uploadprescription',
  templateUrl: './uploadprescription.component.html',
  styleUrls: ['./uploadprescription.component.css']
})
export class UploadprescriptionComponent implements OnInit {
  total = 0;
  cartItems: any[] = [];
  objItemdata: itemwisedata[] = [];

  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  @Output() prescriptionSent = new EventEmitter<void>();
  @Input() ItemtoSend: any;

  files: any[] = [];
  public userloggedin: boolean = false;
  public struserid = '0';
  imagebasepath = '';

  constructor(protected localStorage: LocalStorage, private toastr: ToastrService, public api: Api, public globalSettings: Settings) {
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
  }

  ngOnInit() {
    console.log('ItemtoSend In upload');
    console.log(this.ItemtoSend);
    this.imagebasepath = this.api._imagebasepath;
    this.getuser_Presciption_Cartdata();
    if (this.ItemtoSend != undefined && this.ItemtoSend !== null) {
      if (this.ItemtoSend.length > 0) {
        this.ItemtoSend.forEach((item, index) => {
          this.objItemdata.push({ item_name: item.product.item_name, items_id: item.product.items_id, item_qty: item.qty, adminuser_id: item.adminuser_id, user_id: this.struserid, status: item.product.approval_status });
        });
        console.log('Final Object to Send objItemdata');
        console.log(this.objItemdata);
      }
    }
  }

  p_file: any;
  pre_imgfiles: any[] = [];

  uploadPrescription() {
    try {
      if (confirm('Sure you want to send prescription? ')) {
        for (let i = 0; i < this.files.length; i++) {
          this.p_file = this.files[i];
          this.pre_imgfiles.push(this.p_file);
        }
        let fd = new FormData();
        for (let i = 0; i < this.pre_imgfiles.length; i++) {
          console.log('Pre file');
          console.log(this.pre_imgfiles[i]);
          // let strfiletype = "prescription." + this.pre_imgfiles[i].name.slice((Math.max(0, this.pre_imgfiles[i].name.lastIndexOf(".")) || Infinity) + 1);
          // fd.append("shopeact", this.pre_imgfiles[i],strfiletype);
          fd.append(String(i), this.pre_imgfiles[i], this.pre_imgfiles[i].name);
        }

        let json = JSON.stringify(this.objItemdata);
        console.log('json');
        console.log(json);
        fd.append('itemwisedata', json);
        let seq = this.api.post_httpfileupload('precartimageapi/addprecartimage', fd).share();
        seq
          .subscribe(res => {
            console.log(res);
            if(this.getuser_Presciption_Cartdata != undefined)
            {
              this.user_presciption_cart.forEach((item) => {
                let found = this.ItemtoSend.filter(el => el.product.items_id == item.product.items_id);
                if (found != undefined && found.length > 0) {
                  item.product.approval_status = 'Prescription Sent'
                }
              });
            }
            console.log('this.user_presciption_cart');
            console.log(this.user_presciption_cart);

            let cart_predata: { user_id: string, cart: string } = { user_id: this.struserid, cart: JSON.stringify(this.user_presciption_cart) };
              const seq = this.api.post('precartdataapi/addprecartdata', cart_predata).share();
              seq
                .map(res => res.json())
                .subscribe(res => {
                }, err => {
                  alert(err);
                });
            
            this.prescriptionSent.emit();
          }, err => {
            alert(err);
          });
      }
    } catch (error) {
    }
  }

  user_presciption_cart: any;
  getuser_Presciption_Cartdata() {
    let user: { user_id: string } = { user_id: this.struserid }
    const seq = this.api.post('precartdataapi/getprecartdatadetails', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        if (res != null && res['Data'] != null) {
          this.user_presciption_cart = $.parseJSON(res['Data'].cart);
          console.log('this.user_presciption_cart');
          console.log(this.user_presciption_cart);
        }
      }, err => {
        alert(err);
      });
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    // if (this.files[index].progress < 100) {
    //   console.log("Upload in progress.");
    //   return;
    // }
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    console.log(files);
    // if (this.files != undefined && this.files.length > 0) {
    //   this.files = [];
    // }

    for (const item of files) {
      console.log(item);
      if (item.type.toLowerCase().includes('pdf') || item.type.toLowerCase().includes('jpg') || item.type.toLowerCase().includes('jpeg') || item.type.toLowerCase().includes('png')) {
        var fileinMB = (item.size / (1024 * 1024)).toFixed(2);
        if (Number(fileinMB) > 2) {
          this.toastr.warning('File Size Should be less than 2MB');
        }
        else {
          this.files.push(item);
        }
      } else {
        this.toastr.warning("Allowed extension: pdf, jpeg, png");
      }
    }
    this.fileDropEl.nativeElement.value = "";
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}

 // this.localStorage.getItem('cart_prescription').subscribe((cartdata) => {
    //   this.cartItems = $.parseJSON(cartdata);
    //   console.log('this.cartItems');
    //   console.log(this.cartItems);
    //   if (typeof (this.cartItems) !== "undefined" && this.cartItems !== null) {
    //     if (this.cartItems.length > 0) {
    //       this.cartItems.forEach((item, index) => {
    //         this.objItemdata.push({ item_name: item.product.item_name, items_id: item.product.items_id, item_qty: item.qty, adminuser_id: item.adminuser_id, user_id: this.struserid, status: item.product.approval_status });
    //       });
    //       console.log('this.objItemdata');
    //       console.log(this.objItemdata);
    //     }
    //   }
    // });
