


import { Injectable } from '@angular/core';
import { Http, RequestOptions, URLSearchParams, HttpModule, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType, 
  HttpEvent,
  HttpProgressEvent
} from "@angular/common/http";

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class Api {
 
  // url = 'http://myhomedeliveryservices.com/rest_sellerecomm/index.php/';
  // public _imagebasepath = 'http://myhomedeliveryservices.com/rest_sellerecomm/';

  url = 'https://rest.mhds.co.in/index.php/';
  public _imagebasepath = 'https://rest.mhds.co.in/';

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  options: any;
  

  constructor(public http: Http, private httpClient: HttpClient) {
  }

  // private headers = new Headers({ 'Content-Type': 'application/json' });
  private headers = { headers: new Headers({ 'Content-Type': 'application/json' }) };
  private headers2 = { headers: new Headers({ 'Content-Type': 'application/json; charset=utf-8' }) };

  //  private headers1 = new Headers({ 'content-type': 'application/json; charset=utf-8' });

  get(endpoint: string, params?: any, options?: RequestOptions) {
    if (!options) {
      options = new RequestOptions();
    }

    // Support easy query params for GET requests
    if (params) {
      let p = new URLSearchParams();
      for (let k in params) {
        p.set(k, params[k]);
      }
      // Set the search field if we have params and don't already have
      // a search field set in options.
      options.search = !options.search && p || options.search;
    }

    return this.http.get(this.url + '/' + endpoint, options);
  }

  // post(endpoint: string, body: any, options?: RequestOptions) {
  //   return this.http.post(this.url + endpoint,  { "id": "0" },  this.headers);
  // }

  post(endpoint: string, body: any, options?: RequestOptions) {

    return this.http.post(this.url + endpoint, body, this.headers);
  }

  post_httpclient(endpoint: string, body: any) {
    return this.httpClient
      .post(this.url + endpoint, body,this.httpOptions)
      .catch(this.handleError);
  }

  
  handleError(error: Response) {
    return Observable.throw(error); //Specific to Application
    //OR
    // return Observable.throw(error); //general Response Object
  }


  post2(endpoint: string, body: any, options?: RequestOptions) {
    return this.http.post(this.url + endpoint, body, this.headers2);
  }

  
  post_httpfileupload(endpoint: string, body: any, options?: RequestOptions) {
    return this.httpClient.post(this.url + endpoint, body);
  }
  

  put(endpoint: string, body: any, options?: RequestOptions) {
    return this.http.put(this.url + '/' + endpoint, body, options);
  }

  delete(endpoint: string, options?: RequestOptions) {
    return this.http.delete(this.url + '/' + endpoint, options);
  }

  patch(endpoint: string, body: any, options?: RequestOptions) {
    return this.http.put(this.url + '/' + endpoint, body, options);
  }
  

search(terms: Observable<string>) {
  return terms.debounceTime(100)
    .distinctUntilChanged()
    .switchMap(term => this.searchEntries(term));
}


searchEntries(term) {
  let selller: { searchstring: string } = {
      searchstring: term
    }
  return this.httpClient.post<any>(this.url + "Dashboardapi/searchwithstring", selller, this.httpOptions)
  //return this.httpClient.get<any>(this.coreservicepatienturl + "patient/patient/patientlist?searchtext=" + term + "&tenantid=" + tenantid);
}

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
         console.log(resp);
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          console.log(err);
          reject(err);
        });
    });

  }

  getAddress(latitude, longitude) {
    return new Promise(function (resolve, reject) {
        var request = new XMLHttpRequest();
        var method = 'GET';
        var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&sensor=true&key=AIzaSyBWQzfg1cM3ZHvr-tPQ8zXlKOvXPSsSl98';
        var async = true;
        request.open(method, url, async);
        request.onreadystatechange = function () {
            if (request.readyState == 4) {
                if (request.status == 200) {
                    var data = JSON.parse(request.responseText);
                    console.log('data in get address');
                    console.log(data);
                    var address = data.results[0];
                    resolve(address);
                }
                else {
                    reject(request.status);
                }
            }
        };
        request.send();
    });
};





}
