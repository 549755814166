import { Component, OnInit, ElementRef } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { companymaster } from './clscompanymaster';
import { Router, ActivatedRoute } from "@angular/router";
import { CustomValidators } from 'ng2-validation';
import { Api } from '../../service/providers';

@Component({
  selector: 'app-companymaster',
  templateUrl: './companymaster.component.html',
  styleUrls: ['./companymaster.component.css'],
  providers: [Api]
})
export class CompanymasterComponent implements OnInit {

  frmsignup: FormGroup;
	formtitle: string = "Add Company";
	buttonCaption: string = "Add Company";
	isaddmode: boolean = true;
	isLoading = false;
  category = new companymaster();

  
  constructor(
		fb: FormBuilder, public api: Api, private _router: Router,private _routeParams: ActivatedRoute
	) {
		this.frmsignup = fb.group({
			companyname: ['', Validators.compose([Validators.required])],
      address: [''],
	  email: [''],
	  link: [''],
      no1: [''],
      no2: ['']
		});
	}

  ngOnInit() {
		var id = this._routeParams.params.subscribe(params => {
			var id = String(params["id"]);
			if (id == "new") {
				this.formtitle = "Add Company";
				this.buttonCaption = "Add Company"
				this.isaddmode = true;
			}
			else {
				this.formtitle = "Edit Company";
				this.buttonCaption = "Update Company"
				this.isaddmode = false;
			}

			if (id == "new") {
				return;
			}
			else {
				this.isLoading = true;
				let Company: { companyid: string} = {
					companyid: id
				  };
				let seq = this.api.post('Companyapi/getcompanydetails', Company).share();
				seq
					.map(res => res.json())
					.subscribe(res => {
						this.category = res.Data;
					}, err => {
						alert("Error");
					});
				this.isLoading = false;
			}
		});
  }
  

  addupdatecustomer() {
	
		if (this.category.companyid) {
			//update family
			let seq = this.api.post('Companyapi/updatecompany', this.category).share();
			seq
				.map(res => res.json())
				.subscribe(res => {
					this._router.navigate(['company']);
				}, err => {
					alert("Error");
				});
		}
		else {
			//add family
			let seq = this.api.post('Companyapi/addcompany', this.category).share();
			seq
				.map(res => res.json())
				.subscribe(res => {
					this._router.navigate(['company']);
				}, err => {
					alert("Error");
				});
		}
	}

}
