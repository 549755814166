import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { DatePickerComponent } from 'ng2-date-picker';
import { DatePickerDirective } from 'ng2-date-picker';
import { clsProduct } from '../productdetails/clsProduct';
import { clsbooking } from './clsbooking';
import { ToastrService } from 'ngx-toastr';
import { Moment } from 'moment';
import * as momentNs from 'moment';
const moment = momentNs;
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
declare var $: any;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  @ViewChild('dayPicker') datePicker: DatePickerComponent;
  @ViewChild('dateComponent') dateComponent: DatePickerComponent;
  public selectedDate: any;
  isLoading = false;
  public struserid: string = "0";
  ordernote: string = "";
  total_price: number = 0;
  advancepayment: number = 0;
  totalorderitem_qty: number = 0;


  public userloggedin: boolean = false;
  imagebasepath: string = "";
  productlist: any[];
  productcategoryid: any;
  product = new clsProduct();
  productdata: any;
  existingimages: any[];
  categorylist: any[];
  additionalinfo: string = "";
  sellername: string = "";
  cat_name: string = "";
  caturllink: string = "";
  sub_name: string = "";
  suburllink: string = "";
  currentimageurl: string = "";
  categoryproducts: any[];
  othersellerlist: any[];
  isproductLoading = false;
  productid: any;
  public repoUrl = '';
  public sitemainurl = '';
  itemcount = 1;
  public dtdate = new Date();
  placeholder: string = 'Choose a date...';
  required: boolean = true;
  validationMinDate: Moment = moment(new Date());
  validationMaxDate: Moment;
  addresslist: any[];
  selectedAddressid: string = "";
  dailyproduct = 1;

  format?: string;
  date: Moment[];
  dates: Moment[] = [];
  displayDate: Moment | string;
  formGroup: FormGroup;
  config = {
    firstDayOfWeek: 'su',
    monthFormat: 'MMM, YYYY',
    disableKeypress: false,
    allowMultiSelect: true,
    closeOnSelect: undefined,
    closeOnSelectDelay: 100,
    openOnFocus: true,
    openOnClick: true,
    onOpenDelay: 0,
    weekDayFormat: 'ddd',
    appendTo: document.body,
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    yearFormat: 'YYYY',
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 1,
    secondsFormat: 'ss',
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: false,
    timeSeparator: ':',
    multipleYearsNavigateBy: 10,
    showMultipleYearsNavigation: false,
    locale: moment.locale(),
    hideInputContainer: false,
    returnedValueType: String,
    unSelectOnClick: true,
    hideOnOutsideClick: true
  };

  pickerMode = 'dayPicker';

  constructor(protected localStorage: LocalStorage, private elementRef: ElementRef, private toastr: ToastrService,
    public api: Api, private _router: Router, public globalSettings: Settings, private _Router: Router,
    private _routeParams: ActivatedRoute) {



    this.formGroup = new FormGroup({
      datePicker: new FormControl({ value: this.date }, [
        this.required ? Validators.required : () => undefined,
        control => this.validationMinDate && this.config &&
          moment(control.value, String(this.config) || this.getDefaultFormatByMode(this.pickerMode))
            .isBefore(this.validationMinDate)
          ? { minDate: 'minDate Invalid' } : undefined,
        control => this.validationMaxDate && this.config &&
          moment(control.value, String(this.config) || this.getDefaultFormatByMode(this.pickerMode))
            .isAfter(this.validationMaxDate)
          ? { maxDate: 'maxDate Invalid' } : undefined
      ])
    });

  }

  ngOnInit() {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;
    this.getuseralladdress();
    var id = this._routeParams.params.subscribe(params => {
      var id = params["id"];
      this.productid = id;
      // this.datePicker.api.open();
      if (!id)
        return;
      this.getProductDetails(id);
    });
  }

  getuseralladdress() {
    let user: { user_id: string } = { user_id: this.struserid };
    let seq = this.api.post('userdata/getuseraddress', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.addresslist = res.Data;
      }, err => {
        alert('Error');
      });
  }

  open() {
    //this.datePicker.api.open(); 
    this.dateComponent.api.open();
  }


  booknow() {
    console.log('this.date');
    console.log(this.date);
    if (this.selectedAddressid != "") {
      let currentuserid = this.struserid === "" ? "0" : this.struserid;
      let user: { adminuser_id: string, user_id: string,items_id: string,item_sale_price : string,item_taxamount,note : string,totalorderitem_qty: Number,
        total_price : Number,perorderitem_qty : Number,bookdateArray: any,address_id: string, advanceamount: number } = {
        adminuser_id: this.product.adminuser_id,
        user_id: this.struserid,
        items_id: this.product.items_id,
        item_sale_price: this.product.saleprice,
        item_taxamount: this.product.item_tax,
        note: this.ordernote,
        totalorderitem_qty:this.totalorderitem_qty,
        total_price: this.total_price,
        perorderitem_qty: this.dailyproduct,
        bookdateArray:this.date,
        address_id:this.selectedAddressid,
        advanceamount: this.advancepayment
      };

      console.log(user);
      let seq = this.api.post('bookingapi/addbooking', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          console.log('res of Addbooking');
          console.log(res);
          if(res.Data == "Sucessfully booking added")
          {
            this.toastr.success('Booking Confirmed. Order has been Placed.');
            this._Router.navigate(['dashboard']);
          }
        }, err => {
          alert("Error");
        });
    }
    else {
      this.toastr.warning('Please Select Delivery Address');
    }
  }

  onSelect(data: any) {
    console.log('onSelect');
    console.log(data);
  }

  log(data: any) {
    console.log('log');
    console.log(data);
  }


  private getDefaultFormatByMode(mode: string): string {
    switch (mode) {
      case 'daytimePicker':
      case 'daytimeInline':
      case 'daytimeDirective':
        return 'DD-MM-YYYY HH:mm:ss';
      case 'dayPicker':
      case 'dayInline':
      case 'dayDirective':
      case 'dayDirectiveReactiveMenu':
        return 'DD-MM-YYYY';
      case 'monthPicker':
      case 'monthInline':
      case 'monthDirective':
        return 'MMM, YYYY';
      case 'timePicker':
      case 'timeInline':
      case 'timeDirective':
        return 'HH:mm:ss';
    }
  }

  getProductDetails(id: any) {
    return new Promise((resolve, reject) => {
      let currentuserid = this.struserid === "" ? "0" : this.struserid;
      let user: { id: string, items_id: string } = {
        id: currentuserid,
        items_id: id
      };
      //let seq = this.api.post('productapi/getProductDetailsbyuserid', user).share();
      let seq = this.api.post('productapi/getProductDetailsbyuseridseller ', user).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.product = res.Data[0];
          this.othersellerlist = res.Seller;
          this.productcategoryid = this.product.cat_id;
          this.additionalinfo = '';
          this.sellername = '';

          if (typeof (this.product.sellername) !== "undefined" && this.product.sellername !== null &&
            String(this.product.sellername) != "") {
            this.sellername = '@' + this.product.sellername;
          }
          if (typeof (this.product.cat_name) !== "undefined" && this.product.cat_name !== null && String(this.product.cat_name) != "") {
            this.cat_name = this.product.cat_name;
          }
          if (typeof (this.product.caturllink) !== "undefined" && this.product.caturllink !== null && String(this.product.caturllink) != "") {
            this.caturllink = this.product.caturllink;
          }
          if (typeof (this.product.sub_name) !== "undefined" && this.product.sub_name !== null && String(this.product.sub_name) != "") {
            this.sub_name = this.product.sub_name;
          }
          if (typeof (this.product.suburllink) !== "undefined" && this.product.suburllink !== null && String(this.product.suburllink) != "") {
            this.suburllink = this.product.suburllink;
          }

          if (String(this.product.item_dtl_desc) != "") {
            this.additionalinfo = this.product.item_dtl_desc.replace(/<img/g, "<img class='img-responsive' ");
            // this.additionalinfo = this.product.item_dtl_desc;
            // this.additionalinfo = this.additionalinfo.replace(/<img/g, "<img class='img-responsive' ")
          }
          this.existingimages = res.Images;
          if (this.existingimages.length > 0) {
            this.currentimageurl = this.existingimages[0]["mainimage"];
          }

        }, err => {
          alert("Error");
        });
    });
  }

  disabled: false;
  opened()  {}

  closed()  {}

  onLeftNav($event) {}

  onRightNav($event) {}

  priceandtax(qty, saleprice, tax): Number {
    //  console.log(saleprice);
    //  console.log(tax);
    //  console.log(Number(saleprice));
    //  console.log(Number(tax));
    this.totalorderitem_qty = Number(qty) ;
    this.total_price =  ( (Number(qty) * this.dailyproduct) * (Number(saleprice) + Number(tax)));
    this.advancepayment = this.total_price * 0.10;
    return this.total_price;
  }

  changeQty(item, change) {

    // let price = 0;
    // let qty = 0;

    // price = parseFloat(item.product.saleprice);
    // qty = item.qty;

    if (change < 0 && this.dailyproduct === 1) {
      return;
    }

    this.dailyproduct = this.dailyproduct + change;
    // item.qty = qty;
    // item.amount = qty * price;


  }


}
