export class clsuser {
user_id: string = '';
contactno1: string = '';
contactno2: string = '';
firstname: string = '';
lastname: string = '';
email: string = '';
gender: string = '';
address: string = '';
IsActivate: string = '';
IsAdmin: string = '';
Issuspended: string = '';
company_name: string = '';
account_type: string = '';
user_pass: string = '';
website: string = '';
date_joined: string = '';
full_name: string = '';
thumbnail: string = '';
main_image: string = '';
}