import { Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { clsaddress } from './clsaddress'
import { clsuser } from './clsuser'
import { CustomValidator2 } from '../../shared/customvalidator';
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  public struserid = '0';
  public userloggedin = false;
  imagebasepath = '';
  addresslist: any[];
  category = new clsaddress();
  userprofile = new clsuser();
  frmprofile: FormGroup;
  frmsignup: FormGroup;
  formtitle: string = "Add A New Address";
  buttonCaption: string = "Add Address";
  isaddmode: boolean = true;
  isprofileeditmode: boolean = false;
  showsection = 'profile';

  contactno1: any;
  contactno2: any;
  firstname: any;
  lastname: any;
  usergender: string = '';
  email: any;

  constructor(fb: FormBuilder, private elementRef: ElementRef, protected localStorage: LocalStorage, public api: Api, private _router: Router,
    private toastr: ToastrService, public globalSettings: Settings, public _Router: Router) {
    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.imagebasepath = this.api._imagebasepath;

    this.frmsignup = fb.group({
      username: ['', Validators.compose([Validators.required])],
      address1: ['', Validators.compose([Validators.required])],
      address2: [''],
      phone: ['', Validators.compose([
        Validators.required, CustomValidator2.isNumberCheck,])],
      landmark: [''],
      state: [''],
      city: [''],
      // county: [''],
      post_code: ['']
    });


    this.frmprofile = fb.group({
      email: ['', Validators.compose([Validators.required])],
      firstname: ['', Validators.compose([Validators.required])],
      lastname: ['', Validators.compose([Validators.required])],
      contactno1: ['', Validators.compose([Validators.required])],
      contactno2: [''],
      gender: ['']
    });

  }

  ngOnInit() {
    this.getuseralladdress();
    this.getcurrentuserinfo();
  }

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    // s.src = "http://somedomain.com/somescript";
    s.text = `
		 $(document).ready( function() {
      //$('.widget').widgster();
		});
		 `;
    this.elementRef.nativeElement.appendChild(s);
  }



  getcurrentuserinfo() {
    let user: { user_id: string } = { user_id: this.struserid };
    let seq = this.api.post('userdata/getUserData', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.userprofile = res.UserData;

        this.contactno1 = this.userprofile.contactno1;
        this.contactno2 = this.userprofile.contactno2;
        this.firstname = this.userprofile.firstname;
        this.lastname = this.userprofile.lastname;
        this.usergender = this.userprofile.gender;
        this.email = this.userprofile.email;
        
      }, err => {
        alert('Error');
      });
  }


  profileeditmode(val: boolean) {
    this.isprofileeditmode = val;
  }

  profileeditmodereset() {

    this.isprofileeditmode = false;
    // this.frmprofile.reset({
    //   email: '', firstname: '', lastname: '', contactno1: '', contactno2: '', gender: ''
    // });
  }

  currentselected(show: string) {
    this.showsection = show;
  }

  //adduseraddress
  //deleteaddress
  //updateaddress

  getuseralladdress() {
    let user: { user_id: string } = { user_id: this.struserid };
    let seq = this.api.post('userdata/getuseraddress', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.addresslist = res.Data;
      }, err => {
        alert('Error');
      });
  }

  addnew() {
    this.isaddmode = true;
    this.formtitle = "Add A New Address";
    this.buttonCaption = "Add Address";
    $('.widget-body').show(200);
  }

  addupdateprofile() {

    let seq = this.api.post('userdata/updateuser', this.userprofile).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        var updateLocalStorage = JSON.parse(localStorage.getItem("currentUser"));
        updateLocalStorage.contactno1 = this.userprofile.contactno1;
        updateLocalStorage.contactno2 = this.userprofile.contactno2;
        updateLocalStorage.firstname = this.userprofile.firstname;
        updateLocalStorage.lastname = this.userprofile.lastname;
        updateLocalStorage.gender = this.userprofile.gender;
        localStorage.setItem("currentUser", JSON.stringify(updateLocalStorage));
        this.contactno1 = this.userprofile.contactno1;
        this.contactno2 = this.userprofile.contactno2;
        this.firstname = this.userprofile.firstname;
        this.lastname = this.userprofile.lastname;
        this.usergender = this.userprofile.gender;
        this.email = this.userprofile.email;
        this.isprofileeditmode = false;
        // this.frmprofile.reset({
        //   email: '', firstname: '', lastname: '', contactno1: '', contactno2: '', gender: ''
        // });
      }, err => {
        alert("Error");
      });
  }


  addupdateaddress() {

    if (this.isaddmode == false) {
      //update family
      let seq = this.api.post('userdata/updateaddress', this.category).share();
      seq
        .map(res => res.json())

        .subscribe(res => {
          this.frmsignup.reset({
            username: '', address1: '', address2: '', phone: '', landmark: '', state: '', city: '', post_code: ''
          });
          this.getuseralladdress();
          //this._router.navigate(['policytype']);
        }, err => {
          alert("Error");
        });
      this.isaddmode = true;
      this.formtitle = "Add A New Address";
      this.buttonCaption = "Add Address";
      //$('.widget-body').widgster('collapse');
      $('.widget-body').hide(200);

    }
    else {
      //add family
      this.category.user_id = this.struserid;
      let seq = this.api.post('userdata/adduseraddress', this.category).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.frmsignup.reset({
            username: '', address1: '', address2: '', phone: '', landmark: '', state: '', city: '', post_code: ''
          });
          this.getuseralladdress();
        }, err => {
          alert("Error");
        });
      this.isaddmode = true;
      this.formtitle = "Add A New Address";
      this.buttonCaption = "Add Address";
      //$('.widget-body').widgster('collapse');
      $('.widget-body').hide(200);
    }
  }

  redirecttolist() {
    this.frmsignup.reset({
      username: '', address1: '', address2: '', phone: '', landmark: '', state: '', city: '', post_code: ''
    });
  }

  deleteaddress(product: any) {
    if (confirm("Delete User Address " + product.username + "?")) {
      var index = this.addresslist.indexOf(product);

      let health: { address_id: string } = {
        address_id: product.address_id
      };

      let seq = this.api.post('userdata/deleteaddress', health).share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          if (res.Data == "Sucessfully Address Deleted") {
            this.addresslist.splice(index, 1);
          }
        }, err => {
          this.toastr.error('Error');
        });
    }
  }


  canceladdress() {
    this.frmsignup.reset({
      username: '', address1: '', address2: '', phone: '', landmark: '', state: '', city: '', post_code: ''
    });
    this.formtitle = "Add A New Address";
    this.buttonCaption = "Add Address";
    this.isaddmode = true;

    this.category = new clsaddress();
    //$('.widget-body').widgster('collapse');
    $('.widget-body').hide(200);
    // $('.widget-body').widgster({
    //   collapsed: true
    // });
  }

  logout() {

    this.globalSettings.setLoginStatus(false);
    this.globalSettings.clearuserparameters();
    this.globalSettings.clearlocationparameters();
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    localStorage.clear();
    this._Router.navigate(['/']);
  }

  editaddress(product: any) {

    //$('.widget-body').widgster('expand');
    $('.widget-body').show(200);
    this.formtitle = "Edit Address";
    this.buttonCaption = "Update Address"
    this.isaddmode = false;

    let health: { address_id: string } = {
      address_id: product.address_id
    };

    let seq = this.api.post('userdata/getaddressdata', health).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.category = res.Data;
      }, err => {
        this.toastr.error('Error');
      });

  }


  canceluserprofile() {
    this.frmprofile.reset({
      email: '', firstname: '', lastname: '', contactno1: '', contactno2: '', gender: ''
    });
  }
}
