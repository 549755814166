import { Component, OnInit, ElementRef } from '@angular/core';
import { Settings } from '../../service/providers';
import { Api } from '../../service/providers';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import 'rxjs/add/operator/delay';
declare var $: any;
import * as _ from 'underscore'
// import { HttpClient } from "@angular/common/http";
// import { Observable } from "rxjs/Observable";
// import { switchMap, map } from 'rxjs/operators';
// import { interval } from 'rxjs';


@Component({
  selector: 'app-newdashboard',
  templateUrl: './newdashboard.component.html',
  styleUrls: ['./newdashboard.component.css']
})
export class NewdashboardComponent implements OnInit {

  public selectedDate: any;
  public struserid = '0';
  public userloggedin = false;
  public loading = false;
  public showdashview = false;
  productlist: any[];
  sellerproductlist: any[];
  dashviewlist: any[];
  trending: any[];
  dashstandalonggroup: any[];
  bestseller: any[];
  newarrivals: any[];
  bannerlist: any[];
  imagebasepath = '';
  cartcount = 0;
  catcount = 0;
  categorylist: any[];
  cartdata1: { user_id: string, cart: string } = { user_id: '0', cart: null };
  pageSize = 16;
  pagedPosts: any[];
  public cityarea_id = '';
  from: '';
  
  constructor(protected localStorage: LocalStorage, public api: Api, private _router: Router, private elementRef: ElementRef,
    private toastr: ToastrService, public globalSettings: Settings, private _routeParams: ActivatedRoute,
    //,private httpClient: HttpClient
  ) {
  }

  ngOnInit() {


    this.globalSettings._loggedinuserid.subscribe(value => this.struserid = value);
    this.globalSettings._isuserloggedin.subscribe(value => this.userloggedin = value);
    this.globalSettings._cityarea_id.subscribe(value => this.cityarea_id = value);
    this.imagebasepath = this.api._imagebasepath;
    //  $('#dashboardviews').hide();
    this.getbannerlist();
    this.getcategorylist();
   

    var name = this._routeParams.queryParams.subscribe(query => {
      this.from = query["locationupdated"];
      if (this.from != "" && this.from != undefined) {
        this.getbannerlist();
        this.getcategorylist();
      }
    });
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }


  getbannerlist() {
    this.loading = true;
    let seq = this.api.post('bannerapi/getallbanner', '').share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.bannerlist = res.Data;
        this.loading = false;
      }, err => {
        alert('Error');
        this.loading = false;
      });
  }


  getcategorylist() {
    let user: { email: string } = { email: "test2@test.com" };
    let seq = this.api.post('categoryapi/getAllCategories', user).share();
    seq
      .map(res => res.json())
      .subscribe(res => {
        this.categorylist = res.Data;
        if (this.categorylist.length > 0) {
          this.catcount = this.categorylist.length;
        }

      }, err => {
        alert("Error");
      });
  }

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.text = `
		 $(document).ready( function() {
       
		});
		 `;
    this.elementRef.nativeElement.appendChild(s);
  }
}
