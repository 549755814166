import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserComponent } from './pages/user/user.component';
import { UseraddComponent } from './pages/user/useradd.component';
import { CompanymasterComponent } from './pages/companymaster/companymaster.component';
import { CompanymasterlistComponent } from './pages/companymasterlist/companymasterlist.component';
import { ProductlistComponent } from './pages/product/productlist.component';
import { ProductComponent } from './pages/product/product.component';
import { AuthGuard } from './guard/auth.guard';
import { CarouselComponent } from './shared/carousel';
import { RoughtemplateComponent } from './pages/roughtemplate/roughtemplate.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { CartComponent } from './pages/cart/cart.component';
import { ProductdetailsComponent } from './pages/productdetails/productdetails.component';
import { CategoryComponent } from './pages/category/category.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { SubcategoryComponent } from './pages/subcategory/subcategory.component';
import { SearchComponent } from './pages/search/search.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { OrderComponent } from './pages/order/order.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogdetailsComponent } from './pages/blog/blogdetails.component';
import { LocationComponent } from './pages/location/location.component';
import { SellerproductsComponent } from './pages/sellerproducts/sellerproducts.component';
import { BookingComponent } from './pages/booking/booking.component';
import { MobilesignupComponent } from './pages/mobilesignup/mobilesignup.component';
import { OtpverificationComponent } from './pages/otpverification/otpverification.component';
import { SignupComponent } from './pages/signup/signup.component';
import { NewdashboardComponent } from './pages/newdashboard/newdashboard.component';
import { ChooselocationComponent } from './pages/chooselocation/chooselocation.component';
import { SellerfoundComponent } from './pages/sellerfound/sellerfound.component';
import { CashbackComponent } from './pages/cashback/cashback.component';
import { CartMedicationComponent } from './pages/cart-medication/cart-medication.component';

const APP_ROUTES: Routes = [
  { path: 'category', component: CategoryComponent },
  { path: 'category/:id', component: CategoryComponent},
  { path: 'category/:id1/:id', component: SubcategoryComponent },
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent },
  // { path: 'dashboard', component: DashboardComponent },  
  { path: 'mainpage/:id', component: ProductdetailsComponent},
  { path: 'wishlist', component: WishlistComponent },
  { path: 'user', component: UserComponent },
  { path: 'mobilesignup', component: MobilesignupComponent },
  { path: 'signup/:mobileno', component: SignupComponent},
  { path: 'otpverification/:mobileno', component: OtpverificationComponent},
  { path: 'seller/:id', component: SellerproductsComponent},
  { path: 'user/new', component: UseraddComponent },
  { path: 'user/:id', component: UseraddComponent },
  { path: 'search/:id', component: SearchComponent },
  { path: 'company', component: CompanymasterlistComponent },
  { path: 'cart', component: CartComponent  },
  { path: 'cart_prescription', component: CartMedicationComponent },
  { path: 'rough', component: RoughtemplateComponent},
  { path: 'company/:id', component: CompanymasterComponent },
  { path: 'company/new', component: CompanymasterComponent },
  { path: 'sellerfound/:pincode/:areaid', component: SellerfoundComponent },
  { path: 'product', component: ProductlistComponent },
  { path: 'product/:id', component: ProductComponent },
  { path: 'product/new', component: ProductComponent },
  { path: 'checkout/:id', component: CheckoutComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'orders', component: OrderComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:id', component: BlogdetailsComponent },
  { path: 'location', component: LocationComponent },
  { path: 'chooselocation', component: ChooselocationComponent },
  { path: 'cashback', component: CashbackComponent },
  
  //{ path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard', component: NewdashboardComponent },
  { path: 'booking/:id', component: BookingComponent },
  { path: '', component: NewdashboardComponent },
  { path: ':id', component: ProductdetailsComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  //imports: [RouterModule.forRoot(APP_ROUTES)],
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'top', // Add options right here
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }


// export const routing = RouterModule.forRoot(APP_ROUTES);